<div #formField>
  <ng-container *ngIf="!readonly">
    <rds-form-field *ngIf="disabled === false" [formGroup]="form">
      <rds-control-label>
          <span>Enter user name or email</span>
      </rds-control-label>
      <rds-icon rds-control-prefix namespace="outlined" icon="search"></rds-icon>
      <rds-autocomplete 
          #autocompleteRef
          [displayProperty]="'email'"
          formControlName="search"
          placeholder=" "
          [options]="fetchOptions"
          (keyup.enter)="enter(autocompleteRef.getInputFilledValue())"
          [optionTemplate]="optionTemplate"
      />
      <ng-template #optionTemplate let-autocomplete let-option="option" let-keyboardFocusedItem="keyboardFocusedItem">
          <button
              [rdsAutocompleteOption]="autocomplete"
              [keyboardFocusedItem]="keyboardFocusedItem"
              class="rds-list-item rds-list-item--l rds-single-select-list-item"
              (click)="autocomplete.toggle(option)"
          >
            <div class="row row-nowrap row-centered" [style.textAlign]="'left'">
              <div class="col-auto">
                  <rh-user-single [user]="option"></rh-user-single>
              </div>
              <div class="col">
                  <span [innerHTML]="(option.name || option.firstName + ' ' + option.lastName) + ' (' + option.email + ')' | highlight:highlightPhrase"></span>
              </div>
            </div>
          </button>
      </ng-template>
    </rds-form-field>
    <rh-subscript-wrapper *ngIf="!!control && !disabled" [hasError]="!control.valid && control.touched"
                          [disabled]="disabled">
      <ng-content select="rds-control-hint" ngProjectAs="rds-control-hint"></ng-content>
      <ng-content select="rds-control-error" ngProjectAs="rds-control-error"></ng-content>
    </rh-subscript-wrapper>

  </ng-container>
  <ng-container *ngIf="(selectedUsers | mergeUsers) as mergedSelection">
    <div *ngIf="mergedSelection?.length > 0" class="user__value-container" [class.readonly]="disabled">
      <div *ngIf="mergedSelection.length > 0" class="user__value-container--selected">
        <div *ngFor="let selected of mergedSelection" class="row">
          <div class="col-auto">
            <rh-user-single [user]="selected"></rh-user-single>
          </div>
          <div class="col">
            <p class="ui-label-m-bold">
              {{selected.name || (selected.firstName + ' ' + selected.lastName)}} <span *ngIf="!!selected.email"
                                                                                        class="ui-label-s">{{' (' +selected.email + ')'}}</span>
            </p>
            <p class="ui-label-s-bold">
              {{roles[selected.role].description}}
            </p>
          </div>
          <div class="col-auto">
            <div rdsDropdown [disabled]="control.disabled || !roles[selected.role].canEdit">
              <button rds-dropdown-toggle-secondary-button>{{roles[selected.role].label}}</button>
              <div *rdsDropdownMenu>
                <ng-container *ngFor="let role of roles | keyvalue: showRolesInOrder">
                  <button
                    *ngIf="role.value.canEdit"
                    rds-menu-item
                    (click)="changeRole(selected, role.key)">
                    <span>{{role.value.label}}</span>
                  </button>
                </ng-container>
                <button rds-menu-item (click)="remove(selected)"
                        [disabled]="!canRemove(selected.identifier || selected.login) || disabled">
                  <span>Remove access</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

</div>

import { createSelector } from '@ngrx/store';
import * as fromReducer from './template-form.reducer';
import * as fromNewsletter from '../newsletter.reducer';
import {
	selectBodyFormStatus,
	selectHeaderFooterFormStatus,
	selectNewsletter,
} from '@app/newsletter-new/store/newsletter-form';
import { USER_ROLES_TEMPLATE } from '@app/newsletter-new/models/user-roles.enum';

export const selectState = createSelector(fromNewsletter.selectState, fromNewsletter.getTemplateForm);

export const selectForm = createSelector(selectState, fromReducer.getForm);

export const selectFormChecked = createSelector(selectState, fromReducer.getFormChecked);

export const selectUserRole = createSelector(selectState, fromReducer.getUserRole);

export const selectInitialForm = createSelector(selectState, fromReducer.getInitialForm);

export const selectId = createSelector(selectForm, fromReducer.getId);

export function flattenPermission(permissions) {
	const owners = permissions?.owners.length
		? permissions?.owners?.map((i) => ({ ...i, userLogin: i.identifier, role: USER_ROLES_TEMPLATE.OWNER }))
		: [];
	const editors = permissions?.editors.length
		? permissions?.editors?.map((i) => ({ ...i, userLogin: i.identifier, role: USER_ROLES_TEMPLATE.EDITOR }))
		: [];
	const viewers = permissions?.viewers.length
		? permissions?.viewers?.map((i) => ({ ...i, userLogin: i.identifier, role: USER_ROLES_TEMPLATE.VIEWER }))
		: [];

	return [...owners, ...editors, ...viewers];
}

export const selectCreateFormForRequest = createSelector(selectForm, selectNewsletter, (form, newsletterForm) => ({
	bannerPhotoUrl: newsletterForm.hasBanner
		? newsletterForm?.banner?.url
		: newsletterForm.hasImage
			? newsletterForm?.image?.url
			: null,
	bannerPhotoName: newsletterForm.hasBanner
		? newsletterForm?.banner?.name
		: newsletterForm.hasImage
			? newsletterForm?.image?.name
			: null,
	bannerPhotoSize: newsletterForm.hasBanner
		? newsletterForm?.banner?.size
		: newsletterForm.hasImage
			? newsletterForm?.image?.size
			: null,
	id: form?.id,
	title: newsletterForm?.title,
	disclaimer: newsletterForm?.disclaimer,
	hasSenderDisclaimer: newsletterForm?.hasSenderDisclaimer,
	hasBanner: newsletterForm?.hasBanner,
	hasImage: newsletterForm?.hasBanner ? false : newsletterForm?.hasImage,
	hasFeedback: newsletterForm?.hasFeedback,
	image: newsletterForm?.image,
	banner: newsletterForm?.banner,
	sections: newsletterForm?.sections,
	content: newsletterForm?.content,
	imagePreviewUrl: form?.imagePreviewUrl,
	isPredefined: form?.isPredefined,
	newsletterTemplateUserRole: form?.isPredefined ? [] : flattenPermission(form?.permissions),
	newsletterTemplateFeedbackSection: newsletterForm?.hasFeedback  ? newsletterForm?.feedbackSettings?.feedbackType === null ? null : newsletterForm?.feedbackSettings : null,
	subject: '',
	previewText: '',
}));

export const selectUpdateFormForRequest = createSelector(selectForm, selectNewsletter, (form, newsletterForm) => ({
	bannerPhotoUrl: newsletterForm.hasBanner
		? newsletterForm?.banner?.url
		: newsletterForm.hasImage
			? newsletterForm?.image?.url
			: null,
	bannerPhotoName: newsletterForm.hasBanner
		? newsletterForm?.banner?.name
		: newsletterForm.hasImage
			? newsletterForm?.image?.name
			: null,
	bannerPhotoSize: newsletterForm.hasBanner
		? newsletterForm?.banner?.size
		: newsletterForm.hasImage
			? newsletterForm?.image?.size
			: null,
	id: form?.id,
	title: newsletterForm?.title,
	disclaimer: newsletterForm?.disclaimer,
	hasSenderDisclaimer: newsletterForm?.hasSenderDisclaimer,
	hasBanner: newsletterForm?.hasBanner,
	hasImage: newsletterForm?.hasBanner ? false : newsletterForm?.hasImage,
	hasFeedback: newsletterForm?.hasFeedback,
	image: newsletterForm?.image,
	banner: newsletterForm?.banner,
	sections: newsletterForm?.sections,
	content: newsletterForm?.content,
	imagePreviewUrl: form?.imagePreviewUrl,
	isPredefined: form?.isPredefined,
	newsletterTemplateUserRole: form?.isPredefined ? [] : flattenPermission(form?.permissions),
	newsletterTemplateFeedbackSection: newsletterForm?.hasFeedback  ? newsletterForm?.feedbackSettings?.feedbackType === null ? null : newsletterForm?.feedbackSettings : null,
}));

export const selectShareForm = createSelector(selectForm, (form) => ({
	permissions: form?.permissions || { owners: [], editors: [], viewers: [] },
	isPredefined: form?.isPredefined,
}));

export const selectShareStepStatus = createSelector(selectState, fromReducer.getShareStepStatus);

export const selectShareFormStatus = createSelector(selectState, fromReducer.getShareFormStatus);

export const selectChangesMade = createSelector(selectForm, selectInitialForm, (form, initialForm) => {
	return JSON.stringify(form) !== JSON.stringify(initialForm);
});

export const areFormsValid = createSelector(
	selectHeaderFooterFormStatus,
	selectBodyFormStatus,
	selectShareFormStatus,
	(header, body, share) => {
		const areFormsValid = header === 'VALID' && body === 'VALID' && share === 'VALID';
		return areFormsValid;
	}
);

export const canSaveTemplate = createSelector(areFormsValid, selectChangesMade, (formsValid, changesMade) => {
	console.log(formsValid, changesMade);
	return formsValid && changesMade;
});

export const disableSaveTemplateTooltip = createSelector(
	areFormsValid,
	selectChangesMade,
	(formsValid, changesMade) => {
		if (!changesMade) {
			return `No changes made`;
		}
		if (!formsValid) {
			return 'Fill mandatory fields to save template';
		}
		return '';
	}
);

export const selectIsTouched = createSelector(selectState, fromReducer.getTouched);

import { createReducer, Action, on} from '@ngrx/store';
import { createEntityAdapter, Dictionary, EntityAdapter } from '@ngrx/entity';
import * as fromActions from './news-table.actions';
import * as fromChannelDetails from '@app/newsboard/channel/store/channel-details';

import { ChannelDetails, News, NewsSuggestion } from '@app/core/models/newsboard';
import { Channel, Pagination } from '@app/core/models';
import { INITIAL_ALL_NEWS_FILTERS, INITIAL_MY_ARCHIVED_FILTERS, INITIAL_MY_NEWS_FILTERS, INITIAL_MY_SUGGESTED_FILTERS, NewsTableFilters, TableState } from '@app/shared/filters/filters-model';

export const feature = 'details';

export const adapter: EntityAdapter<Partial<News>> =
 createEntityAdapter<Partial<News>>({
  selectId: (e) => e.id
 });

 export const suggestionAdapter: EntityAdapter<Partial<NewsSuggestion>> =
 createEntityAdapter<Partial<NewsSuggestion>>({
  selectId: (e) => `c${e.channel.id}n${e.id}`
 });

export interface State {
  details: ChannelDetails;
  newsPreviewDetails: News;
  recentlyDuplicated: Array<number>;
  allNews: TableState<Partial<News>, NewsTableFilters>;
  myNews: TableState<Partial<News>, NewsTableFilters>;
  mySuggestedNews: TableState<Partial<News>, NewsTableFilters>;
  myArchivedNews: TableState<Partial<News>, NewsTableFilters>;
}

export const initialState: State = {
  details: null,
  newsPreviewDetails: null,
  recentlyDuplicated: [],
  allNews: {
    data: adapter.getInitialState(),
    pagination: {
      ...Pagination,
      pageIndex: 0,
      pageSize: 10,
    },
    sort: {
      active: 'createdDate',
      direction: 'desc'
    },
    defaultSort: {
      active: 'createdDate',
      direction: 'desc'
    },
    filters: INITIAL_ALL_NEWS_FILTERS,
    defaultFilters: INITIAL_ALL_NEWS_FILTERS,
    isLoading: false,
    resetIndex: false,
  },
  myNews: {
    data: adapter.getInitialState(),
    pagination: {
      ...Pagination,
      pageIndex: 0,
      pageSize: 10,
    },
    sort: {
      active: 'createdDate',
      direction: 'desc'
    },
    defaultSort: {
      active: 'createdDate',
      direction: 'desc'
    },
    filters: INITIAL_MY_NEWS_FILTERS,
    defaultFilters: INITIAL_MY_NEWS_FILTERS,
    isLoading: false,
    resetIndex: false,
  },
  mySuggestedNews: {
    data: suggestionAdapter.getInitialState(),
    pagination: {
      ...Pagination,
      pageIndex: 0,
      pageSize: 10,
    },
    sort: {
      active: 'suggestedDate',
      direction: 'desc'
    },
    defaultSort: {
      active: 'suggestedDate',
      direction: 'desc'
    },
    filters: INITIAL_MY_SUGGESTED_FILTERS,
    defaultFilters: INITIAL_MY_SUGGESTED_FILTERS,
    isLoading: false,
    resetIndex: false,
  },
  myArchivedNews: {
    data: adapter.getInitialState(),
    pagination: {
      ...Pagination,
      pageIndex: 0,
      pageSize: 10,
    },
    sort: {
      active: 'modifiedDate',
      direction: 'desc'
    },
    defaultSort: {
      active: 'modifiedDate',
      direction: 'desc'
    },
    filters: INITIAL_MY_ARCHIVED_FILTERS,
    defaultFilters: INITIAL_MY_ARCHIVED_FILTERS,
    isLoading: false,
    resetIndex: false,
  },
};

const tableReducer = createReducer(
  initialState,
  on(fromActions.setChannelDetails, (state, {details}) => ({
    ...state,
    details
  })),
  on(fromActions.getAllNewsRequest, (state) => ({
    ...state,
    allNews: {
      ...state.allNews,
      isLoading: true
    }
  })),
  on(fromActions.getAllNewsSuccess, fromActions.getAllNewsFailure, (state) => ({
    ...state,
    allNews: {
      ...state.allNews,
      isLoading: false
    }
  })),
  on(fromActions.getAllNewsSuccess, (state, {data, pagination}) => ({
    ...state,
    allNews: {
      ...state.allNews,
      data: adapter.setAll(data, state.allNews.data),
      pagination,
      resetIndex: false,
    }
  })),
  on(fromActions.deleteAllNewsSuccess, (state) => ({
    ...state,
    allNews: {
      ...state.allNews,
      resetIndex: true
    }
  })),
  on(fromActions.changeAllNewsFilters, (state, {filters}) => ({
    ...state,
    allNews: {
      ...state.allNews,
      filters,
      resetIndex: true
    }
  })),
  on(fromActions.changeAllNewsSort, (state, {active, direction}) => ({
    ...state,
    allNews: {
      ...state.allNews,
      sort: {
        active,
        direction
      }
    }
  })),
  on(fromActions.changeAllNewsPagination, (state, {pageIndex, pageSize}) => ({
    ...state,
    allNews: {
      ...state.allNews,
      pagination: {
        ...state.allNews.pagination,
        pageIndex,
        pageSize
      }
    }
  })),


  on(fromActions.getMyNewsRequest, (state) => ({
    ...state,
    myNews: {
      ...state.myNews,
      isLoading: true
    }
  })),
  on(fromActions.getMyNewsSuccess, fromActions.getMyNewsFailure, (state) => ({
    ...state,
    myNews: {
      ...state.myNews,
      isLoading: false
    }
  })),
  on(fromActions.getMyNewsSuccess, (state, {data, pagination}) => ({
    ...state,
    myNews: {
      ...state.myNews,
      data: adapter.setAll(data, state.myNews.data),
      pagination,
      resetIndex: false,
    }
  })),
  on(fromActions.deleteMyNewsSuccess, (state) => ({
    ...state,
    myNews: {
      ...state.myNews,
      resetIndex: true
    }
  })),
  on(fromActions.changeMyNewsFilters, (state, {filters}) => ({
    ...state,
    myNews: {
      ...state.myNews,
      filters,
      resetIndex: true
    }
  })),
  on(fromActions.changeMyNewsSort, (state, {active, direction}) => ({
    ...state,
    myNews: {
      ...state.myNews,
      sort: {
        active,
        direction
      }
    }
  })),
  on(fromActions.changeMyNewsPagination, (state, {pageIndex, pageSize}) => ({
    ...state,
    myNews: {
      ...state.myNews,
      pagination: {
        ...state.myNews.pagination,
        pageIndex,
        pageSize
      }
    }
  })),
  on(fromActions.getMySuggestedNewsRequest, (state) => ({
    ...state,
    mySuggestedNews: {
      ...state.mySuggestedNews,
      isLoading: true
    }
  })),
  on(fromActions.getMySuggestedNewsSuccess, fromActions.getMySuggestedNewsFailure, (state) => ({
    ...state,
    mySuggestedNews: {
      ...state.mySuggestedNews,
      isLoading: false
    }
  })),
  on(fromActions.getMySuggestedNewsSuccess, (state, {data, pagination}) => ({
    ...state,
    mySuggestedNews: {
      ...state.mySuggestedNews,
      data: suggestionAdapter.setAll(data, state.mySuggestedNews.data),
      pagination,
      resetIndex: false
    }
  })),
  on(fromActions.changeMySuggestedNewsFilters, (state, {filters}) => ({
    ...state,
    mySuggestedNews: {
      ...state.mySuggestedNews,
      filters,
      resetIndex: true
    }
  })),
  on(fromActions.changeMySuggestedNewsSort, (state, {active, direction}) => ({
    ...state,
    mySuggestedNews: {
      ...state.mySuggestedNews,
      sort: {
        active,
        direction
      }
    }
  })),
  on(fromActions.changeMySuggestedNewsPagination, (state, {pageIndex, pageSize}) => ({
    ...state,
    mySuggestedNews: {
      ...state.mySuggestedNews,
      pagination: {
        ...state.mySuggestedNews.pagination,
        pageIndex,
        pageSize
      }
    }
  })),

  
  on(fromActions.getMyArchivedNewsRequest, (state) => ({
    ...state,
    myArchivedNews: {
      ...state.myArchivedNews,
      isLoading: true
    }
  })),
  on(fromActions.getMyArchivedNewsSuccess, fromActions.getMyArchivedNewsFailure, (state) => ({
    ...state,
    myArchivedNews: {
      ...state.myArchivedNews,
      isLoading: false
    }
  })),
  on(fromActions.getMyArchivedNewsSuccess, (state, {data, pagination}) => ({
    ...state,
    myArchivedNews: {
      ...state.myArchivedNews,
      data: adapter.setAll(data, state.myArchivedNews.data),
      pagination,
      resetIndex: false
    }
  })),
  on(fromActions.deleteMyArchivedNewsSuccess, (state) => ({
    ...state,
    myArchivedNews: {
      ...state.myArchivedNews,
      resetIndex: true
    }
  })),
  on(fromActions.changeMyArchivedNewsFilters, (state, {filters}) => ({
    ...state,
    myArchivedNews: {
      ...state.myArchivedNews,
      filters,
      resetIndex: true
    }
  })),
  on(fromActions.changeMyArchivedNewsSort, (state, {active, direction}) => ({
    ...state,
    myArchivedNews: {
      ...state.myArchivedNews,
      sort: {
        active,
        direction
      }
    }
  })),
  on(fromActions.changeMyArchivedNewsPagination, (state, {pageIndex, pageSize}) => ({
    ...state,
    myArchivedNews: {
      ...state.myArchivedNews,
      pagination: {
        ...state.myArchivedNews.pagination,
        pageIndex,
        pageSize
      }
    }
  })),
  on(fromActions.clearAll, (state) => ({
    ...initialState
  })),
  on(fromActions.archiveMyNewsSuccess, fromActions.archiveAllNewsSuccess, (state) => ({
    ...state,
    myNews: {
      ...state.myNews,
      resetIndex: true
    },
    myArchivedNews: {
      ...state.myArchivedNews,
      resetIndex: true
    },
    allNews: {
      ...state.allNews,
      resetIndex: true
    }
  })),
  on(
    fromActions.duplicateNewsSuccess,
    fromActions.updateNewsAssignmentsSuccess,
    fromActions.moveAsDraftSuccess,
    fromActions.unpublishNewsSuccess, (state) => ({
    ...state,
    myNews: {
      ...state.myNews,
      resetIndex: true
    }
  })),
  on(fromActions.removeSuggestionSuccess, (state) => ({
    ...state,
    mySuggestedNews: {
      ...state.mySuggestedNews,
      resetIndex: true
    }
  })),
  on(fromActions.moveAsDraftSuccess, (state) => ({
    ...state,
    myArchivedNews: {
      ...state.myArchivedNews,
      resetIndex: true
    },
    allNews: {
      ...state.allNews,
      resetIndex: true
    }
  })),
  on(
    fromActions.duplicateNewsSuccess,
    fromChannelDetails.duplicateNewsSuccess, (state, {id}) => ({
    ...state,
    recentlyDuplicated: [
      ...state.recentlyDuplicated,
      id
    ]
  })),
  on(
    fromActions.removeDuplicatedId, (state, {id}) => ({
    ...state,
    recentlyDuplicated: state.recentlyDuplicated.filter(i => i !== id)
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return tableReducer(state, action);
}

export const getDetails = (state: State) => state.details;

export const getRecentlyDuplicated = (state: State) => state.recentlyDuplicated;

export const getChannelId = (details: ChannelDetails) => details?.id;

export const getAllNews = (state: State) => state.allNews;
export const getMyNews = (state: State) => state.myNews;
export const getMySuggestedNews = (state: State) => state.mySuggestedNews;
export const getMyArchivedNews = (state: State) => state.myArchivedNews;

export const getPagination = (table: TableState<Partial<News>, NewsTableFilters>) => table.pagination;
export const getSort = (table: TableState<Partial<News>, NewsTableFilters>) => table.sort;
export const getDefaultSort = (table: TableState<Partial<News>, NewsTableFilters>) => table.defaultSort;
export const getFilters = (table: TableState<Partial<News>, NewsTableFilters>) => table.filters;
export const getDefaultFilters = (table: TableState<Partial<News>, NewsTableFilters>) => table.defaultFilters;
export const getIsLoading = (table: TableState<Partial<News>, NewsTableFilters>) => table.isLoading;

export const getRequestData = ({pagination, sort, filters, resetIndex}: TableState<Partial<News>, NewsTableFilters>) => ({
  pageIndex: pagination.pageIndex,
  pageSize: pagination.pageSize,
  sort,
  filters,
  resetIndex});

export const getNewsData = (table: TableState<Partial<News>, NewsTableFilters>) => adapter.getSelectors().selectAll(table.data);
export const getNewsSuggestionData = (table: TableState<Partial<News>, NewsTableFilters>) => suggestionAdapter.getSelectors().selectAll(table.data);

// Pagination fields
export const getPageSize = (pagination: Pagination) => pagination.pageSize;
export const getPageIndex = (pagination: Pagination) => pagination.pageIndex;
export const getTotalCount = (pagination: Pagination) => pagination.totalCount;

// Filters fields
export const getSearch = (filters: NewsTableFilters) => filters.search.value;

export const getEntitiesByIds = (entities: Dictionary<Channel>, ids: string[]): Channel[] => ids.map(id => entities[id]);

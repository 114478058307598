import {ModuleWithProviders, NgModule} from '@angular/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, RouterState, RouterStateSerializer } from '@ngrx/router-store';
import { environment } from '@env/environment';
import { metaReducers, ROOT_REDUCER } from './root.reducer';
import { CustomSerializer, RouterEffects } from './router';
import { IdleTimeoutEffects } from './idle/idle-timeout.effects';
import { ScreenEffects } from './ui/screen/screen.effects';
import { NotificationsEffects } from './ui/notifications';
import { BackButtonEffects } from './ui/back-button';
import { SuggestionsEffects } from './suggestions';
import { SharedModule } from '@app/shared/shared.module';
import { SiteMapEffects } from './ui/sitemap';
import { ToastEffects } from './ui/toast';

const GLOBAL_EFFECTS = [
  RouterEffects,
  IdleTimeoutEffects,
  ScreenEffects,
  ToastEffects,
  NotificationsEffects,
  BackButtonEffects,
  SuggestionsEffects,
  SiteMapEffects
];

@NgModule({
  imports: [
    SharedModule,
    StoreModule.forRoot(ROOT_REDUCER, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false,
      }
    }),
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal,
    }),
    EffectsModule.forRoot(GLOBAL_EFFECTS),
    !environment.prod ? StoreDevtoolsModule.instrument({ name: 'RocheHome portal' }) : [],
  ],
  declarations: [],
  providers: [
    { provide: RouterStateSerializer, useClass: CustomSerializer },
  ]
})
export class RootStoreModule {
  static forRoot(): ModuleWithProviders<RootStoreModule> {
    return {
      ngModule: RootStoreModule,
      providers: []
    };
  }
}


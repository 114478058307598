import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RdsDialogService, RdsToastService } from '@rds/angular-components';
import * as fromActions from './newsletter-preview.actions';
import * as fromReducer from './newsletter-preview.reducer';
import * as fromUser from '@app/core/user/store';

import { Clipboard } from '@angular/cdk/clipboard';
import { NewsletterService } from '@app/core/services/newsletter.service';
import { ConfirmDialogComponent, ConfirmDialogData } from '@app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import { CopyToClipboardDialogContainer, CopyToClipboardDialogData } from '@app/shared/dialogs/copy-to-clipboard-dialog/copy-to-clipboard-dialog.container';
import { catchError, filter, map, mergeMap, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { CopyLinkData, CopyLinkDialogComponent } from '@app/user-settings/store/campaign-subscriptions/copy-link-dialog/copy-link-dialog.component';


@Injectable()
export class NewsletterPreviewEffects {

  public openCopyLinkDialog$ = createEffect(
	  () =>
		this.actions$.pipe(
		  ofType(fromActions.subscription.openCopyLinkDialog),
		  withLatestFrom(this.store$.select(fromUser.selectFullName)),
		  map(([{ id, name, newsletterId }, user]) => {
			const data: CopyLinkData = {
			  id, 
			  name,
			  user,
			  route: `newsletter/newsletter/${newsletterId}/preview`
			}
			console.log(data);
			const dialog = this.dialogService.open(CopyLinkDialogComponent, {
			  data
			});
			return { dialog, id };
		  }),
		  switchMap(({ dialog, id }) =>
            dialog.afterClosed().pipe(
              filter(confirmed => !!confirmed),
              tap((confirmed) => {
                this.toastService.success('Link copied to clipboard', 'Success', {position: 'bottom-left'})
              })
            )
          )
        ), 
      { dispatch: false }
    );

	public openAcceptShareDialog$ = createEffect(
	  () =>
		this.actions$.pipe(
		  ofType(fromActions.subscription.openAcceptShareDialog),
		  map(({ id, name, user }) => {
			const data: ConfirmDialogData = {
			  title: 'Subscribe',
			  messages: 
				user 
				? [ `<strong>${user}</strong> invites you to the <strong>${name}</strong> campaign. Would you like to subscribe? ` ]
				: [ `Would you like to subscribe to the <strong>${name}</strong> campaign?` ],
				cancelButtonLabel: 'Cancel',
				confirmButtonLabel: 'Yes, subscribe',
				confirmButtonType: 'primary'
			}
			console.log(data);
			const dialog = this.dialogService.open(ConfirmDialogComponent, {
			  data
			});
			return { dialog, id };
		  }),
		  switchMap(({ dialog, id }) =>
			dialog.afterClosed().pipe(
			  filter(confirmed => !!confirmed),
			  map((confirmed) => {
				return fromActions.subscription.subscribeRequest({ campaignId: id});
			  })
			)
		  )
		), 
	  { dispatch: true }
	);

	public cannotSubscribe$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.subscription.cannotSubscribe),
				map(({ campaign }) => {
					const campaignOwner = campaign.campaignUserRoles.find(u => u.role === 1);
					const data: ConfirmDialogData = {
						title: `Subscribe not allowed`,
						messages: [
							`Subscribing to <strong>${campaign.name}</strong> newsletter campaign is disabled.`,
							`If you want to  subscribe to that campaign please reach out to <a href="mailto:${campaignOwner.email}">${campaignOwner.email}</a>`,
						],
						cancelButtonLabel: 'Cancel'
					}
					const dialog = this.dialogService.open(ConfirmDialogComponent, {
						data
					});
					return { dialog, campaign };
				})
			), 
		{ dispatch: false }
	);
	

	public cannotUnsubscribe$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.subscription.cannotUnsubscribe),
				map(({ campaign }) => {
					const campaignOwner = campaign.campaignUserRoles.find(u => u.role === 1);
					const data: ConfirmDialogData = {
						title: `Unubscribe not allowed`,
						messages: [
							`Unsubscribing from <strong>${campaign.name}</strong> newsletter campaign is disabled.`,
							`If you want to unsubscribe from that campaign please reach out to <a href="mailto:${campaignOwner.email}">${campaignOwner.email}</a>`,
						],
						cancelButtonLabel: 'Cancel'
					}
					const dialog = this.dialogService.open(ConfirmDialogComponent, {
						data
					});
					return { dialog, campaign };
				})
			), 
		{ dispatch: false }
	);

	public openConfirmDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.subscription.openConfirmDialog),
				map(({ campaign, isSubscribed }) => {
					const campaignOwner = campaign.campaignUserRoles.find(u => u.role === 1);
					const data: ConfirmDialogData = {
						title: `${isSubscribed ? 'Unsubscribe' : 'Subscribe'}`,
						messages: [
							`Are you sure you want to  ${isSubscribed ? 'unsubscribe from' : 'subscribe to'} <strong>${campaign.name}</strong> newsletter campaign?`,
						],
						confirmButtonType: isSubscribed ? 'warning' : 'primary',
						confirmButtonLabel: isSubscribed ? 'Yes, unsubscribe' : 'Yes, subscribe',
						cancelButtonLabel: 'Cancel'
					}
					const dialog = this.dialogService.open(ConfirmDialogComponent, {
						data
					});
					return { dialog, campaign, isSubscribed };
				}),
				switchMap(({ dialog, campaign, isSubscribed }) =>
					dialog.afterClosed().pipe(
						filter(confirmed => !!confirmed),
						map((confirmed) => {
							return isSubscribed ? fromActions.subscription.unsubscribeRequest({ campaignId: campaign.id }) : fromActions.subscription.subscribeRequest({ campaignId: campaign.id });
						})
					)
				)
			), 
		{ dispatch: true }
	);


	public isSubscribedRequest$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.subscription.isSubscribedRequest),
				switchMap(({campaignId}) =>
					this.newsletterService.isSubscribed(campaignId).pipe(
						map((isSubscribed) => fromActions.subscription.isSubscribedSuccess({ isSubscribed })),
						catchError(({ message }) => of(fromActions.subscription.isSubscribedFailure({ error: message })))
					)
				),
			),
		{ dispatch: true }
	);

	public subscribeRequest$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.subscription.subscribeRequest),
				switchMap(({campaignId}) =>
					this.newsletterService.subscribe(campaignId).pipe(
						mergeMap(({success}) => {
							if (success) {
							return [fromActions.subscription.subscribeSuccess()];
							} else {
							return [
								fromActions.subscription.subscribeFailure({ error: 'Subscribe not allowed' }),
							];
							}
						}),
						catchError(({ message }) => of(fromActions.subscription.subscribeFailure({ error: message })))
					)
				),
			),
		{ dispatch: true }
	);

	public unsubscribeRequest$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.subscription.unsubscribeRequest),
				switchMap(({campaignId}) =>
					this.newsletterService.unsubscribe(campaignId).pipe(
						mergeMap(({success}) => {
							if (success) {
							return [fromActions.subscription.unsubscribeSuccess()];
							} else {
							return [
								fromActions.subscription.unsubscribeFailure({ error: 'Unsubscribe not allowed' }),
							];
							}
						}),
						catchError(({ message }) => of(fromActions.subscription.unsubscribeFailure({ error: message })))
					)
				),
			),
		{ dispatch: true }
	);

	constructor(
		private actions$: Actions,
		private clipboard: Clipboard,
		private store$: Store<fromReducer.State>,
		private newsletterService: NewsletterService,
		private dialogService: RdsDialogService,
		private toastService: RdsToastService,
	) {}
}

import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RDS_DIALOG_DATA } from '@rds/angular-components';

export interface RenameDialogData {
  title: string;
  oldName: string;
  controlLabel: string;
  required: boolean;
  maxLength?: number;
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
}
@Component({
  selector: 'rh-rename-dialog',
  templateUrl: './rename-dialog.component.html',
  styleUrls: ['./rename-dialog.component.scss']
})
export class RenameDialogComponent {
  maxLength: number;
  public form: FormGroup = new FormGroup({
    rename: new FormControl('')
  });

  constructor(@Inject(RDS_DIALOG_DATA) public data: RenameDialogData) {
    if (!!data) {
      const validators = [];
      if (data.required) {
        validators.push(Validators.required);
      }
      if (data.maxLength) {
        validators.push(Validators.maxLength(data.maxLength));
      }
      this.maxLength = data.maxLength
      this.form.controls.rename.patchValue(data.oldName);
      this.form.controls.rename.setValidators(validators);
    }
   }
}

import {Action, createReducer, on} from '@ngrx/store';
import * as fromActions from '@app/user-settings/store/campaign-subscriptions/campaign-subscriptions.actions';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
export interface CampaignListModel {campaignId: number, campaignName: string, isUnsubscribed: boolean,
  allowToSubscribe:    boolean
  allowToUnsubscribe:    boolean}
export const adapter: EntityAdapter<CampaignListModel> = createEntityAdapter<CampaignListModel>({
  selectId: (e) => e.campaignId
});
export interface State {
  data: EntityState<CampaignListModel>;
  blockedList: number[];
  hiddenList: number[];
}

export const initialState: State = {
  data: adapter.getInitialState(),
  blockedList: [],
  hiddenList: []
};

export const campaignSubscriptionsReducer = createReducer(
  initialState,
  on(fromActions.list.success, (state, {data}) => ({
    ...state,
    data:  adapter.setAll(data, state.data)
  })),
  on(fromActions.subscribe.request, fromActions.unsubscribe.request, (state, {id}) => ({
    ...state,
    blockedList: [...state.blockedList, id]
  })),
  on(
    fromActions.subscribe.failure,
    fromActions.subscribe.success,
    fromActions.unsubscribe.request,
    fromActions.unsubscribe.failure,
    (state, {id}) => ({
    ...state,
    blockedList: state.blockedList.filter(i => i !== id)
  })),
  on(fromActions.subscribe.success, (state, {id}) => ({
    ...state,
    data:   adapter.updateOne({id, changes: {isUnsubscribed: false}}, state.data),
  })),
  on(fromActions.unsubscribe.success, (state, {id}) => ({
    ...state,
    data:  adapter.updateOne({id, changes: {isUnsubscribed: true}}, state.data),
  })),
  on(fromActions.visibility.hide, (state, {id}) => ({
    ...state,
    hiddenList: [...state.hiddenList, id]
  })),
  on(fromActions.visibility.unhide, (state, {id}) => ({
    ...state,
    hiddenList: state.hiddenList.filter(i => i !== id)
  })),
  on(fromActions.visibility.unhideAll, (state) => ({
    ...state,
    hiddenList: []
  })),
  on(fromActions.visibility.hideAll, (state) => ({
    ...state,
    hiddenList: adapter.getSelectors().selectAll(state.data).map(({campaignId}) => campaignId)
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return campaignSubscriptionsReducer(state, action);
}

export const getData = (state) => adapter.getSelectors().selectAll(state.data);
export const getBlockedList = (state) => state.blockedList;
export const getHiddenList = (state) => state.hiddenList;

import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CampaignListModel } from '@app/user-settings/store/campaign-subscriptions';
import { select, Store } from '@ngrx/store';
import { RDS_DIALOG_DATA, RdsDialogRef, RdsExpansionPanelComponent } from '@rds/angular-components';
import * as fromCampaignSubscriptions from '@app/user-settings/store/campaign-subscriptions';
import { combineLatest, Observable, startWith } from 'rxjs';
import { SubSink } from 'subsink';

@Component({
  selector: 'rh-hidden-subscriptions-dialog',
  templateUrl: './hidden-subscriptions-dialog.component.html',
  styleUrls: ['./hidden-subscriptions-dialog.component.scss']
})
export class HiddenSubscriptionsDialogComponent implements OnInit {
  private subs = new SubSink();
  @ViewChild('hiddenPanel')  hiddenPanel: RdsExpansionPanelComponent
  @ViewChild('visiblePanel')  visiblePanel: RdsExpansionPanelComponent
  data$: Observable<Array<CampaignListModel>> = this.store$.pipe(select(fromCampaignSubscriptions.selectData));
  hiddenCampaigns$: Observable<Array<number>> = this.store$.pipe(select(fromCampaignSubscriptions.selectHiddenList));
  
  public visible: CampaignListModel[] = [];
  public hidden: CampaignListModel[] = [];

  ngOnInit() {
    this.subs.sink = 
    combineLatest([
      this.data$.pipe(startWith([])),
      this.hiddenCampaigns$.pipe(startWith([]))
    ])
    .subscribe(([data, hidden]) => {
      console.log(data, hidden)
      this.visible = data.filter(c => !hidden.includes(c.campaignId)).slice();
      this.hidden = data.filter(c => hidden.includes(c.campaignId)).slice();
      if (this.visible.length === 0) {
        this.handlePanelsOnHideAll();
      }
      if (this.hidden.length === 0) {
        this.handlePanelsOnUnhideAll();
      }
      this.cdr.markForCheck();
    });
  }
  subscribe(id) {
    this.store$.dispatch(fromCampaignSubscriptions.subscribe.request({id}));
  }
  unsubscribe(id) {
    this.store$.dispatch(fromCampaignSubscriptions.unsubscribe.request({id}));
  }

  show(id) {
    this.store$.dispatch(fromCampaignSubscriptions.visibility.unhide({id}));
  }

  hide(id) {
    this.store$.dispatch(fromCampaignSubscriptions.visibility.hide({id}));
  }

  hideAll() {
    this.store$.dispatch(fromCampaignSubscriptions.visibility.hideAll());
    console.log(this.hiddenPanel, this.visiblePanel);
  }
  handlePanelsOnHideAll() {
    this.hiddenPanel.disabled = false;
    this.visiblePanel.disabled = false;
    this.visiblePanel.close();
    this.hiddenPanel.open();
    this.visiblePanel.disabled = true;
  }

  unhideAll() {
    this.store$.dispatch(fromCampaignSubscriptions.visibility.unhideAll());

  }
  handlePanelsOnUnhideAll() {
    this.hiddenPanel.disabled = false;
    this.visiblePanel.disabled = false;
    this.visiblePanel.open();
    this.hiddenPanel.close();
    this.hiddenPanel.disabled = true;
  }

  openCopyLinkDialog(id, name) {
    this.store$.dispatch(fromCampaignSubscriptions.share.openCopyLinkDialog({id, name}));
  }

  ngOnDestroy() {

  }
  constructor(
    public dialogRef: RdsDialogRef<HiddenSubscriptionsDialogComponent>,
    private store$: Store<fromCampaignSubscriptions.State>,
    private cdr: ChangeDetectorRef
  ) {

  }

}

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';

import * as fromCampaignSubscriptions from '@app/user-settings/store/campaign-subscriptions';
import {select, Store} from '@ngrx/store';
import {SubSink} from 'subsink';
import {combineLatest, Observable} from 'rxjs';
import {CampaignListModel} from '@app/user-settings/store/campaign-subscriptions';
import {startWith, tap} from 'rxjs/operators';
import {campaign} from '@app/newsletter-new/store/mock';
import { ActivatedRoute } from '@angular/router';
import { Base64 } from 'js-base64';
import { Location } from '@angular/common';

@Component({
  selector: 'atr-subscriptions',
  templateUrl: './subscriptions.container.html',
  styleUrls: ['./subscriptions.container.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionsContainer implements OnInit, OnDestroy {
  private subs = new SubSink();

  data$: Observable<Array<CampaignListModel>> = this.store$.pipe(select(fromCampaignSubscriptions.selectData), tap(data => console.log(data)));
  hiddenCampaigns$: Observable<Array<number>> = this.store$.pipe(select(fromCampaignSubscriptions.selectHiddenList));
  dataLoaded: boolean = false;
  public subscribed: CampaignListModel[] = [];
  public unsubscribed: CampaignListModel[] = [];
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['share']) {
        const jsonString = Base64.decode(params['share']);
        try {
          const data = JSON.parse(jsonString);
          this.store$.dispatch(fromCampaignSubscriptions.share.openAcceptShareDialog(data));
        } catch (e) {
          this.location.replaceState(this.location.path().split('?')[0]);
        }
      }
    });
    this.store$.dispatch(fromCampaignSubscriptions.list.request());
    this.subs.sink = 
    combineLatest([
      this.data$.pipe(startWith([])),
      this.hiddenCampaigns$.pipe(startWith([]))
    ])
    .subscribe(([data, hidden]) => {
      console.log(data, hidden)
      this.subscribed = data.filter(c => !c.isUnsubscribed).slice();
      this.unsubscribed = data.filter(c => c.isUnsubscribed).slice();
      this.cdr.markForCheck();
      this.dataLoaded = true;
    });
  }

  subscribe(id) {
    this.store$.dispatch(fromCampaignSubscriptions.subscribe.request({id}));
  }
  unsubscribe(id) {
    this.store$.dispatch(fromCampaignSubscriptions.unsubscribe.request({id}));
  }

  hide(id) {
    this.store$.dispatch(fromCampaignSubscriptions.visibility.hide({id}));
  }

  unhideAll() {
    this.store$.dispatch(fromCampaignSubscriptions.visibility.openHiddenSubscriptionModal());
  }

  openCopyLinkDialog(id, name) {
    this.store$.dispatch(fromCampaignSubscriptions.share.openCopyLinkDialog({id, name}));
  }
  ngOnDestroy() {

  }
  constructor(
    private cdr: ChangeDetectorRef,
    private location: Location,
    private store$: Store<fromCampaignSubscriptions.State>,
    private route: ActivatedRoute
  ) {
  }

  protected readonly campaign = campaign;
}

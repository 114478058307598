import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RDS_DIALOG_DATA } from '@rds/angular-components';

export interface ConfirmSendDialogData {
	recipientsLists?: any[];
	recipientsCount: number;
	recipientsRadaCount: number;
	cancelButtonLabel?: string;
	confirmButtonLabel?: string;
  isScheduled?: boolean;
}

@Component({
	selector: 'rh-confirm-send-dialog',
	templateUrl: './confirm-send-dialog.component.html',
	styleUrls: ['./confirm-send-dialog.component.scss'],
})
export class ConfirmSendDialogComponent {
	confirmation: FormGroup = new FormGroup({});

	constructor(@Inject(RDS_DIALOG_DATA) public data: ConfirmSendDialogData) {}
}

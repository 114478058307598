import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, Dictionary, EntityAdapter } from '@ngrx/entity';
import * as fromActions from './newsletter-table.actions';
import { flattenFilters, TableState } from '@app/shared/filters/filters-model';
import { Pagination } from '@app/core/models';
import { NewsletterTableModel } from '@app/core/models/newsletter.model';
import { INITIAL_NEWSLETTER_FILTERS, NewsletterTableFilters } from '@app/shared/filters/models/newsletter-table';

export interface Sugg {
	name: string;
	tickersMessage: string[];
}

export const adapter: EntityAdapter<NewsletterTableModel> = createEntityAdapter<NewsletterTableModel>({
	selectId: (e) => e.id,
});

export interface State {
	table: TableState<NewsletterTableModel, NewsletterTableFilters>;
  view: 'newsletters' | 'campaignNewsletters'
}

export const initialState: State = {
	table: {
		data: adapter.getInitialState(),
		pagination: {
			...Pagination,
			pageIndex: 0,
			pageSize: 10,
		},
		sort: {
			active: 'LastModifiedDate',
			direction: 'desc',
		},
		defaultSort: {
			active: 'LastModifiedDate',
			direction: 'desc',
		},
		filters: INITIAL_NEWSLETTER_FILTERS,
		defaultFilters: INITIAL_NEWSLETTER_FILTERS,
		isLoading: false,
		resetIndex: false,
	},
  view: null
};

const contentPageReducer = createReducer(
	initialState,
	on(fromActions.table.request, (state) => ({
		...state,
		table: {
			...state.table,
			isLoading: true,
		},
	})),
	on(fromActions.table.success, fromActions.table.failure, (state) => ({
		...state,
		table: {
			...state.table,
			isLoading: false,
		},
	})),
	on(fromActions.table.success, (state, { data, pagination, view = 'newsletters' }) => ({
		...state,
		table: {
			...state.table,
			data: adapter.setAll(data, state.table.data),
			pagination,
			resetIndex: false,
		},
    view: view
	})),
	on(fromActions.table.filter, (state, { filters }) => ({
		...state,
		table: {
			...state.table,
			filters,
			resetIndex: true,
		},
	})),
	on(fromActions.table.sort, (state, { active, direction }) => ({
		...state,
		table: {
			...state.table,
			sort: {
				active,
				direction,
			},
		},
	})),
	on(fromActions.table.paginate, (state, { pageIndex, pageSize }) => ({
		...state,
		table: {
			...state.table,
			pagination: {
				...state.table.pagination,
				pageIndex,
				pageSize,
			},
		},
	})),
	on(fromActions.renameNewsletter.success, (state, { newsletter }) => ({
		...state,
		table: {
			...state.table,
			data: adapter.updateOne({ id: newsletter.newsletterId, changes: newsletter }, state.table.data),
		},
	})),
	on(fromActions.moveToDraft.success, (state, { newsletter }) => {
		let changes = { ...newsletter, statusId: 0 };
		return {
			...state,
			table: {
				...state.table,
				data: adapter.updateOne({ id: newsletter.newsletterId, changes }, state.table.data),
			},
		};
	}),
	on(fromActions.shareNewsletter.success, (state, { newsletterContributors, id }) => {
		return {
			...state,
			table: {
				...state.table,
				data: adapter.updateOne({ id, changes: { newsletterContributors } }, state.table.data),
			},
		};
	}),
	on(fromActions.deleteNewsletter.success, (state) => ({
		...state,
		table: {
			...state.table,
			resetIndex: true,
		},
	})),
	on(fromActions.updateFromWs.update, fromActions.updateFromWs.share, (state, { data, id, action }) => ({
		...state,
		table: {
			...state.table,
			data: adapter.updateOne({ id, changes: data }, state.table.data),
		},
	})),
	on(fromActions.table.clear, (state) => ({
		...initialState,
	}))
);

export function reducer(state: State | undefined, action: Action) {
	return contentPageReducer(state, action);
}

// NEW WAY TABLE
export const getTableState = (state: State) => state.table;

export const getPagination = (table: TableState<NewsletterTableModel, NewsletterTableFilters>) => table.pagination;
export const getSort = (table: TableState<NewsletterTableModel, NewsletterTableFilters>) => table.sort;
export const getDefaultSort = (table: TableState<NewsletterTableModel, NewsletterTableFilters>) => table.defaultSort;
export const getFilters = (table: TableState<NewsletterTableModel, NewsletterTableFilters>) => table.filters;
export const getDefaultFilters = (table: TableState<NewsletterTableModel, NewsletterTableFilters>) =>
	table.defaultFilters;
export const getIsLoading = (table: TableState<NewsletterTableModel, NewsletterTableFilters>) => table.isLoading;

export const getRequestData = ({
	pagination,
	sort,
	filters,
	resetIndex,
}: TableState<NewsletterTableModel, NewsletterTableFilters>) => ({
	pageIndex: pagination.pageIndex,
	pageSize: pagination.pageSize,
	sort,
	filters: flattenFilters(filters),
	resetIndex,
});

export const getTableData = (table: TableState<NewsletterTableModel, NewsletterTableFilters>) =>
	adapter.getSelectors().selectAll(table.data);

export const getTableDataContributedIds = (table: TableState<NewsletterTableModel, NewsletterTableFilters>) =>
	getTableData(table)
		.filter((n) => n.newsletterContributors.length > 0)
		.map((n) => n.id);

export const getTableDataOnlyDrafts = (table: TableState<NewsletterTableModel, NewsletterTableFilters>) =>
	getTableData(table).every((n) => n.status === 0) && getTableData(table).length > 0;

// Pagination fields
export const getPageSize = (pagination: Pagination) => pagination.pageSize;
export const getPageIndex = (pagination: Pagination) => pagination.pageIndex;
export const getTotalCount = (pagination: Pagination) => pagination.totalCount;

// Filters fields
export const getSearch = (filters: NewsletterTableFilters) => filters.search.value;
export const getView = (state: State) => state.view

export const getEntitiesByIds = (entities: Dictionary<NewsletterTableModel>, ids: string[]): NewsletterTableModel[] =>
	ids.map((id) => entities[id]);

import { DatePipe } from '@angular/common';
import {
	FeedbackStatisticsModel,
	PeriodStatisticsModel,
	CampaignStatisticsModel,
	EMPTY_CAMPAIGN_STATS,
} from '@app/core/models/newsletter.model';
import { PeriodEnum } from '@app/shared/filters/models/newsletter-link-statistics';
import { Action, createReducer, on } from '@ngrx/store';
import * as fromActions from './statistics-all-campaign.actions';
import { periodFromDate, subperiodsFromPeriod } from '@app/newsletter-new/utils/helpers';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const CHART_COLOR_PALETE_FACTORY = (count: number) => {
	switch (count) {
		case 1:
			return ['#7C0095'];
		case 2:
			return ['#7C0095', '#C684BD'];
		case 3:
			return ['#7C0095', '#AD42C3', '#C684BD'];
		case 4:
			return ['#7C0095', '#AD42C3', '#C684BD', '#DEAABF'];
		case 5:
			return ['#7C0095', '#AD42C3', '#AD63BF', '#C684BD', '#DEAABF'];
		case 6:
			return ['#41004E', '#7C0095', '#AD42C3', '#AD63BF', '#C684BD', '#DEAABF'];
		case 7:
			return ['#41004E', '#7C0095', '#AD42C3', '#AD63BF', '#C684BD', '#DEAABF', '#FAD6C7'];
		case 8:
			return ['#41004E', '#7C0095', '#AD42C3', '#AD63BF', '#C684BD', '#DEAABF', '#FAD6C7', '#E7B198'];
		case 9:
			return ['#41004E', '#7C0095', '#AD42C3', '#AD63BF', '#C684BD', '#DEAABF', '#FAD6C7', '#E7B198', '#A26D51'];
		case 10:
			return [
				'#41004E',
				'#7C0095',
				'#AD42C3',
				'#AD63BF',
				'#C684BD',
				'#DEAABF',
				'#FAD6C7',
				'#E7B198',
				'#A26D51',
				'#C28D70',
			];
		default:
			return [];
	}
};

const newslettersAdapter = createEntityAdapter<any>({
	selectId: (n) => n.newsletterId,
});

export interface State {
	title: string;
	createdAt: Date;
	id: number;
	newsletters: EntityState<any>;
	statistics: CampaignStatisticsModel;
	periodStatistics: Array<PeriodStatisticsModel>;
	feedback: FeedbackStatisticsModel;
	filters: {
		range: {
			start: Date;
			end: Date;
		};
		period: {
			period: PeriodEnum;
			date: Date;
		};
		newsletters: {
			chartType: 'normalized' | 'stacked' | string;
			selected: Array<number>;
			defaultSelection: Array<number>;
		};
	};
	availableNewsletters: Array<any>;
}

export const initialState: State = {
	title: null,
	createdAt: null,
	id: null,
	newsletters: newslettersAdapter.getInitialState(),
	statistics: EMPTY_CAMPAIGN_STATS,
	periodStatistics: [],
	feedback: null,
	filters: {
		range: {
			start: null,
			end: null,
		},
		period: {
			period: null,
			date: null,
		},
		newsletters: {
			chartType: 'normalized',
			selected: [],
			defaultSelection: [],
		},
	},
	availableNewsletters: [],
};

const contentPageReducer = createReducer(
	initialState,
	on(fromActions.statistics.init, (state, { id }) => ({
		...state,
		id,
	})),
	on(fromActions.statistics.saveCampaignInfo, (state, { createdAt, newsletters, title }) => ({
		...state,
		createdAt,
		title,
		newsletters: newslettersAdapter.addMany(newsletters, state.newsletters),
	})),
	on(fromActions.statistics.initPeriod, (state, { period, date }) => ({
		...state,
		filters: {
			...state.filters,
			period: {
				period,
				date,
			},
		},
	})),
	on(fromActions.statistics.updatePeriodFilter, (state, { period, date }) => ({
		...state,
		filters: {
			...state.filters,
			period: {
				period,
				date,
			},
		},
	})),
	on(fromActions.statistics.successPeriod, (state, { statistics }) => ({
		...state,
		periodStatistics: statistics,
	})),
	on(fromActions.statistics.updateNewslettersFilter, (state, { chartType, selected }) => ({
		...state,
		filters: {
			...state.filters,
			newsletters: {
				chartType: chartType ? chartType : state.filters.newsletters.chartType,
				selected: selected ? selected : state.filters.newsletters.selected,
				defaultSelection: state.filters.newsletters.defaultSelection,
			},
		},
	})),
	on(fromActions.statistics.successNewsletter, (state, { newsletterDeliveryStatistics, availableNewsletters }) => ({
		...state,
		newsletterDeliveryStatistics,
		newsletterDelivery: availableNewsletters,
	})),
	on(fromActions.statistics.updateRangeFilter, (state, { start, end }) => ({
		...state,
		filters: {
			...state.filters,
			range: {
				start,
				end,
			},
		},
	})),
	on(fromActions.statistics.successDelivery, (state, { statistics, availableNewsletters }) => ({
		...state,
		statistics,
		filters: {
			...state.filters,
			newsletters: {
				chartType: state.filters.newsletters?.chartType || 'normalized',
				selected: availableNewsletters.map((n) => n.value).slice(0, 8),
				defaultSelection: availableNewsletters.map((n) => n.value).slice(0, 8),
			},
		},
		availableNewsletters,
	})),
	on(fromActions.statistics.clear, (state) => ({
		...initialState,
	}))
);

export function reducer(state: State | undefined, action: Action) {
	return contentPageReducer(state, action);
}

export const getStatistics = (state: State) => state.statistics;
export const getPeriodStatistics = (state: State) => state.periodStatistics;

export const getNewsletters = (state: State) => state.newsletters;

export const getRecipientsChartData = (statistics: CampaignStatisticsModel) => [
	{
		name: 'Newsletter recipients',
		value: statistics?.newsletters.reduce((acc, n) => acc + n.totalReceivers, 0) + '',
	},
];

export const getDeliverabilityChartData = (statistics: CampaignStatisticsModel) => {
	return {
		customColors: [
			{
				name: 'Delivered & opened',
				value: '#00A17C',
			},
			{
				name: 'Delivered not opened',
				value: '#E9B519',
			},
			{
				name: 'Newsletter bounced',
				value: '#C40000',
			},
		],
		chartData: [
			{
				name: 'Delivered & opened',
				value: statistics?.newsletters.filter((n) => n.delivered > 0).reduce((acc, n) => acc + n.uniqueOpened, 0) + '',
			},
			{
				name: 'Delivered not opened',
				value:
					statistics?.newsletters
						.filter((n) => n.delivered > 0)
						.reduce((acc, n) => acc + (n.delivered - n.uniqueOpened), 0) + '',
			},
			{
				name: 'Newsletter bounced',
				value: statistics?.newsletters.filter((n) => n.delivered > 0).reduce((acc, n) => acc + n.bounced, 0) + '',
			},
		],
	};
};

export const getClicksData = (statistics: CampaignStatisticsModel) => ({
	unique: statistics?.newsletters.reduce((acc, n) => acc + n.uniqueClicked, 0),
	total: statistics?.newsletters.reduce((acc, n) => acc + n.clicked, 0),
});

export const getSuccessfulDeliveryChart = (statistics: CampaignStatisticsModel) => [
	{
		name: 'Successful delivery',
		value: statistics?.newsletters.reduce((acc, n) => acc + n.delivered, 0) + '',
	},
];

export const getUniqueOpensChartData = (statistics: CampaignStatisticsModel) => [
	{
		name: 'Unique newsletter opened',
		value: statistics?.newsletters.reduce((acc, n) => acc + n.uniqueOpened, 0) + '',
	},
];

export const getBouncesChartData = (statistics: CampaignStatisticsModel) => [
	{
		name: 'Newsletter bounces',
		value: statistics?.newsletters.reduce((acc, n) => acc + n.bounced, 0) + '',
	},
];
export const getCampaignTitle = (state: State) => state.title;
export const getCampaignCreatedAt = (state: State) => state.createdAt;
export const getCampaignId = (state: State) => state.id;

export const getPeriodFilter = (state: State) => state.filters.period;
export const getRangeFilter = (state: State) => state.filters.range;
export const getNewslettersFilter = (state: State) => state.filters.newsletters;

export const getAvailableNewsletters = (state: State) => state.availableNewsletters;

export const getPeriodDeliveryChartData = (
	statistics: Array<PeriodStatisticsModel>,
	filter: { period: PeriodEnum; date: Date },
	newsletters: any
) => {
	if (!filter.date) return;
	let subperiods = subperiodsFromPeriod(periodFromDate(filter.period, filter.date));
	const datePipe = new DatePipe('en-US');
	const newsletterDictionary = newslettersAdapter.getSelectors().selectEntities(newsletters);
	const maxBarValue = Math.max(
		...statistics.map((p) => p.newsletters.reduce((acc, n) => acc + n.delivered + n.bounced, 0)),
		0
	);
	return {
		name: periodFromDate(filter.period, filter.date),
		colorScheme: {
			domain: ['#357AF6', '#C2BAB5'],
		},
		chartData: subperiods.map((subperiod) => {
			let label = '';
			const data = statistics.find((p) => p.period === subperiod);
			switch (filter.period) {
				case PeriodEnum.YEAR:
					label = datePipe.transform(`${subperiod}Z`, 'MMM', 'UTC');
					break;
				case PeriodEnum.MONTH:
					label = datePipe.transform(`${subperiod}Z`, 'dd (E)', 'UTC');
					break;
				case PeriodEnum.DAY:
					label = datePipe.transform(`${subperiod}Z`, 'HH:00');
					break;
			}
			return {
				name: label,
				series: [
					{
						name: 'Delivered',
						value: data ? data.newsletters.reduce((acc, n) => acc + n.delivered, 0) : 0,
						extra: {
							groupType: filter.period,
							period: subperiod,
							newsletters: data
								? data.newsletters
										.filter((n) => n.delivered > 0 || n.bounced > 0)
										.map((n) => ({
											...n,
											title: newsletterDictionary[n.newsletterId].title,
											sentDate: newsletterDictionary[n.newsletterId].sentDate,
										}))
								: [],
						},
					},
					{
						name: 'Bounced',
						value: data ? data.newsletters.reduce((acc, n) => acc + n.bounced, 0) : 0,
						extra: {
							groupType: filter.period,
							period: subperiod,
							newsletters: data
								? data.newsletters
										.filter((n) => n.delivered > 0 || n.bounced > 0)
										.map((n) => ({
											...n,
											title: newsletterDictionary[n.newsletterId].title,
											sentDate: newsletterDictionary[n.newsletterId].sentDate,
										}))
								: [],
						},
					},
				],
			};
		}),
		yScaleMax: (Math.floor(maxBarValue / 10) + 1) * 10,
	};
};

export const getPeriodClicksChartData = (
	statistics: Array<PeriodStatisticsModel>,
	filter: { period: PeriodEnum; date: Date },
	newsletters: any
) => {
	if (!filter.date) return;
	let subperiods = subperiodsFromPeriod(periodFromDate(filter.period, filter.date));
	const datePipe = new DatePipe('en-US');
	const newsletterDictionary = newslettersAdapter.getSelectors().selectEntities(newsletters);
	const maxBarValue = Math.max(...statistics.map((p) => p.newsletters.reduce((acc, n) => acc + n.uniqueClicked, 0)), 0);
	return {
		name: periodFromDate(filter.period, filter.date),
		colorScheme: {
			domain: ['#7D0096'],
		},
		chartData: subperiods.map((subperiod) => {
			let label = '';
			const data = statistics.find((p) => p.period === subperiod);
			switch (filter.period) {
				case PeriodEnum.YEAR:
					label = datePipe.transform(`${subperiod}Z`, 'MMM', 'UTC');
					break;
				case PeriodEnum.MONTH:
					label = datePipe.transform(`${subperiod}Z`, 'dd (E)', 'UTC');
					break;
				case PeriodEnum.DAY:
					label = datePipe.transform(`${subperiod}Z`, 'HH:00');
					break;
			}
			return {
				name: label,
				series: [
					{
						name: 'Newsletter link clicks',
						value: data ? data.newsletters.reduce((acc, n) => acc + n.uniqueClicked, 0) : 0,
						extra: {
							groupType: filter.period,
							period: subperiod,
							newsletters: data
								? data.newsletters
										.filter((n) => n.uniqueClicked > 0)
										.map((n) => ({
											...n,
											title: newsletterDictionary[n.newsletterId].title,
											sentDate: newsletterDictionary[n.newsletterId].sentDate,
										}))
								: [],
						},
					},
				],
			};
		}),
		yScaleMax: (Math.floor(maxBarValue / 10) + 1) * 10,
	};
};

export const getNewsletterDeliveryNormalizedChartData = (
	statistics: CampaignStatisticsModel,
	filter: { chartType: 'normalized' | 'stacked' | string; selected: Array<number> }
) => {
	if (!statistics) return;
	const filteredNewsletters = statistics.newsletters.filter(
		(n) => n.delivered > 0 && filter.selected.includes(n.newsletterId)
	);
	const maxBarValue = Math.max(...filteredNewsletters.map((n) => n.delivered + n.bounced), 0);
	return {
		name: 'Normalized newsletter delivery data',
		customColors: [
			{
				name: 'Delivered & opened',
				value: '#00A17C',
			},
			{
				name: 'Delivered not opened',
				value: '#E9B519',
			},
			{
				name: 'Newsletter bounced',
				value: '#C40000',
			},
		],
		chartData: filteredNewsletters.map((n) => ({
			name: `${n.newsletterTitle} | ${n.newsletterId}`,
			series: [
				{
					name: 'Delivered & opened',
					value: n?.uniqueOpened,
					extra: {
						newsletterName: n.newsletterTitle,
						newsletterSentDate: n.newsletterSentDate,
						total: n.delivered + n.bounced,
					},
				},
				{
					name: 'Delivered not opened',
					value: n?.delivered - n?.uniqueOpened,
					extra: {
						newsletterName: n.newsletterTitle,
						newsletterSentDate: n.newsletterSentDate,
						total: n.delivered + n.bounced,
					},
				},
				{
					name: 'Newsletter bounced',
					value: n?.bounced,
					extra: {
						newsletterName: n.newsletterTitle,
						newsletterSentDate: n.newsletterSentDate,
						total: n.delivered + n.bounced,
					},
				},
			],
		})),
		xScaleMax: (Math.floor(maxBarValue / 10) + 1) * 10,
	};
};

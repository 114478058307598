<h2 rds-dialog-header>
	<ng-container *ngIf="data.settings.channel"> Edit Channel section </ng-container>
	<ng-container *ngIf="!data.settings.channel"> Add channel section </ng-container>
</h2>
<div rds-dialog-content cdkScrollable style="overflow-x: hidden">
	<ng-container [formGroup]="channelForm">
		<div class="row">
			<div class="col-5">
				<div class="row row-centered">
					<div class="col">
						<p class="ui-body-l-bold--xl mb-3">Select channel</p>
					</div>
					<!-- <div *ngIf="!!channelForm.controls.channel.value" class="col-auto">
						<button rds-icon-button size="s" rdsTooltip="Refresh channel list" (click)="refreshChannelNews()">
							<rds-icon namespace="outlined" icon="refresh"></rds-icon>
						</button>
					</div> -->
				</div>
				<div class="mb-5">
					<rds-form-field>
						<rds-control-label>Which channel do you want news from?</rds-control-label>
						<rds-select
							#select
							formControlName="channel"
							(valueChange)="select.resetFilterValue(); channelChange($event)"
							[filterBy]="filterBy"
							[compareWith]="compareChannels"
						>
							<rds-select-search-input></rds-select-search-input>
							<rds-select-option *ngFor="let channel of channels$ | async" [value]="channel">
								{{ channel.name }}
							</rds-select-option>
						</rds-select>
					</rds-form-field>
				</div>
				<ng-container *ngIf="!!channelForm.controls.channel.value">
					<p class="ui-body-l-bold--xl mb-3">Section title</p>
					<div class="mb-3">
						<rds-switch formControlName="addLink" checked>Add section title</rds-switch>
					</div>
					<ng-container *ngIf="channelForm.controls.addLink.value">
						<rds-form-field class="mb-7">
							<rds-control-label>Title</rds-control-label>
							<input rdsInput formControlName="linkText" />
							<rds-control-hint>Section title is link to the channel</rds-control-hint>
						</rds-form-field>
					</ng-container>
					<p class="ui-body-l-bold--xl mb-3">News selection type</p>
					<div class="mb-5">
						<rds-radio-group formControlName="selectionType" class="inline">
							<rds-radio-button [value]="'recent'">recent</rds-radio-button>
							<rds-radio-button [value]="'manual'">manual</rds-radio-button>
						</rds-radio-group>
						<rh-subscript-wrapper [hasError]="false">
							<rds-control-hint *ngIf="channelForm.controls.selectionType.value === 'recent'">
								Takes a selected amount of the latest News
							</rds-control-hint>
							<rds-control-hint *ngIf="channelForm.controls.selectionType.value === 'manual'">
								Allows you to manualy select News
							</rds-control-hint>
						</rh-subscript-wrapper>
					</div>
					<ng-container *ngIf="channelForm.controls.selectionType.value === 'recent'" [formGroup]="recentFormGroup">
						<p class="ui-body-l-bold--xl mb-3">How many recent News you want to add?</p>
						<div class="mb-5">
							<rh-number-select
								singular="news"
								plural="news"
								[max]="allNews.length"
								formControlName="newsCount"
							></rh-number-select>
						</div>
					</ng-container>
					
				</ng-container>
			</div>
			<div *ngIf="channelForm.controls.selectionType.value === 'recent'" class="col-7" [formGroup]="recentFormGroup">
				<p class="ui-body-l-bold--xl mb-3">Selected News</p>
				<div *ngIf="recentFormGroup.controls.selectedNews as newsFormArray" class="news-container rds-shadow-2">
					<div
						*ngIf="newsFormArray.controls.length === 0"
						class="bg-100-neutral-1"
						style="display: flex; align-items: center; justify-content: center; min-height: 112px; margin: 8px 24px"
					>
						<p *ngIf="!channelForm.controls.channel.value" class="ui-component-text-s text-center">
							Selected News will appear here
						</p>
						<ng-container *ngIf="!!channelForm.controls.channel.value">
							<div style="display: flex; flex-direction: column; align-items: center; justify-content: center">
								<p class="ui-component-text-s text-center">Loading news...</p>
								<rds-progress-bar></rds-progress-bar>
							</div>
						</ng-container>
					</div>
					<p *ngIf="channelForm.controls.addLink.value === true" class="ui-body-l-bold--xl px-7">
						{{ channelForm.controls.linkText.value }}
					</p>
					<div
						*ngFor="let newsFormGroup of newsFormArray.controls; let index = index"
						class="news-container__item"
						[formGroup]="newsFormGroup"
					>
						<rh-news-element
							style="zoom: 60%; display: inline; flex: 1; margin-bottom: -24px"
							[forceDisabledAnimation]="true"
							[blockProperties]="{
								card: false,
								flipped: false,
								news: newsFormGroup.controls.news.value,
								newsLanguage: newsFormGroup.controls.language.value
							}"
						></rh-news-element>
						<div class="news-container__item--actions">
							<rds-form-field size="s">
								<rds-select #select formControlName="language">
									<rds-select-option
										*ngIf="newsFormGroup.controls.language.value as newsLanguage"
										[value]="newsFormGroup.controls.language.value"
									>
										{{ newsFormGroup.controls.language.value | languageName | async }}
										<span
											*ngIf="
												newsFormGroup.controls.news.value.primaryLanguage === newsFormGroup.controls.language.value
											"
											>(primary)</span
										>
									</rds-select-option>
									<ng-container
										ngProjectAs="rds-select-option"
										*ngFor="let language of newsFormGroup.controls.news.value.abstracts | keyvalue as abstracts"
									>
										<rds-select-option
											*ngIf="language.key !== newsFormGroup.controls.language.value"
											[value]="language.key"
										>
											{{ language.key | languageName | async }}
											<span *ngIf="newsFormGroup.controls.news.value.primaryLanguage === language.key">(primary)</span>
										</rds-select-option>
									</ng-container>
								</rds-select>
							</rds-form-field>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="channelForm.controls.selectionType.value === 'manual'" class="col-7" [formGroup]="manualFormGroup">
				<p class="ui-body-l-bold--xl mb-3">Selected News</p>
				<div
					*ngIf="manualFormGroup.controls.selectedNews as newsFormArray"
					cdkDropList
					(cdkDropListDropped)="drop($event)"
					class="news-container rds-shadow-2"
				>
					<div
						*ngIf="newsFormArray.controls.length === 0"
						class="bg-100-neutral-1"
						style="display: flex; align-items: center; justify-content: center; min-height: 112px; margin: 8px 24px"
					>
						<p *ngIf="!channelForm.controls.channel.value" class="ui-component-text-s text-center">
							Selected News will appear here
						</p>
						<ng-container *ngIf="!!channelForm.controls.channel.value">
							<div style="display: flex; flex-direction: column; align-items: center; justify-content: center">
								<p class="ui-component-text-s text-center">Loading news...</p>
								<rds-progress-bar></rds-progress-bar>
							</div>
						</ng-container>
					</div>
					<p *ngIf="channelForm.controls.addLink.value === true" class="ui-body-l-bold--xl px-7">
						{{ channelForm.controls.linkText.value }}
					</p>
					<div
						*ngFor="let newsFormGroup of newsFormArray.controls; let index = index"
						[cdkDragPreviewContainer]="'parent'"
						cdkDragLockAxis="y"
						cdkDrag
						class="news-container__item"
						[formGroup]="newsFormGroup"
					>
						<div class="news-container__item--drag" cdkDragHandle>
							<rds-icon icon="drag" namespace="outlined"></rds-icon>
						</div>
						<rh-news-element
							style="zoom: 60%; display: inline; flex: 1; margin-bottom: -24px"
							[forceDisabledAnimation]="true"
							[blockProperties]="{
								card: false,
								flipped: false,
								news: newsFormGroup.controls.news.value,
								newsLanguage: newsFormGroup.controls.language.value
							}"
						></rh-news-element>
						<div class="news-container__item--actions">
							<rds-form-field size="s">
								<rds-select #select formControlName="language">
									<rds-select-option
										*ngIf="newsFormGroup.controls.language.value as newsLanguage"
										[value]="newsFormGroup.controls.language.value"
									>
										{{ newsFormGroup.controls.language.value | languageName | async }}
										<span
											*ngIf="
												newsFormGroup.controls.news.value.primaryLanguage === newsFormGroup.controls.language.value
											"
											>(primary)</span
										>
									</rds-select-option>
									<ng-container
										ngProjectAs="rds-select-option"
										*ngFor="let language of newsFormGroup.controls.news.value.abstracts | keyvalue as abstracts"
									>
										<rds-select-option
											*ngIf="language.key !== newsFormGroup.controls.language.value"
											[value]="language.key"
										>
											{{ language.key | languageName | async }}
											<span *ngIf="newsFormGroup.controls.news.value.primaryLanguage === language.key">(primary)</span>
										</rds-select-option>
									</ng-container>
								</rds-select>
							</rds-form-field>
							<button rds-icon-button size="s" rdsTooltip="Hide" (click)="deselectNews(newsFormArray, index)">
								<rds-icon namespace="outlined" icon="hide"></rds-icon>
							</button>
						</div>
					</div>
					<ng-container
						*ngIf="
							allNews
								| filterArray
									: filterChannelNews
									: manualFormGroup.controls.languageFilter.value
									: manualFormGroup.controls.selectedNews.value as filteredNews
						"
					>
						<ng-container *ngIf="channelForm.controls.selectionType.value === 'manual' && allNews.length > 0">
							<rds-divider></rds-divider>
							<ng-container *ngIf="availableLanguages.length > 0">
								<rds-chip-list type="choice" size="s" formControlName="languageFilter" class="px-5">
									<rds-chip-selectable *ngFor="let language of availableLanguages" [value]="language">
										{{ language | languageName | async }}
									</rds-chip-selectable>
								</rds-chip-list>
							</ng-container>
							<div
								*ngIf="filteredNews.length === 0"
								class="bg-100-neutral-1"
								style="display: flex; align-items: center; justify-content: center; min-height: 112px; margin: 8px 24px"
							>
								<p class="ui-component-text-s text-center">
									There is no more news to add
									{{
										manualFormGroup.controls.languageFilter.value === null
											? 'in selected channel'
											: 'for selected language'
									}}
								</p>
							</div>
							<div *ngFor="let news of filteredNews" class="news-container__item news-container__item--not-added">
								<rh-news-element
									style="zoom: 60%; display: inline; flex: 1; margin-bottom: -24px"
									[forceDisabledAnimation]="true"
									[blockProperties]="{
										card: false,
										flipped: false,
										news: news,
										newsLanguage: manualFormGroup.controls.languageFilter.value
									}"
								></rh-news-element>
								<div class="news-container__item--actions">
									<button
										rds-icon-button
										size="s"
										rdsTooltip="Show"
										(click)="selectNews(newsFormArray, news, manualFormGroup.controls.languageFilter.value)"
									>
										<rds-icon namespace="outlined" icon="show"></rds-icon>
									</button>
								</div>
							</div>
						</ng-container>
					</ng-container>
				</div>
			</div>
		</div>
	</ng-container>
</div>
<div rds-dialog-actions-container align="end">
	<button rds-secondary-button [rds-dialog-close]="null" size="m">Cancel</button>
	<button rds-primary-button [disabled]="channelForm.invalid" size="m" (click)="submit()">Save changes</button>
</div>

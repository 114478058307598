import {Injectable} from '@angular/core';
import {NewsletterService} from '@app/core/services/newsletter.service';
import {UserProfileService} from '@app/core/services/userProfile.service';
import {
    SaveNewsletterPreviewDialogComponent
} from '@app/newsletter/dialogs/save-newsletter-preview-dialog/save-newsletter-preview-dialog.component';
import {SendTestDialogComponent} from '@app/newsletter/dialogs/send-test-dialog/send-test-dialog.component';
import {BlockTypes} from '@app/newsletter/inline-editor/models/block-type.enum';
import {NewsletterInlineForm} from '@app/newsletter/models';
import {NewsletterEditType} from '@app/newsletter/models/newsletter-edit-type.enum';
import * as fromNewsletterBlocksActions from '@app/newsletter/store/newsletter-blocks/newsletter-blocks.actions';
import * as fromNewsletterBlocksSelectors from '@app/newsletter/store/newsletter-blocks/newsletter-blocks.selectors';
import * as fromDashboard from '@app/newsletter/store/newsletter-dashboard/newsletter-dashboard.actions';
import * as fromActions from '@app/newsletter/store/newsletter-inline/newsletter-inline.actions';
import * as fromSelectors from '@app/newsletter/store/newsletter-inline/newsletter-inline.selectors';
import * as fromWS from '@app/newsletter/store/newsletter-websocket/newsletter-websocket.actions';
import * as fromRouter from '@app/root-store/router/router.actions';
import * as fromBackButton from '@app/root-store/ui/back-button';
import * as fromUiReducer from '@app/root-store/ui/ui.reducer';
import {ConfirmDialogComponent} from '@app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import * as fromChannels from '@core/core-store/channels';
import * as fromChannelsNews from '@core/core-store/channels-news';
import * as fromDepartmentsFlat from '@core/core-store/departments-flat';
import * as fromFunctionsFlat from '@core/core-store/functions-flat';
import * as fromLocationsFlat from '@core/core-store/locations-flat';
import * as fromUser from '@core/user/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {RdsDialogService} from '@rds/angular-components';
import {of} from 'rxjs';
import {catchError, filter, first, map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';

@Injectable()
export class NewsletterInlineEffects {

    public uploadBannerPhotoRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.uploadBannerPhotoRequest),
            switchMap(({file, bannerPhotoName, bannerPhotoSize}) => this.newsletterService.uploadFile(file).pipe(
                map((url) => fromActions.uploadBannerPhotoSuccess({bannerPhotoUrl: url, bannerPhotoName, bannerPhotoSize}),
                    catchError(({message}) => of(fromActions.uploadBannerPhotoFailure({message}))))
            ))
        ), {dispatch: true}
    );

    public getEmailFrom$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.getEmailFromNewsletter),
            mergeMap(() =>
                this.newsletterService.getEmailFrom().pipe(
                    map((fromEmail) => fromActions.getEmailFromNewsletterSuccess({fromEmail})),
                    catchError(() => of(fromActions.getEmailFromNewsletterFailure())
                    )))
        ), {dispatch: true}
    );

    public sendNewsletter$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.sendNewsletter),
            withLatestFrom(
                this.store$.pipe(select(fromSelectors.selectNewsletterRequestData)),
            ),
            switchMap(([action, data]) =>
                this.newsletterService.publishInline(data.form as NewsletterInlineForm, data.sections).pipe(
                    mergeMap(() => [
                        fromActions.sendNewsletterSuccess(),
                        fromWS.wsStopWork({newsletterId: data.form.newsletterId}),
                        fromDashboard.getDrafts(),
                        fromRouter.go({
                            path: 'newsletter',
                            queryParams: {}
                        }),
                    ]),
                    catchError(({message}) => of(fromActions.sendNewsletterFailure({message}))
                    )))
        ), {dispatch: true}
    );

    public sendNewsletterFromDraft$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.sendNewsletterFromDraft),
            withLatestFrom(
                this.store$.pipe(select(fromSelectors.selectNewsletterRequestData)),
            ),
            switchMap(([action, data]) =>
                this.newsletterService.publishInlineFromDraft(data.form as NewsletterInlineForm, data.sections).pipe(
                    mergeMap(() => [
                        fromRouter.go({
                            path: 'newsletter',
                            queryParams: {}
                        }),
                        fromDashboard.getDrafts(),
                        fromActions.sendNewsletterFromDraftSuccess(),
                        fromWS.wsStopWork({newsletterId: data.form.newsletterId}),
                    ]),
                    catchError(({message}) => of(fromActions.sendNewsletterFromDraftFailure({message}))
                    )))
        ), {dispatch: true}
    );

    public saveAsDraft$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.saveAsDraft),
            withLatestFrom(this.store$.pipe(select(fromSelectors.selectNewsletterRequestData))),
            switchMap(([action, data]) =>
                this.newsletterService.createDraftInline(data.form as NewsletterInlineForm, data.sections).pipe(
                    mergeMap(() => [
                        fromRouter.go({
                            path: 'newsletter',
                            queryParams: {}
                        }),
                        fromDashboard.getNewsletters(),
                        fromDashboard.getDrafts(),
                        fromActions.saveAsDraftSuccess(),
                        fromWS.wsStopWork({newsletterId: data.form.newsletterId})
                    ]),
                    catchError(({message}) => of(fromActions.saveAsDraftFailure({message}))
                    )))
        ), {dispatch: true}
    );

    public updateAsDraft$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.updateAsDraft),
            withLatestFrom(this.store$.pipe(select(fromSelectors.selectNewsletterRequestData))),
            switchMap(([action, data]) =>
                this.newsletterService.updateDraftInline(data.form as NewsletterInlineForm, data.sections).pipe(
                    mergeMap(() => [
                        fromRouter.go({
                            path: 'newsletter',
                            queryParams: {}
                        }),
                        fromDashboard.getNewsletters(),
                        fromDashboard.getDrafts(),
                        fromActions.updateAsDraftSuccess(),
                        fromWS.wsStopWork({newsletterId: data.form.newsletterId})
                    ]),
                    catchError(({message}) => of(fromActions.updateAsDraftFailure({message}))
                    )))
        ), {dispatch: true}
    );

    public getNewsletterById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.getNewsletterById),
            mergeMap((data) =>
                this.newsletterService.getNewsletter(data.id).pipe(
                    filter(result => {
                        return result.templateId === 'NewsletterTemplateInlineEditor'
                    }),
                    map((result) => this.newsletterService.parseInlineContentData(result, data.id)),
                    mergeMap(({form, sections}) => {
                        const channelsToLoad = [];
                        sections.forEach(s =>
                            s.subsections.forEach(ss =>
                                ss.blocks.forEach(b => {
                                    if (b.type === BlockTypes.NEWS) {
                                        const regexp: RegExp = /data-newsid='(.*?)'/gm;
                                        const channelId = regexp.exec(b.markup)[1].match(/(.*?:)(.*?)(?=:)/g)[0];
                                        channelsToLoad.push(channelId);
                                    }
                                })
                            )
                        );
                        return [
                            fromActions.loadChannelDataForNewsletter({channelIds: channelsToLoad}),
                            fromActions.setWholeForm({form}),
                            fromNewsletterBlocksActions.setSections({sections}),
                        ];
                    }),
                    catchError(() => of(fromActions.getNewsletterByIdFailure())
                    )))
        ), {dispatch: true}
    );

    public loadChannelDataForNewsletter$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.loadChannelDataForNewsletter),
            switchMap(({channelIds}) => this.store$.pipe(
                select(fromChannels.selectAll),
                first(channels => channels.length > 0),
                map(channels => ({channelIds}))
            )),
            mergeMap(({channelIds}) => {
                const loadChannelActions = [];
                channelIds.forEach(channelId => {
                    loadChannelActions.push(fromChannelsNews.addChannel({channelId}));
                });
                return loadChannelActions;
            })
        ), {dispatch: true}
    );

    public getNewsletterByIdFailure$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.getNewsletterByIdFailure),
            map(() => fromRouter.go({path: 'newsletter', queryParams: {}}))
        ), {dispatch: true}
    );

    public putSearchingPreferencesCount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.putSearchingPreferencesCountRequest),
            mergeMap(({preferencesIds}) => {
                    const mappedPreferencesIds = {...preferencesIds};
                    if (!preferencesIds.locations.length) {
                        this.store$.pipe(select(fromLocationsFlat.selectRoot),
                            first(allLocations => allLocations.length > 0)).subscribe((allLocations) =>
                            mappedPreferencesIds.locations = [allLocations[0].id]);
                    }
                    if (!preferencesIds.departments.length) {
                        this.store$.pipe(select(fromDepartmentsFlat.selectRoot),
                            first(allDepartments => allDepartments.length > 0)).subscribe((allDepartments) =>
                            mappedPreferencesIds.departments = [allDepartments[0].id]);
                    }
                    if (!preferencesIds.functions.length) {
                        this.store$.pipe(select(fromFunctionsFlat.selectRoot),
                            first(allFunction => allFunction.length > 0)).subscribe((allFunction) =>
                            mappedPreferencesIds.functions = [allFunction[0].id]);
                    }

                    return this.userProfileService.putSearchingPreferencesCount(mappedPreferencesIds).pipe(
                        map((usersCount) => fromActions.putSearchingPreferencesCountSuccess({usersCount})),
                        catchError(({message}) => of(fromActions.putSearchingPreferencesCountFailure({error: message})))
                    );
                }
            )
        ), {dispatch: true}
    );


    public openConfirmLeaveDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.openConfirmLeaveDialog),
            withLatestFrom(
                this.store$.pipe(select(fromSelectors.selectEditType)),
                this.store$.pipe(select(fromSelectors.selectCanSaveAsDraft))
            ),
            switchMap(([action, editType, canSave]) => this.dialogService.open(ConfirmDialogComponent, {
                data: {
                    title: 'Do you want to save the newsletter as a draft before exiting?',
                    messages: [],
                    confirmationOptions: {
                        options: [
                            {
                                label: 'Yes, save as draft and exit',
                                value: 'saveAndExit',
                                disabled: !canSave,
                                reason: `Newsletter without Title can not be saved as draft`
                            },
                            {
                                label: 'No, exit without saving',
                                value: 'exit',
                                disabled: false
                            }
                        ]
                    },
                    confirmButtonLabel: 'Exit process',
                    confirmButtonType: 'primary'
                }
            }).afterClosed().pipe(
                filter(data => !!data),
                map((data) => {
                    switch (data.confirmationOptions.value) {
                        case 'exit': {
                            return fromBackButton.back({defaultLabel: 'Newsletter Dashboard', defaultRoute: 'newsletter'});
                        }
                        case 'saveAndExit': {
                            return fromActions.openSaveAsDraftDialog()
                        }
                    }
                })
            ))
        ));

    public openSaveAsDraftDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.openSaveAsDraftDialog),
            withLatestFrom(
                this.store$.pipe(select(fromSelectors.selectEditType))
            ),
            switchMap(([action, editType]) => this.dialogService.open(SaveNewsletterPreviewDialogComponent, {
                data: {
                    form: this.store$.pipe(select(fromSelectors.selectForm)),
                    sections: this.store$.pipe(select(fromNewsletterBlocksSelectors.selectSections)),
                    templateId: 'NewsletterTemplateInlineEditor'
                },
                size: 'm',
                disableClose: true,
                closeOnNavigation: false,
            }).afterClosed().pipe(
                filter(({url, error}) => !!url),
                map(({url, error}) => ({url, error, editType}))
            )),
            mergeMap(({url, error, editType}) => {
                let saveAction;
                switch (editType) {
                    case NewsletterEditType.EDIT: {
                        saveAction = fromActions.updateAsDraft();
                        break;
                    }
                    case NewsletterEditType.REUSE: {
                        saveAction = fromActions.saveAsDraft();
                        break;
                    }
                    case NewsletterEditType.NEW: {
                        saveAction = fromActions.saveAsDraft();
                        break;
                    }
                    default: {
                        saveAction = fromActions.saveAsDraft();
                        break;
                    }
                }
                return [
                    fromActions.setImagePreviewUrl({imagePreviewUrl: url}),
                    saveAction
                ];
            })
        )
    );

    public openSendTestDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.openSendTestDialog),
            withLatestFrom(this.store$.pipe(select(fromUser.selectEmail))),
            switchMap(([action, email]) => this.dialogService.open(SendTestDialogComponent, {
                data: {
                    loggedUserEmail: email
                },
                size: 'm',
                disableClose: true,
                closeOnNavigation: false,
            }).afterClosed().pipe(
                filter(data => !!data),
                map(({to}) => fromActions.sendTest({to}))
            ))
        )
    );

    public sendTest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.sendTest),
            withLatestFrom(this.store$.pipe(select(fromSelectors.selectNewsletterRequestData))),
            switchMap(([{to}, {form, sections}]) =>
                this.newsletterService.sendTestInline({...form, to, toGroups: ''} as NewsletterInlineForm, sections).pipe(
                    mergeMap(() => [
                        fromActions.sendTestSuccess()]),
                    catchError(({message}) => of(fromActions.sendTestFailure())
                    )))
        ), {dispatch: true}
    );

    public openSendNewsletterDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.openSendNewsletterDialog),
            withLatestFrom(
                this.store$.pipe(select(fromSelectors.selectPublishDialogDataRecipientsTextAndWhenToSend)),
                this.store$.pipe(select(fromSelectors.selectEditType))
            ),
            switchMap(([action, {recipients, whenToSend}, editType]) => this.dialogService.open(ConfirmDialogComponent, {
                data: {
                    title: 'Are you sure you want to send out this newsletter?',
                    messages: [
                        `When you click on “Yes, ${whenToSend === 1 ? 'schedule' : 'send'} now” this newsletter will be sent to ${recipients}`,
                        'After sending you will not be able to undo this action. Please use this newsletter tool responsibly as your name will be included in the disclaimer in the footer.'
                    ],
                    confirmButtonType: 'primary',
                    confirmButtonLabel: `Yes, ${whenToSend === 1 ? 'schedule' : 'send'} now`
                },
                size: 'l'
            }).afterClosed().pipe(
                filter(data => !!data),
                map(() => ({editType}))
            )),
            switchMap(({editType}) => this.dialogService.open(SaveNewsletterPreviewDialogComponent, {
                data: {
                    sections: this.store$.pipe(select(fromSelectors.selectForm)),
                    form: this.store$.pipe(select(fromNewsletterBlocksSelectors.selectSections)),
                    templateId: 'NewsletterTemplateInlineEditor'
                },
                size: 'm',
                disableClose: true,
                closeOnNavigation: false,
            }).afterClosed().pipe(
                filter(({url, error}) => !!url),
                map(({url, error}) => ({url, error, editType}))
            )),
            mergeMap(({url, error, editType}) => {
                let saveAction;
                switch (editType) {
                    case NewsletterEditType.EDIT: {
                        saveAction = fromActions.sendNewsletterFromDraft();
                        break;
                    }
                    case NewsletterEditType.REUSE: {
                        saveAction = fromActions.sendNewsletter();
                        break;
                    }
                    case NewsletterEditType.NEW: {
                        saveAction = fromActions.sendNewsletter();
                        break;
                    }
                    default: {
                        saveAction = fromActions.sendNewsletter();
                        break;
                    }
                }
                return [
                    fromActions.setImagePreviewUrl({imagePreviewUrl: url}),
                    saveAction
                ];
            })
        )
    );

    constructor(
        private actions$: Actions,
        private store$: Store<fromUiReducer.State>,
        private newsletterService: NewsletterService,
        private userProfileService: UserProfileService,
        private dialogService: RdsDialogService
    ) {
    }
}

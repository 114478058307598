<h2 rds-dialog-header>Your Subscriptions</h2>
<div rds-dialog-content>
  <rds-accordion >
    <rds-expansion-panel #hiddenPanel expanded="true">
      <rds-expansion-panel-header>
        <rds-panel-title>
          Hidden Subscriptions
        </rds-panel-title>
      </rds-expansion-panel-header>
      <ng-container *ngIf="hidden.length;">
        <div class="row">
          <div class="col-auto offset-auto">
            <button rds-text-button (mousedown)="unhideAll()">
              Show all
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-s-4 col-6 py-3">
          </div>
          <div class="col-s-3 col-2 py-3">
            <div class="row">
              <div class="col-auto offset-both">
                <p class="ui-label-s-bold text-low-contrast text-nowrap">
                  Subscribe
                </p>
              </div>
            </div>
          </div>
          <div class="col-s-3 col-2 py-3">
            <div class="row">
              <div class="col-auto offset-both">
                <p class="ui-label-s-bold text-low-contrast text-nowrap">
                  Show
                </p>
              </div>
            </div>
          </div>
          <div class="col-s-3 col-2 py-3">
            <div class="row">
              <div class="col-auto offset-both">
                <p class="ui-label-s-bold text-low-contrast text-nowrap">
                  Share
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3" *ngFor="let campaign of hidden">
          <div class="col-s-4 col-6">
            <p class="ui-label-m-bold">
              {{campaign.campaignName}}
            </p>
          </div>
          <div class="col-s-3 col-2">
            <div class="row">
              <div class="col-auto offset-both">
                <rds-switch [disabled]="(campaign | isSubscriptionProcessed | async) || (campaign.isUnsubscribed && !campaign.allowToSubscribe) || (!campaign.isUnsubscribed && !campaign.allowToUnsubscribe)" [checked]="!campaign.isUnsubscribed" (buttonChanged)="$event.checked ? subscribe(campaign.campaignId) : unsubscribe(campaign.campaignId)"></rds-switch>
              </div>
            </div>
          </div>
          <div class="col-s-3 col-2">
            <div class="row">
              <div class="col-auto offset-both">
                <button rds-icon-button size="m" (click)="show(campaign.campaignId)">
                  <rds-icon icon="show" namespace="outlined"></rds-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="col-s-3 col-2">
            <div class="row">
              <div class="col-auto offset-both">
                <button rds-icon-button size="m" (click)="openCopyLinkDialog(campaign.campaignId, campaign.campaignName)">
                  <rds-icon icon="share" namespace="outlined"></rds-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </rds-expansion-panel>
    <rds-expansion-panel #visiblePanel>
      <rds-expansion-panel-header>
        <rds-panel-title>
          Visible Subscriptions
        </rds-panel-title>
      </rds-expansion-panel-header>
      <ng-container *ngIf="visible.length;">
        <div class="row">
          <div class="col-auto offset-auto">
              <button rds-text-button (mousedown)="hideAll()">
                Hide all
              </button>
          </div>
        </div>
        <div class="row">
          <div class="col-s-4 col-6 py-3">
          </div>
          <div class="col-s-3 col-2 py-3">
            <div class="row">
              <div class="col-auto offset-both">
                <p class="ui-label-s-bold text-low-contrast text-nowrap">
                  Subscribe
                </p>
              </div>
            </div>
          </div>
          <div class="col-s-3 col-2 py-3">
            <div class="row">
              <div class="col-auto offset-both">
                <p class="ui-label-s-bold text-low-contrast text-nowrap">
                  Hide
                </p>
              </div>
            </div>
          </div>
          <div class="col-s-3 col-2 py-3">
            <div class="row">
              <div class="col-auto offset-both">
                <p class="ui-label-s-bold text-low-contrast text-nowrap">
                  Share
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3" *ngFor="let campaign of visible">
          <div class="col-s-4 col-6">
            <p class="ui-label-m-bold">
              {{campaign.campaignName}}
            </p>
          </div>
          <div class="col-s-3 col-2">
            <div class="row">
              <div class="col-auto offset-both">
                <rds-switch [disabled]="(campaign | isSubscriptionProcessed | async) || (campaign.isUnsubscribed && !campaign.allowToSubscribe) || (!campaign.isUnsubscribed && !campaign.allowToUnsubscribe)" [checked]="!campaign.isUnsubscribed" (buttonChanged)="$event.checked ? subscribe(campaign.campaignId) : unsubscribe(campaign.campaignId)"></rds-switch>
              </div>
            </div>
          </div>
          <div class="col-s-3 col-2">
            <div class="row">
              <div class="col-auto offset-both">
                <button rds-icon-button size="m" (click)="hide(campaign.campaignId)">
                  <rds-icon icon="hide" namespace="outlined"></rds-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="col-s-3 col-2">
            <div class="row">
              <div class="col-auto offset-both">
                <button rds-icon-button size="m" (click)="openCopyLinkDialog(campaign.campaignId, campaign.campaignName)">
                  <rds-icon icon="share" namespace="outlined"></rds-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </rds-expansion-panel>
  </rds-accordion>
    <ng-template #noHiddenCampaigns>
      <div class="row">
        <div class="col">
          <rds-empty-state alignment="left" size="s">
            <img rds-empty-state-image src="assets/images/empty-state.png" alt="No data" />
            <rds-empty-state-title>
              All newsletter campaigns are visible
            </rds-empty-state-title>
            <button rds-primary-button rds-empty-state-button (click)="hideAll()">Hide all</button>
          </rds-empty-state>
        </div>
      </div>
    </ng-template>
    <ng-template #noVisibleCampaigns>
      <div class="row">
        <div class="col">
          <rds-empty-state alignment="left" size="s">
            <img rds-empty-state-image src="assets/images/empty-state.png" alt="No data" />
            <rds-empty-state-title>
              All newsletter campaigns are hidden
            </rds-empty-state-title>
            <button rds-primary-button rds-empty-state-button (click)="unhideAll()">Show all</button>
          </rds-empty-state>
        </div>
      </div>
    </ng-template>
</div>
<div rds-dialog-actions-container align="end">
  <button rds-secondary-button [rds-dialog-close]="false">Close</button>
</div>
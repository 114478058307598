import { Pipe, PipeTransform } from '@angular/core';
import { CampaignListModel } from '@app/user-settings/store/campaign-subscriptions';
import { Store, select } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import * as fromSubscriptions from '../../store/campaign-subscriptions'
@Pipe({
  name: 'isSubscriptionProcessed'
})
export class IsSubscriptionProcessedPipe implements PipeTransform {
  transform(value: CampaignListModel): Observable<boolean> {
    return this.store$.pipe(
      select(fromSubscriptions.selectBlockedList),
      map(list => list.includes(value.campaignId))
    );
  }

  constructor(
    private store$: Store<any>) {
  }
}

import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {RdsDialogService} from '@rds/angular-components';
import * as fromReducer from './root-newsletter.reducer';
import {NewsletterService} from '@app/core/services/newsletter.service';
import * as fromActions from '@app/newsletter-new/store/root-newsletter/root-newsletter.actions';
import * as fromSelectors from '@app/newsletter-new/store/campaign-form/campaign-form.selectors';
import * as fromTemplatesFrom from '@app/newsletter-new/store/template-form';
import {filter, map, mergeMap, of, switchMap} from 'rxjs';
import {catchError} from 'rxjs/operators';
import * as fromRootNewsletters from '@app/newsletter-new/store/root-newsletter/';
import * as fromRouter from '@app/root-store/router/router.actions';

@Injectable()
export class RootNewsletterEffects {
  public initForm$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromRootNewsletters.checkCanEdit.request),


        switchMap(({endpointType, id}) =>
          this.newsletterService.checkCanEdit(endpointType, id).pipe(
            switchMap((result) => {
              console.log(result)
              if (result.data) {
                return [fromActions.checkCanEdit.success()]
              }
              const message = endpointType === 'CAMPAIGN' ? 'You do not have permissions to edit this campaign' : 'You do not have permissions to edit this recipients list ';
              return [fromRootNewsletters.checkCanEdit.noPermissions({message}),
                fromRouter.go({path: '/newsletter/newsletters', queryParams: {}})
              ]
            }), catchError(({message}) => {
              this.store$.dispatch(fromRouter.go({path: '/newsletter/newsletters', queryParams: {}}));
              return of(fromActions.checkCanEdit.failure({message}))
            })
          )
        )
      ),
    {dispatch: true}
  );

  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private newsletterService: NewsletterService
  ) {
  }
}

import {Injectable} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {ChannelBase, EMPTY_NEWS_FORM, NewsDetails, NewsForm, NewsRoles} from '@app/core/models/newsboard';
import {ChannelService} from '@app/newsboard/channel/channel.service';
import * as fromBackButton from '@app/root-store/ui/back-button';
import {ConfirmDialogComponent, ConfirmDialogData} from '@app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import {getPublishNewsDialog, getUnpublishNewsDialog} from '@app/shared/dialogs/confirm-dialog/confirm-dialog.models';
import {
  NewsPreviewDialogComponent,
  NewsPreviewDialogData
} from '@app/shared/dialogs/previews/news-preview-dialog/news-preview-dialog.component';
import {SelectDialogComponent, SelectDialogData} from '@app/shared/dialogs/select-dialog/select-dialog.component';
import {CustomValidators, validateTwoDates} from '@app/shared/form-controls/validators/validator.function';
import * as fromLanguages from '@core/core-store/languages-dict';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {RdsDialogService} from '@rds/angular-components';
import {catchError, filter, map, mergeMap, of, switchMap, withLatestFrom} from 'rxjs';
import {FormStepStatus} from '../../../../shared/form-status-badge/form-status-badge.component';
import {NewsService} from '../../news.service';
import {TemplatesService} from '../../templates.service';
import * as fromActions from './form.actions';
import * as fromReducer from './form.reducer';
import * as fromSelectors from './form.selectors';
import * as fromRelatedNews from '../../store/related-news'
import * as fromTableActions from '../news-table/news-table.actions'
@Injectable()
export class FormEffects {

  public defaultTimeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

  public initAddForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.initAddForm),
      filter(({id}) => !id),
      withLatestFrom(
        this.store$.pipe(select(fromSelectors.selectCreateFromTemplate))
      ),
      map(([{user, contentType}, template]): Partial<NewsForm> => {
        return ({
          ...EMPTY_NEWS_FORM,
          type: contentType,
          authors: [user],
          byline: user.name,
          contact: user.email,
          permissions: {
            editors: [],
            owners: [user],
          },
          timeZone: this.defaultTimeZone,
          publishDate: null,
          expiryDate: null,
          content: !!template ? [...template.content] : [...EMPTY_NEWS_FORM.content],
        })
      }),
      mergeMap((form) => [
        fromActions.setFormValue({form: {...form}}),
        fromActions.setInitialFormValue({form: {...form}}),
        fromActions.setFormChecked()
      ])
    ), {dispatch: true}
  );

  public initCreateFormFromTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.initCreateFormFromTemplate),
      switchMap(({templateId, user, contentType}) => this.templateService.get(templateId).pipe(
        mergeMap((template) => [
          fromActions.initCreateFormFromTemplateSuccess({template}),
          fromActions.initAddForm({user, contentType})
        ])
      ))
    ), {dispatch: true}
  );

  public initEditForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.initAddForm),
      filter(({id}) => !!id),
      map(({id}) => fromActions.getNewsDetailsRequest({id}))
    ), {dispatch: true}
  );

  public getNewsDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getNewsDetailsRequest),
      withLatestFrom(
        this.store$.pipe(select(fromSelectors.selectFillFormStartedAt))
      ),
      switchMap(([{id}, fillFormStartedAt]) => this.newsService.get(id).pipe(
        mergeMap(news => {
          const role: NewsRoles = news.isOwner ? 'Owner' : (news.isEditor ? 'Editor' : null);
          const general: FormGroup = new FormGroup({
            id: new FormControl(news.id),
            type: new FormControl(news.contentType),
            authors: new FormControl(news.authors),
            byline: new FormControl(news.byline, [Validators.required]),
            contact: new FormControl(news.contact, [Validators.required]),
            language: new FormControl(news.language),
            commentsEnabled: new FormControl(news.commentsEnabled),
            commentsHidden: new FormControl(news.commentsHidden)
          });
          general.updateValueAndValidity();

          const languageVersions: FormArray = new FormArray([]);
          const primaryLanguage = news.content.find(c => c.language === news.language);
          news.content.forEach(c => {
            const syncImage = JSON.stringify(primaryLanguage.image) === JSON.stringify(c.image);
            languageVersions.push(new FormGroup({
              language: new FormControl(c.language, [Validators.required]),
              link: new FormControl(c.link, news.contentType === 'Link' ? [Validators.required] : []),
              title: new FormControl(c.title, [Validators.required]),
              abstract: new FormControl(c.abstract, [Validators.required]),
              image: new FormControl(c.image),
              html: new FormControl(c.html, news.contentType === 'Embedded' ? [Validators.required] : []),
              syncImage: new FormControl(syncImage),
            }))
          })

          const content: FormGroup = new FormGroup({
            content: languageVersions,
            websiteLink: new FormGroup({
              url: new FormControl(news.websiteLinkUrl),
              name: new FormControl(news.websiteLinkName)
            }),
            topics: new FormControl(news.topics, [CustomValidators.notEmptyList]),
            related: new FormControl(news.relatedNews)
          });
          content.updateValueAndValidity();

          const publishing: FormGroup = new FormGroup({
            assignement: new FormGroup({
              assign: new FormControl(news.assignedChannels),
              suggest: new FormControl(news.suggestedChannels),
            }, CustomValidators.validateAtLeastOneRequired([
              'assign', 'suggest',
            ])),
            timeZone: new FormControl(news.publishTimeZone),
            publishDate: new FormControl(news.publishDate, [Validators.required]),
            publishTime: new FormControl(!!news.publishDate ? new Date(news.publishDate).toLocaleTimeString('en-GB', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: false
            }) : null, [Validators.required]),
            expiryDate: new FormControl(news.expiryDate, [Validators.required]),
            expiryTime: new FormControl(!!news.expiryDate ? new Date(news.expiryDate).toLocaleTimeString('en-GB', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: false
            }) : null, [Validators.required])
          }, [validateTwoDates({
            startDate: 'publishDate',
            startTime: 'publishTime',
            endDate: 'expiryDate',
            endTime: 'expiryTime',
            timeZone: 'timeZone'
          }, fillFormStartedAt, !!news.publishDate ? news.publishDate : new Date(new Date().setHours(0, 0)))]);
          publishing.updateValueAndValidity();

          const permissions: FormGroup = new FormGroup({
            permissions: new FormControl({
              owners: news.owners,
              editors: news.editors
            }, CustomValidators.validateNewsPermissions),
          });
          permissions.updateValueAndValidity();

          return [
            fromActions.setGeneralFormStatus({status: general.status}),
            fromActions.setGeneralStepStatus({status: general.status === 'VALID' ? FormStepStatus.COMPLETED : FormStepStatus.INCOMPLETE}),
            fromActions.setContentFormStatus({status: content.status}),
            fromActions.setContentStepStatus({status: content.status === 'VALID' ? FormStepStatus.COMPLETED : FormStepStatus.INCOMPLETE}),
            fromActions.setPublishingFormStatus({status: publishing.status}),
            fromActions.setPublishingStepStatus({status: publishing.status === 'VALID' ? FormStepStatus.COMPLETED : FormStepStatus.INCOMPLETE}),
            fromActions.setPermissionsFormStatus({status: permissions.status}),
            fromActions.setPermissionsStepStatus({status: permissions.status === 'VALID' ? FormStepStatus.COMPLETED : FormStepStatus.INCOMPLETE}),
            fromActions.getNewsDetailsSuccess({
              news: {
                ...general.value,
                ...content.value,
                ...publishing.value,
                ...permissions.value
                // publishTime: news.publishDate ? this.getTime(new Date(news.publishDate)) : null,
                // expiryTime: news.expiryDate ? this.getTime(new Date(news.expiryDate)) : null,
              }, status: news.status, role
            }),
            fromActions.setFormChecked()
          ]
        }),
        catchError(({message}) => of(fromActions.getNewsDetailsFailure({error: message})))
      )),
    ), {dispatch: true}
  );

  public getNewsDetailsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getNewsDetailsSuccess),
      mergeMap(({news}) => [
        fromActions.setFormValue({form: news}),
      ]),
    ), {dispatch: true}
  );

  public loadAssignements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadAssignements),
      mergeMap(({id}) => [
        fromActions.loadAssignableRequest({id}),
        fromActions.loadSuggestableRequest({id}),
      ])
    )
  );

  public loadAssignableRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadAssignableRequest),
      switchMap(({id}) => this.channelService.listAssignable(id).pipe(
        map(channels => fromActions.loadAssignableSuccess({channels}))
      ))
    )
  );

  public loadSuggestableRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadSuggestableRequest),
      switchMap(({id}) => this.channelService.listNonAssignable(id).pipe(
        map(channels => fromActions.loadSuggestableSuccess({channels}))
      ))
    )
  );

  public saveAsDraft$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.saveAsDraftRequest),
      withLatestFrom(this.store$.pipe(select(fromSelectors.selectCreateFormForRequest(true)))),
      switchMap(([action, form]) => this.newsService.create(form).pipe(
        map(form => fromActions.saveAsDraftSuccess()),
        catchError(({message}) => of(fromActions.saveAsDraftFailure({error: message})))
      )),
    ), {dispatch: true}
  );

  public updateAsDraft$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateAsDraftRequest),
      withLatestFrom(this.store$.pipe(select(fromSelectors.selectUpdateFormForRequest(true)))),
      switchMap(([{id}, form]) => this.newsService.edit(id, form).pipe(
        map(form => fromActions.updateAsDraftSuccess()),
        catchError(({message}) => of(fromActions.updateAsDraftFailure({error: message})))
      )),
    ), {dispatch: true}
  );


  public saveAsPublish$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.saveAsPublishRequest),
      withLatestFrom(this.store$.pipe(select(fromSelectors.selectCreateFormForRequest(false)))),
      switchMap(([action, form]) => this.newsService.create(form).pipe(
        map(form => fromActions.saveAsPublishSuccess()),
        catchError(({message}) => of(fromActions.saveAsPublishFailure({error: message})))
      )),
    ), {dispatch: true}
  );


  public openPublishChangesConfirmDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.openPublishChangesConfirmDialog),
      map(({id}) => {
        const data: ConfirmDialogData = getPublishNewsDialog([id]);

        const dialog = this.dialogService.open(ConfirmDialogComponent, {
          data
        });
        return ({dialog, id})
      }),
      switchMap(({dialog, id}) => dialog.afterClosed().pipe(
        filter(data => !!data),
        map(() => fromActions.updateAsPublishRequest({id}))
      )),
    ), {dispatch: true});

  public updateAsPublish$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateAsPublishRequest),
      withLatestFrom(this.store$.pipe(select(fromSelectors.selectUpdateFormForRequest(false)))),
      switchMap(([{id}, form]) => this.newsService.edit(id, form).pipe(
        map(form => fromActions.updateAsPublishSuccess()),
        catchError(({message}) => of(fromActions.updateAsPublishFailure({error: message})))
      )),
    ), {dispatch: true}
  );

  public saveSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.saveAsDraftSuccess,
        fromActions.saveAsPublishSuccess,
        fromActions.updateAsDraftSuccess,
        fromActions.updateAsPublishSuccess,
        fromActions.unpublishSuccess
      ),
      mergeMap(() => [
        fromActions.closeAllDialogs(),
        fromActions.setFormTouched({touched: false}),
        fromTableActions.clearAll(),
        fromBackButton.back({defaultLabel: 'My News', defaultRoute: 'news/my'})
      ]),
    ), {dispatch: true}
  );

  public closeAllDialogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.closeAllDialogs),
      map(() => {
        this.dialogService.closeAll()
      }),
      switchMap(() => [fromActions.clearAll(), fromRelatedNews.clearAll()]),
    ), {dispatch: true}
  );


  public primaryLanguageChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.primaryLanguageChanged),
      withLatestFrom(this.store$.pipe(select(fromSelectors.selectPrimaryLanguage))),
      map(([{lang}, oldLang]) => fromActions.updatePrimaryLanguage({oldLang, newLang: lang}))
    ), {dispatch: true}
  );

  public openAddLanguageDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.openAddLanguageDialog),
      withLatestFrom(
        this.store$.pipe(select(fromLanguages.selectAll)),
        this.store$.pipe(select(fromSelectors.selectLanguages))
      ),
      map(([action, languages, currentLanguages]) => {
        const data: SelectDialogData = {
          title: 'Specify language version you want to create',
          selects: [
            {
              selectType: 'select',
              prop: 'language',
              options: languages.filter(l => !(currentLanguages as Array<string>).includes(l.code)),
              multiple: false,
              required: true,
              entityType: 'language'
            },
          ],
          confirmButtonLabel: 'Add language version'
        }
        const dialog = this.dialogService.open(SelectDialogComponent, {
          size: 'l',
          data
        });
        return ({dialog})
      }),
      switchMap(({dialog}) => dialog.afterClosed().pipe(
        filter(data => !!data),
        map((data: SelectDialogData) => {
          return ({language: data.selects.find(s => s.prop === 'language').value})
        })
      )),
      map(({language}) => fromActions.addLanguageVersion({language}))
    ), {dispatch: true}
  );

  public openLeavePageDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.openLeavePageDialog),
      withLatestFrom(
        this.store$.pipe(select(fromSelectors.selectChangesMade))
      ),
      filter(([{url}, changesMade]) => !!changesMade),
      map(([{url}, changesMade]) => {
        const data: ConfirmDialogData = {
          ids: [],
          messages: [],
          title: 'Do you want to discard changes?',
          confirmButtonLabel: 'Yes, discard',
          confirmButtonType: 'primary'
        }
        const dialog = this.dialogService.open(ConfirmDialogComponent, {
          data
        });
        return ({dialog, url})
      }),
      switchMap(({dialog, url}) => dialog.afterClosed().pipe(
        filter(data => !!data),
        map((data: SelectDialogData) => {
          return ({url})
        })
      )),
      map(({url}) => fromBackButton.back({defaultLabel: 'My News', defaultRoute: 'news/my'}))
    ), {dispatch: true})

  public closeWithoutDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.openLeavePageDialog),
      withLatestFrom(
        this.store$.pipe(select(fromSelectors.selectChangesMade))
      ),
      filter(([{url}, changesMade]) => !changesMade),
      map(([{url}]) => fromBackButton.back({defaultLabel: 'My News', defaultRoute: 'news/my'}))
    ), {dispatch: true})

  public openPreviewDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.openPreviewDialog),
      withLatestFrom(this.store$.pipe(select(fromSelectors.selectForm)),
        this.store$.pipe(select(fromSelectors.selectAssignableChannels)),
        this.store$.pipe(select(fromSelectors.selectSuggestableChannels)),
        this.store$.pipe(select(fromSelectors.canSaveAsDraft)),
      ),
      map(([action, form, assignable, suggestable, canSaveAsDraft]) => {
        const channel = form.assignement.assign.length > 0 ? assignable.find(c => c.id === form.assignement.assign[0]) : null
        const news: NewsDetails = {
          assignedChannels: form.assignement.assign,
          byline: form.byline,
          contact: form.contact,
          content: form.content,
          contentType: form.type,
          editors: form.permissions?.editors,
          id: -1,
          isEditor: false,
          isOwner: true,
          language: form.language,
          owners: form.permissions?.owners,
          relatedNews: [],
          status: 'Draft',
          suggestedChannels: form.assignement.suggest,
          authors: form.authors,
          commentsEnabled: true,
          expiryDate: form.expiryDate,
          expiryTimeZone: form.timeZone,
          publishDate: form.publishDate,
          publishTimeZone: form.timeZone,
          topics: form.topics,
          websiteLinkName: form.websiteLink.name,
          websiteLinkUrl: form.websiteLink.url,
        }
        const data: NewsPreviewDialogData = {
          news,
          channel: channel as ChannelBase,
          channelsDict: [
            ...assignable,
            ...suggestable
          ],
          context: 'NewsEditor',
          buttons: {
            canSaveNews: {
              disabled: !canSaveAsDraft,
              visible: true
            }
          }
        }
        const dialog = this.dialogService.open(NewsPreviewDialogComponent, {
            size: 'xl',
            height: '100%',
            maxHeight: '100%',
            data
          }
        )
        return ({dialog, form})
      }),
      switchMap(({dialog, form}) => dialog.afterClosed().pipe(
        filter(data => !!data),
        map((data: SelectDialogData) => {
          return ({id: form.id})
        })
      )),
      map(({id}) => id ? fromActions.updateAsDraftRequest({id}) : fromActions.saveAsDraftRequest())
    ), {dispatch: true});


  public openUnpublishConfirmDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.openUnpublishConfirmDialog),
      map(({id}) => {
        const data: ConfirmDialogData = getUnpublishNewsDialog([id]);

        const dialog = this.dialogService.open(ConfirmDialogComponent, {
          data
        });
        return ({dialog, id})
      }),
      switchMap(({dialog, id}) => dialog.afterClosed().pipe(
        filter(data => !!data),
        map(() => fromActions.unpublishRequest({id}))
      )),
    ), {dispatch: true});

  public unpublishRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.unpublishRequest),
      switchMap(({id}) => this.newsService.unarchive(id, true).pipe(
        map(() => ({id}))
      )),
      map(({id}) => fromActions.unpublishSuccess({id})),
      catchError((error) => of(fromActions.unpublishFailure({error})))
    )
  );

//   public unpublishSuccess$ = createEffect(() =>
//   this.actions$.pipe(
//     ofType(fromActions.unpublishSuccess),
//     map(({id}) => fromActions.updateAsDraftRequest({id}))
//   )
// );
  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private newsService: NewsService,
    private channelService: ChannelService,
    private dialogService: RdsDialogService,
    private templateService: TemplatesService,
  ) {
  }

  getTime(date: Date) {
    return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
  }

}

import { ActionReducerMap, ActionReducer, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { environment } from '@env/environment';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import * as fromUi from './ui/ui.reducer';
import * as fromRouter from './router/router.serializer';
import * as fromSuggestions from './suggestions/suggestions.reducer';

export interface State {
  router: RouterReducerState<fromRouter.RouterStateUrl>;
  suggestions: fromSuggestions.State;
  ui: fromUi.State;
}

export const ROOT_REDUCER: ActionReducerMap<State> = {
  router: routerReducer,
  suggestions: fromSuggestions.reducer,
  ui: fromUi.reducer,
};

export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state, action) => {
    return reducer(state, action);
  };
}

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [
      'user',
      {
        ui: [
          {backButton: ['label', 'routeBack', 'routeBackQueryParams', 'routeCurrent', 'addNewsMiddleRoutes']},
          {siteMap: ['routes']}],
      },
      {
       userSettings: [
        { campaignSubscriptions: [ 'data', 'blockedList', 'hiddenList' ] }
       ] 
      }
    ], rehydrate: true

    
  })(reducer);
}

export const metaReducers: MetaReducer<State>[] = !environment.prod
  ? [logger, localStorageSyncReducer]
  : [localStorageSyncReducer];

export const getRouter = (state: State) => state.router;
export const getUi = (state: State) => state.ui;
export const getSuggestions = (state: State) => state.suggestions;

import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CONTAINERS} from './containers';
import {UserSettingsRoutingModule} from './user-settings-routing.module';
import {SharedModule} from '@app/shared/shared.module';
import {StoreModule} from '@ngrx/store';
import {feature, reducer} from './store/user-settings.reducer';
import {EffectsModule} from '@ngrx/effects';
import {GlobalSettingsEffects} from '@app/user-settings/store/global-settings';
import {SearchingPreferencesEffects} from '@app/user-settings/store/searching-preferences';
import {LayoutModule} from '@app/layout/layout.module';
import {NotificationSettingsEffects} from './store/notification-settings';
import {CampaignSubscriptionsEffects} from '@app/user-settings/store/campaign-subscriptions';
import { CopyLinkDialogComponent } from './store/campaign-subscriptions/copy-link-dialog/copy-link-dialog.component';
import { IsSubscriptionProcessedPipe } from './containers/subscriptions/is-subscription-processed.pipe';
import { HiddenSubscriptionsDialogComponent } from './containers/subscriptions/hidden-subscriptions-dialog/hidden-subscriptions-dialog.component';

const EFFECTS = [
  GlobalSettingsEffects,
  SearchingPreferencesEffects,
  NotificationSettingsEffects,
  CampaignSubscriptionsEffects
];

@NgModule({
  declarations: [
    ...CONTAINERS,
    CopyLinkDialogComponent,
    IsSubscriptionProcessedPipe,
    HiddenSubscriptionsDialogComponent,
  ],
  providers: [],
  imports: [
    CommonModule,
    UserSettingsRoutingModule,
    SharedModule,
    LayoutModule,
    StoreModule.forFeature(feature, reducer),
    EffectsModule.forFeature(EFFECTS),
  ]
})
export class UserSettingsModule {
  static forRoot(): ModuleWithProviders<UserSettingsModule> {
    return {
      ngModule: UserSettingsModule,
      providers: []
    };
  }
}

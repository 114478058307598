const api = 'https://api-dev-at.roche.com';
const apiKey = ['A', 'I', 'z', 'a', 'S', 'y', 'D', 'w', 'T', 's', 'i', 'w', '7', 'u', 'F', 'l', 'l', 'G', 'Y', 'O', 'R',
  'p', 'd', 'Z', '6', '7', '9', 'N', '3', '2', 'R', 'E', '6', 'd', 'j', 'C', 'B', 'Y', 'I'].join('');
export const environment = {
  prod: false,
  dev: true,
  test: false,
  version: {},
  url: 'https://dev-at.roche.com',
  gateway: {
    AUTHENTICATION: `${api}/auth/api/`,
    CHANNEL: `${api}/content/api/`,
    CONTENT: `${api}/content/api/`,
    COMMENT: `${api}/content/api/`,
    SEARCH: `${api}/search/api/`,
    CATEGORY: `${api}/directory/api/`,
    CATALOG: `${api}/directory/api/`,
    CATALOG_PERSONALIZATION: `${api}/directory/api/`,
    QUICKLINKS: `${api}/directory/api/`,
    USER_PROFILE: `${api}/userprofile/api/`,
    ODATA: `${api}/`,
    DIRECTORY: `${api}/directory/api/`,
    TAXONOMY: `${api}/taxonomy/api/`,
    WORKDAY: `${api}/workday/api/`,
    WORKDAY_IMPORTER: `${api}/workdayimporter/api/`,
    NEWSLETTER: `${api}/newsletter/api/`
  },
  newsletterSenderDomains: [
    'rw2.rhtest1@roche.com',
    '@roche.com',
    '@contractors.roche.com',
  ],
  googleClientId: '1068501526172-74k9ui8fl3tvkrnu39tqudp0edosifu1.apps.googleusercontent.com',
  websocketUrl: 'wss://any28g9cmg.execute-api.eu-central-1.amazonaws.com/devel',
  firebaseConfig: {
    apiKey,
    authDomain: 'rochehomeprod.firebaseapp.com',
    databaseURL: 'https://rochehomeprod.firebaseio.com',
    projectId: 'rochehomeprod',
    storageBucket: 'rochehomeprod.appspot.com',
    messagingSenderId: '1068501526172',
    appId: '1:1068501526172:web:490e702a83b649fccaef59',
    measurementId: 'G-VZTHKD2CM7'
  },
  apiBase: 'https://dev-api-news.roche.com/api/v1',
  statsLink: 'https://lookerstudio.google.com/u/0/reporting/f4acab34-9f70-43e8-9d4c-65fc0adf6333/page/p_ehr7sdwxed',
};

import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import { CampaignSubscribeStatus } from './newsletter-preview.reducer';
import { openShareDialog } from '@app/newsletter/store/newsletter-saved-templates';

export const subscription = createActionGroup({
	source: 'NEWSLETTER2 PREVIEW - SUBSCRIPTION',
	events: {
		openConfirmDialog: props<{campaign: any, isSubscribed: boolean}>(),
		openCopyLinkDialog: props<{ id: number, name: string, newsletterId: number}>(),
    	openAcceptShareDialog: props<{ id: number, name: string, user?: string}>(),
		cannotSubscribe: props<{campaign: any}>(),
		cannotUnsubscribe: props<{campaign: any}>(),
		isSubscribedRequest: props<{campaignId: number}>(),
		isSubscribedSuccess: props<{isSubscribed: CampaignSubscribeStatus}>(),
		isSubscribedFailure: props<{error: any}>(),
		subscribeRequest: props<{campaignId: number}>(),
		subscribeSuccess: emptyProps(),
		subscribeFailure: props<{error: any}>(),
		unsubscribeRequest: props<{campaignId: number}>(),
		unsubscribeSuccess: emptyProps(),
		unsubscribeFailure: props<{error: any}>(),
	},
});

export const clear = createAction( 'NEWSLETTER2 PREVIEW - CLEAR' );

import {createActionGroup, emptyProps, props} from '@ngrx/store';
import {CampaignListModel} from '@app/user-settings/store/campaign-subscriptions/campaign-subscriptions.reducer';


export const list = createActionGroup({
    source: 'SUBSCRIPTIONS LIST',
    events: {
      request: emptyProps(),
      success: props<{ data: CampaignListModel[] }>(),
      failure:   props<{ error: any }>(),
    },
});

export const subscribe = createActionGroup({
  source: 'SUBSCRIPTIONS LIST SUBSCRIBE',
  events: {
    request: props<{ id: number, withRefresh?: boolean }>(),
    success: props<{ id: number }>(),
    failure:   props<{ id: number, error: any }>(),
  },
});

export const unsubscribe = createActionGroup({
  source: 'SUBSCRIPTIONS LIST UNSUBSCRIBE',
  events: {
    request: props<{ id: number }>(),
    success: props<{ id: number }>(),
    failure:   props<{ id: number, error: any }>(),
  },
});

export const share = createActionGroup({
  source: 'SUBSCRIPTIONS LIST SHARE',
  events: {
    openCopyLinkDialog: props<{ id: number, name: string}>(),
    openAcceptShareDialog: props<{ id: number, name: string, user?: string}>(),
  },
});

export const visibility = createActionGroup({
  source: 'SUBSCRIPTIONS LIST VISIBILITY',
  events: {
    hide: props<{ id: number}>(),
    unhide: props<{ id: number}>(),
    openHiddenSubscriptionModal: emptyProps(),
    unhideAll: emptyProps(),
    hideAll: emptyProps(),
  },
});
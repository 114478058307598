<div class="row py-7">
  <div class="col-8 offset-2 py-4">
    <div class="row">
      <div class="col">
        <h4 class="ui-heading-4 mb-5">Newsletter Campaigns</h4>
      </div>
    </div>
  </div>
  <div class="col-8 offset-2 bg-white py-10 py-s-4 px-11 px-s-5">
    <div class="row" >
      <div class="col">
        <p class="ui-label-s mb-5">
          You can manage campaigns you manually subscribed or unsubscribed
        </p>
      </div>
      <div class="col-12">
        <rds-divider class="mb-10"></rds-divider>
      </div>
    </div>
    <rds-accordion >
      <rds-expansion-panel expanded="true">
        <rds-expansion-panel-header>
          <rds-panel-title>
            Subscribed Campaigns
          </rds-panel-title>
        </rds-expansion-panel-header>
        <ng-container *ngIf="subscribed.length else noSubscribedCampaigns;">
          <div class="row">
            <div class="col-s-6 col-8 py-3">
            </div>
            <div class="col-s-3 col-2 py-3">
              <div class="row">
                <div class="col-auto offset-both">
                  <p class="ui-label-s-bold text-low-contrast text-nowrap">
                    Subscribe
                  </p>
                </div>
              </div>
            </div>
            <div class="col-s-3 col-2 py-3">
              <div class="row">
                <div class="col-auto offset-both">
                  <p class="ui-label-s-bold text-low-contrast text-nowrap">
                    Share
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3" *ngFor="let campaign of subscribed">
            <div class="col-s-6 col-8">
              <p class="ui-label-m-bold">
                {{campaign.campaignName}}
              </p>
            </div>
            <div class="col-s-3 col-2">
              <div class="row">
                <div class="col-auto offset-both">
                  <rds-switch [disabled]="(campaign | isSubscriptionProcessed | async) || (campaign.isUnsubscribed && !campaign.allowToSubscribe) || (!campaign.isUnsubscribed && !campaign.allowToUnsubscribe)" [checked]="!campaign.isUnsubscribed" (buttonChanged)="$event.checked ? subscribe(campaign.campaignId) : unsubscribe(campaign.campaignId)"></rds-switch>
                </div>
              </div>
            </div>
            <div class="col-s-3 col-2">
              <div class="row">
                <div class="col-auto offset-both">
                  <button rds-icon-button size="m" (click)="openCopyLinkDialog(campaign.campaignId, campaign.campaignName)">
                    <rds-icon icon="share" namespace="outlined"></rds-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </rds-expansion-panel>
      <rds-expansion-panel>
        <rds-expansion-panel-header>
          <rds-panel-title>
            Unsubscribed Campaigns
          </rds-panel-title>
        </rds-expansion-panel-header>
        <ng-container *ngIf="unsubscribed.length else noUnsubscribedCampaigns;">
          <div class="row">
            <div class="col-s-6 col-8 py-3">
            </div>
            <div class="col-s-3 col-2 py-3">
              <div class="row">
                <div class="col-auto offset-both">
                  <p class="ui-label-s-bold text-low-contrast text-nowrap">
                    Subscribe
                  </p>
                </div>
              </div>
            </div>
            <div class="col-s-3 col-2 py-3">
              <div class="row">
                <div class="col-auto offset-both">
                  <p class="ui-label-s-bold text-low-contrast text-nowrap">
                    Share
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3" *ngFor="let campaign of unsubscribed">
            <div class="col-s-6 col-8">
              <p class="ui-label-m-bold">
                {{campaign.campaignName}}
              </p>
            </div>
            <div class="col-s-3 col-2">
              <div class="row">
                <div class="col-auto offset-both">
                  <rds-switch [disabled]="(campaign | isSubscriptionProcessed | async) || (campaign.isUnsubscribed && !campaign.allowToSubscribe) || (!campaign.isUnsubscribed && !campaign.allowToUnsubscribe)" [checked]="!campaign.isUnsubscribed" (buttonChanged)="$event.checked ? subscribe(campaign.campaignId) : unsubscribe(campaign.campaignId)"></rds-switch>
                </div>
              </div>
            </div>
            <div class="col-s-3 col-2">
              <div class="row">
                <div class="col-auto offset-both">
                  <button rds-icon-button size="m" (click)="openCopyLinkDialog(campaign.campaignId, campaign.campaignName)">
                    <rds-icon icon="share" namespace="outlined"></rds-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </rds-expansion-panel>
    </rds-accordion>
      <ng-template #noSubscribedCampaigns>
        <div class="row">
          <div class="col">
            <rds-empty-state alignment="center" size="s">
              <img rds-empty-state-image src="assets/images/empty-state.png" alt="No data" />
              <rds-empty-state-title>
                You have no subscribed campaigns
              </rds-empty-state-title>
            </rds-empty-state>
          </div>
        </div>
      </ng-template>
      <ng-template #noUnsubscribedCampaigns>
        <div class="row">
          <div class="col">
            <rds-empty-state alignment="center" size="s">
              <img rds-empty-state-image src="assets/images/empty-state.png" alt="No data" />
              <rds-empty-state-title>
                You have no unsubscribed campaigns
              </rds-empty-state-title>
            </rds-empty-state>
          </div>
        </div>
      </ng-template>
  </div>
</div>

import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Host,
	Input,
	OnInit,
	Optional,
	Output,
	SkipSelf,
	ViewChild,
} from '@angular/core';
import { ControlContainer, FormControl, FormGroup, NG_VALUE_ACCESSOR, ValidatorFn, Validators } from '@angular/forms';
import { SimpleUser } from '@app/core/models/newsboard';
import { CustomValidators } from '@app/shared/form-controls/validators/validator.function';
import { TableWithEditPerformanceComponent } from '@app/shared/table-with-edit-performance/table-with-edit-performance.component';
import { ChipsFilter } from '@app/shared/table-with-edit/table-with-edit.component';
import { RdsMenuTriggerDirective } from '@rds/angular-components';
import { BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs';

export interface Recipient {
	id?: string;
	email: string;
	name: string;
	surname: string;
	type: number;
	included?: boolean;
	blocked?: boolean;
}

export interface RecipientSug extends Recipient {
	selected: boolean;
	login: string;
}

@Component({
	selector: 'rh-recipients-picker-table',
	templateUrl: './recipients-picker-table.component.html',
	styleUrls: ['./recipients-picker-table.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: RecipientsPickerTableComponent,
		},
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecipientsPickerTableComponent implements OnInit {
	@ViewChild('trigger', { static: false }) trigger: RdsMenuTriggerDirective;
	@ViewChild('table', { static: false }) table: TableWithEditPerformanceComponent;

	@Input() displayedColumns = [];
	@Input() autocompleteColumn: string;
	@Input() groupColumn: string;
	@Input() validityColumn: 'included' | 'blocked';
	@Input() filters: ChipsFilter;

	@Input() editableColumns: { [key: string]: ValidatorFn | Array<ValidatorFn> } = {};

	rows: BehaviorSubject<Array<any>> = new BehaviorSubject([]);

	selectedRecipients: Array<Recipient> = [];

	onChange = (users) => {
		this.rows.next(users);
	};

	onTouched = () => {};

	touched = false;

	disabled = false;

	hostWidth: number;

	searchUserToRecipient = (user: SimpleUser, validityKey: 'included' | 'blocked'): Recipient => ({
		name: user.firstName,
		surname: user.lastName,
		email: user.email,
		type: 0,
		[validityKey]: true,
	});

	writeValue(value: Array<Recipient>) {
		if (this.selectedRecipients !== value) {
			this.selectedRecipients = [...value];
			this.form.controls.search.updateValueAndValidity();
			this.rows.next(this.selectedRecipients);
		}
	}

	registerOnChange(onChange: any) {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: any) {
		this.onTouched = onTouched;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	setDisabledState(disabled: boolean) {
		this.disabled = disabled;
	}

	@Output() search: EventEmitter<string> = new EventEmitter<string>();
	@Output() selected: EventEmitter<string> = new EventEmitter<string>();
	@Output() removed: EventEmitter<string> = new EventEmitter<string>();

	_autocomplete: { suggestions: Array<RecipientSug>; loading: boolean };
	get autocomplete(): { suggestions: Array<RecipientSug>; loading: boolean } {
		return this._autocomplete;
	}

	@Input() set autocomplete(value: { suggestions: Array<SimpleUser>; loading: boolean }) {
		this._autocomplete = {
			suggestions: value.suggestions.map((sug) => ({
				...this.searchUserToRecipient(sug, this.validityColumn),
				selected: this.selectedIncludesEmail(sug.email),
				login: sug.identifier,
        photoUrl: sug.photoUrl
			})),
			loading: value.loading,
		};
		if (!!this.trigger) {
			this._autocomplete.suggestions.length > 0 && !this._autocomplete.loading
				? this.trigger.openMenu()
				: this.trigger.closeMenu();
		}
	}

	@Input() formControl!: FormControl;
	@Input() formControlName!: string;

	get control() {
		return this.formControl || this.controlContainer.control?.get(this.formControlName);
	}

	get required() {
		return this.control.hasValidator(Validators.required);
	}

	form: FormGroup = new FormGroup({
		search: new FormControl(''),
	});

	@Input() labelFn: (args: any) => string;

	focus() {
		if (this.autocomplete.suggestions.length > 0 && !this.autocomplete.loading) {
			this.trigger.openMenu();
		}
	}

	blur() {
		this.form.controls.search.updateValueAndValidity();
	}

	selectedIncludesEmail(email: string) {
		return this.selectedRecipients.findIndex((e) => e.email === email) > -1;
	}

	scrollTo(user: SimpleUser) {
		const id = this.selectedRecipients.find((e) => e.email === user.email).id;
		this.table.scrollToId(id);
	}

	select(user: SimpleUser) {
		this.selectedRecipients = [...this.selectedRecipients, this.searchUserToRecipient(user, this.validityColumn)];
		this.form.controls.search.setValue('');
		this.selected.emit();
		this.onChange(this.selectedRecipients.slice());
	}

	remove(ids: Array<{ id: string }>) {
		this.selectedRecipients = this.selectedRecipients.filter((u) => !ids.map((row) => row.id).includes(u.id));
		this.form.controls.search.setValue('');
		this.removed.emit();
		this.table.clearAll();
		this.onChange(this.selectedRecipients.slice());
	}

	editRow(rows: { [id: string]: any }) {
		this.selectedRecipients = this.selectedRecipients.map((u) => (rows[u.id] ? { ...u, ...rows[u.id] } : { ...u }));
		this.onChange(this.selectedRecipients.slice());
	}

	updateValidation(validity: { [id: string]: { valid: boolean } }) {
		this.selectedRecipients = this.selectedRecipients.map((u) =>
			validity[u.id] ? { ...u, [this.validityColumn]: validity[u.id].valid } : { ...u }
		);
		this.onChange(this.selectedRecipients.slice());
	}

	userAdded(user) {
		this.selectedRecipients = [...this.selectedRecipients, user];
		this.selected.emit();
		this.onChange(this.selectedRecipients.slice());
	}

	ngOnInit(): void {
		this.form.controls.search.setValidators(CustomValidators.isMasterControlValid(this.control));
		this.form.controls.search.updateValueAndValidity();

		const originalMarkAsTouched = this.control.markAsTouched;
		const that = this;
		this.control.markAsTouched = function () {
			originalMarkAsTouched.apply(this, arguments);
			that.form.controls.search.markAsTouched();
			that.form.controls.search.updateValueAndValidity();
		};

		this.control.statusChanges.pipe(debounceTime(100)).subscribe((status) => {
			this.form.controls.search.updateValueAndValidity();
		});
		this.form.controls.search.valueChanges.pipe(distinctUntilChanged()).subscribe((s) => this.search.emit(s));
	}

	constructor(
		@Optional()
		@Host()
		@SkipSelf()
		private controlContainer: ControlContainer
	) {}
}

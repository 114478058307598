import {createActionGroup, emptyProps, props} from '@ngrx/store';

export const checkCanEdit = createActionGroup({
	source: 'ROOT NEWSLETTER',
	events: {
		request: props<{ endpointType: 'RECIPIENT_LIST' | 'CAMPAIGN', id: number}>(),
		success: emptyProps(),
		failure: props<{ message: any }>(),
    noPermissions: props<{ message: any }>(),
	},
});

<h2 rds-dialog-header>Share</h2>
<div rds-dialog-content [formGroup]="form">
    <rds-form-field labelPosition="none">
        <input rdsInput formControlName="link" />
    </rds-form-field>
    <rds-checkbox class="mb-5" formControlName="sendAsMe"> Include your name in share message </rds-checkbox>
    <p class="ui-label-m-bold">
        Message preview:
    </p>
    <p class="ui-label-m">
        <span *ngIf="form.get('sendAsMe').value === false">
            Would you like to subscribe to <strong>{{data.name}}</strong> campaign?
        </span>
        <span *ngIf="form.get('sendAsMe').value === true">
            <strong>{{data.user}}</strong> invites you to the <strong>{{data.name}}</strong> campaign. Would you like to subscribe? 
        </span>
    </p>
</div>
<div rds-dialog-actions-container align="end">
  <button rds-secondary-button [rds-dialog-close]="false">Cancel</button>
  <button rds-primary-button [rds-dialog-close]="true" (click)="copyLink()">Copy link</button>
</div>
import { Injectable } from '@angular/core';
import { ApiService, HttpError } from './api/api.service';
import { EndpointsService } from './endpoints/endpoints.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserSearch } from '../models/user-search.model';
import { UserWorkdayInformation, UserWorkdayOrganisation} from '../models/user-workday.model';
import { PagedResults, Result } from '@core/models';
import { SimpleUser } from '../models/newsboard';

@Injectable()
export class WorkdayService {

  public getUserSearch({controlValue}): Observable<Array<UserSearch>> {
    const mainUrl = this.endpoints.ENDPOINT.WORKDAY.USER.MY_SEARCH;
    const queryParamsUrl = this.endpoints.addParams(mainUrl, {query: controlValue});

    return this.http
      .get(queryParamsUrl)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get catalogs. ${error.message}`))
      );
  }

  public getUserSearchByLogin(login: string): Observable<SimpleUser> {
    if (!login) {
      return of(null);
    }
    const mainUrl = this.endpoints.ENDPOINT.WORKDAY.USER.BY_LOGIN.GET;
    const urlWithToken = this.endpoints.replaceUrlTokens(mainUrl, { login });

    return this.http
      .get(urlWithToken)
      .pipe(
        map((response: UserWorkdayInformation): SimpleUser  => ({
          email: response.email,
          name: `${response.firstName} ${response.lastName}`,
          identifier: response.login,
          avatar: response.photoUrl
        })),
        catchError((error: HttpError) => throwError(`Cannot get user. ${error.message}`))
      );
  }

  public getUserByLogin(login: string): Observable<UserWorkdayInformation> {
    const mainUrl = this.endpoints.ENDPOINT.WORKDAY.USER.BY_LOGIN.GET;
    const urlWithToken = this.endpoints.replaceUrlTokens(mainUrl, { login });

    return this.http
      .get(urlWithToken)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get user. ${error.message}`))
      );
  }

  public getUserOrganisationByLogin(login: string): Observable<UserWorkdayOrganisation> {
    const mainUrl = this.endpoints.ENDPOINT.WORKDAY.USER.ORGANISATION_BY_LOGIN.GET;
    const urlWithToken = this.endpoints.replaceUrlTokens(mainUrl, { login });

    return this.http
      .get(urlWithToken)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get user. ${error.message}`))
      );
  }

  getPeopleResults({search, filters, pageSize, pageIndex }): Observable<PagedResults> {

    const mainUrl = this.endpoints.ENDPOINT.WORKDAY.USER.FILTERED_SEARCH;

    const filtersValue = {
      departments: filters.departments,
      functions: filters.functions,
      locations: filters.locations,
      personalized: true
    };

    const queryParamsUrl = this.endpoints.addParams(mainUrl, {
      phrase: encodeURIComponent(search),
      pageIndex,
      pageSize,
    });

    return this.http
      .put(queryParamsUrl, filtersValue)
      .pipe(
        map(({results, hasMoreResults, allResultsCount}) =>
          this.addPaginationToResponse({results, hasMoreResults, allResultsCount, pageIndex, pageSize})
        ),
        catchError((error: HttpError) => throwError(`Cannot get user results. ${error.message}`))
      );
  }

  private addPaginationToResponse({results, allResultsCount, hasMoreResults, pageIndex, pageSize}): PagedResults {
    return {
      results: this.addIdToCollection(results.results),
      count: allResultsCount,
      pageIndex,
      pageSize,
      isFirst: pageIndex === 1,
      isLast: !hasMoreResults,
      pageCount: Math.ceil(allResultsCount / pageSize),
    };
  }

  private addIdToCollection(collection: Result[]): Result[] {
    return collection.map((item, index) => ({
      ...item,
      id: item.login,
    }));
  }

  getByEmails(emails: Array<string>) {
    const url = this.endpoints.ENDPOINT.WORKDAY.USER.GET_BY_EMAILS;
    return this.http.put(url, emails)
  }

  getByEmail(email: string) {
    const url = this.endpoints.ENDPOINT.WORKDAY.USER.BY_EMAIL.GET;
    return this.http.get(this.endpoints.addParams(url, {email}))
  }

  constructor(private http: ApiService, private endpoints: EndpointsService) { }
}

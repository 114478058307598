import { Component, Inject } from '@angular/core';
import { FormGroup, UntypedFormControl } from '@angular/forms';
import { RDS_DIALOG_DATA, RdsDialogRef, RdsToastIndividualConfig, RdsToastService } from '@rds/angular-components';

export interface CopyToClipboardDialogData {
  title: string;
  hint?: string;
  valueToCopy: string;
  confirmButtonLabel?: string;
}

@Component({
  selector: 'atr-copy-to-clipboard-dialog',
  templateUrl: './copy-to-clipboard-dialog.container.html',
  styleUrls: ['./copy-to-clipboard-dialog.container.scss']
})
export class CopyToClipboardDialogContainer {

  form: FormGroup = new FormGroup({
    valueToCopy: new UntypedFormControl('')
  });

  copyLink() {
    if (!navigator.clipboard) { 
      document.execCommand('copy');
    } else {
      navigator.clipboard.writeText(this.form.controls.valueToCopy.value);
    }
    const message = 'Link copied to clipboard';
    const title = 'Success';
    const options: Partial<RdsToastIndividualConfig> = {
      type: 'success',
      position: 'bottom-left'
    };
    this.toastService.show(message, title, options);
    this.dialog.close();
  }

  constructor(
    @Inject(RDS_DIALOG_DATA) public data: CopyToClipboardDialogData,
    private dialog: RdsDialogRef<CopyToClipboardDialogContainer>,
    private toastService: RdsToastService
  ) {
    if (!!this.data.valueToCopy) {
      this.form.controls.valueToCopy.patchValue(this.data.valueToCopy);
      this.form.controls.valueToCopy.disable();
    }
  }
}

import { Component, Input } from '@angular/core';
import { ImageBlockProperties } from '@app/newsletter-new/models/block-type.enum';
import { TagTypes } from '@app/shared/utils/get-ses-tags';

@Component({
	selector: 'rh-image-element',
	templateUrl: './image-element.component.html',
	styleUrls: ['./image-element.component.scss'],
})
export class ImageElementComponent {
	TagTypes = TagTypes;
	// @Input() blockProperties: ImageBlockProperties;

  _blockProperties: ImageBlockProperties;
  get blockProperties(): ImageBlockProperties {
    return this._blockProperties;
  }

  @Input() set blockProperties(value: ImageBlockProperties) {

    this._blockProperties = {...value,
    photoUrl: value.photoUrl ? value.photoUrl.split('?')[0] + '?r=' + Math.floor(Math.random()*100000) : location.origin + '/assets/images/news-placeholder.png'};

  }

  public Math = Math;
}

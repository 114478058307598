<rds-table
	#tableRef
	[dataSource]="isLoading ? loadingRows : data"
	[class.rds-table-loading-data]="isLoading"
	[rdsSortActive]="defaultSortActive"
	[rdsSortDirection]="defaultSortDirection"
	(rdsSortChange)="onSort($event)"
	rdsSort
	#sortRef
	density="spacious"
	size="l"
>
	<!-- Custom column -->
	<ng-container rdsColumnDef="custom">
		<rds-header-cell *rdsHeaderCellDef>
			<div class="table__custom-row">
				<ng-content select="[customRow]"></ng-content>
			</div>
		</rds-header-cell>
	</ng-container>
	<!-- Delete column -->
	<ng-container rdsColumnDef="delete-checkbox">
		<rds-header-cell *rdsHeaderCellDef>
			<div class="table__delete-row"></div>
		</rds-header-cell>
	</ng-container>
	<ng-container rdsColumnDef="delete">
		<rds-header-cell *rdsHeaderCellDef>
			<div class="table__delete-row">
				<ng-content select="[bulkText]"></ng-content>
				<ng-content select="[bulkActions]"></ng-content>
				<button rds-secondary-button (click)="clearAll()">Cancel</button>
			</div>
		</rds-header-cell>
	</ng-container>
	<!-- Checkbox column -->
	<ng-container rdsColumnDef="select">
		<rds-header-cell *rdsHeaderCellDef>
			<rds-checkbox
				(click)="$event.stopPropagation()"
				(change)="$event ? masterToggle() : null"
				[checked]="selection.hasValue() && isAllOnPageSelected"
				[indeterminate]="selection.hasValue() && isAnyOnPageSelected && !isAllOnPageSelected"
				[disabled]="disableMasterToggle"
			>
			</rds-checkbox>
		</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<rds-checkbox
				(click)="$event.stopPropagation()"
				(change)="$event ? selection.toggle(element) : null"
				[checked]="
					selection.isSelected(element) || (initialSelectionPredicate ? initialSelectionPredicate(element) : false)
				"
				[disabled]="
					!!bulkPredicate
						? !bulkPredicate(element)
						: !!initialSelectionPredicate
							? initialSelectionPredicate(element)
							: false
				"
			>
			</rds-checkbox>
		</rds-cell>
	</ng-container>

	<ng-container [rdsColumnDef]="loadingColumn" *ngFor="let loadingColumn of loadingColumns">
		<rds-cell *rdsCellDef="let element" [class.sortable]="isSortable(loadingColumn)"> &nbsp;</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="actions">
		<rds-header-cell *rdsHeaderCellDef></rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<ng-template
				[ngTemplateOutlet]="actionsTemplate"
				[ngTemplateOutletContext]="{ $implicit: element }"
			></ng-template>
		</rds-cell>
	</ng-container>

	<!-- CHANNELS COLUMN DEFINITIONS -->
	<ng-container rdsColumnDef="name">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header class="sortable">Channel name</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					link;
					context: {
						disabled: disableRowPredicate(element),
						tooltip: element.name,
						highlight: highlight,
						title: element.name,
						link: 'channels/' + element.id,
						linkType: 'route',
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="name-nolink">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="name" class="sortable">Channel name</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						tooltip: element.name,
						highlight: highlight,
						title: element.name,
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="role">
		<rds-header-cell *rdsHeaderCellDef>My role</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<ng-container
				*ngIf="{
					Owner: 'c-500-extra-dark-green',
					Manager: 'c-300-dark-red',
					Publisher: 'c-400-extra-dark-purple',
				} as colorClass"
			>
				<ng-container
					*ngTemplateOutlet="
						icon;
						context: {
							icon: 'user',
							namespace: 'outlined',
							colorClass: colorClass[element.role],
							tooltip: element.role,
						}
					"
				></ng-container>
			</ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="owners">
		<rds-header-cell *rdsHeaderCellDef>Owners</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<rh-user-group [users]="element.owners"></rh-user-group>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="managers">
		<rds-header-cell *rdsHeaderCellDef>Managers</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<rh-user-group [users]="element.managers"></rh-user-group>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="publishers">
		<rds-header-cell *rdsHeaderCellDef>Publishers</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<rh-user-group [users]="element.publishers"></rh-user-group>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="description">
		<rds-header-cell *rdsHeaderCellDef>Description</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						tooltip: element.description,
						highlight: highlight,
						title: element.description,
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="creationDate">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="creationDate" class="sortable">Created</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						title: (element.creationDate | date: 'shortDate'),
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<!-- NEWS COLUMN DEFINITIONS -->
	<ng-container rdsColumnDef="pin">
		<rds-header-cell *rdsHeaderCellDef></rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<button
				rds-icon-button
				size="m"
				[class.pinned]="element.isPinned"
				(click)="pinClicked.emit({ id: element.id, isPinned: element.isPinned })"
			>
				<rds-icon [namespace]="element.isPinned ? 'filled' : 'outlined'" icon="pinned"></rds-icon>
			</button>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="title">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="content.Title" class="sortable">News title</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					link;
					context: {
						disabled: disableRowPredicate(element),
						tooltip: element.title,
						highlight: highlight,
						title: element.title,
						link: element.id,
						linkType: 'route',
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="title.nosort">
		<rds-header-cell *rdsHeaderCellDef>News title</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<ng-container
				*ngTemplateOutlet="
					link;
					context: {
						disabled: disableRowPredicate(element),
						tooltip: element.title,
						highlight: highlight,
						title: element.title,
						link: element.id,
						linkType: 'route',
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="title-nolink">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="content.Title" class="sortable">News title</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						tooltip: element.title,
						highlight: highlight,
						title: element.title,
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="channel">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="channel.Name" class="sortable">Channel</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						tooltip: element.channel.name,
						title: element.channel.name,
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="assignedBy">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header class="sortable">Assigned by</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<rh-user-single [user]="element.assignedBy"></rh-user-single>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="suggestedBy">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="assignedBy" class="sortable">Suggested by</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<rh-user-single [user]="element.assignedBy"></rh-user-single>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="type">
		<rds-header-cell *rdsHeaderCellDef>Type</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<ng-container
				*ngIf="{
					Link: {
						icon: 'link',
						color: 'c-500-extra-dark-green',
						tooltip: 'Added as a link',
					},
					Embedded: {
						icon: 'document',
						color: 'c-400-extra-dark-purple',
						tooltip: 'Created in the editor',
					},
					Announcement: {
						icon: 'document',
						color: 'c-400-extra-dark-purple',
						tooltip: 'People announcement',
					},
				} as types"
			>
				<ng-container
					*ngTemplateOutlet="
						icon;
						context: {
							icon: types[element.type].icon,
							namespace: 'outlined',
							colorClass: types[element.type].color,
							tooltip: types[element.type].tooltip,
						}
					"
				></ng-container>
			</ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="published">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="publishDate" class="sortable">Published</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						tooltip:
							element.status === 'Scheduled'
								? (element.publishDate | date: '\'Scheduled to be published on \' M/d/yy \'at\' h:mm a')
								: (element.publishDate | date: '\'Published on \' M/d/yy \'at\' h:mm a'),
						tooltipAlwaysVisible: true,
						title: (element.publishDate | date: 'shortDate'),
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="published.nosort">
		<rds-header-cell *rdsHeaderCellDef>Published</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						tooltip:
							element.status === 'Scheduled'
								? (element.publishDate | date: '\'Scheduled to be published on \' M/d/yy \'at\' h:mm a')
								: (element.publishDate | date: '\'Published on \' M/d/yy \'at\' h:mm a'),
						tooltipAlwaysVisible: true,
						title: (element.publishDate | date: 'shortDate'),
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="approved">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="createdDate" class="sortable">Approved</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						tooltip: (element.createdDate | date: '\'Approved on \' M/d/yy \'at\' h:mm a'),
						tooltipAlwaysVisible: true,
						title: (element.createdDate | date: 'shortDate'),
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="created">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="createdDate" class="sortable">Created</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						tooltip: (element.createdDate | date: '\'Created on \' M/d/yy \'at\' h:mm a'),
						tooltipAlwaysVisible: true,
						title: (element.createdDate | date: 'shortDate'),
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="requested">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="suggestedDate" class="sortable">Requested</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						tooltip: (element.suggestedDate | date: '\'Requested on \' M/d/yy \'at\' h:mm a'),
						tooltipAlwaysVisible: true,
						title: (element.suggestedDate | date: 'shortDate'),
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="archived">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="modifiedDate" class="sortable">Archived</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						tooltip: (element.modifiedDate | date: '\'Archived on \' M/d/yy \'at\' h:mm a'),
						tooltipAlwaysVisible: true,
						title: (element.modifiedDate | date: 'shortDate'),
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="suggested">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="suggestedDate" class="sortable">Suggested</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						tooltip: (element.suggestedDate | date: '\'Archived on \' M/d/yy \'at\' h:mm a'),
						tooltipAlwaysVisible: true,
						title: (element.suggestedDate | date: 'shortDate'),
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="response">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="responseDate" class="sortable">Response</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						tooltip:
							(element.approvalStatus === 'Rejected' ? 'Rejected ' : 'Approved') +
							(element.responseDate | date: '\' on \' M/d/yy \'at\' h:mm a'),
						tooltipAlwaysVisible: true,
						title: (element.responseDate | date: 'shortDate'),
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="status">
		<rds-header-cell *rdsHeaderCellDef>Status</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<ng-container
				*ngIf="{
					Published: {
						label: 'Published',
						color: 'green',
					},
					Draft: {
						label: 'Draft',
						color: 'blue',
					},
					Scheduled: {
						label: 'Scheduled',
						color: 'neutral',
					},
					Archived: {
						label: 'Archived',
						color: 'yellow',
					},
				} as statuses"
			>
				<ng-container
					*ngTemplateOutlet="
						badge;
						context: {
							color: statuses[element.status].color,
							label: statuses[element.status].label,
						}
					"
				></ng-container>
			</ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="approvalStatus">
		<rds-header-cell *rdsHeaderCellDef>Status</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<ng-container
				*ngIf="{
					Pending: {
						label: 'Pending',
						color: 'neutral',
						tooltip:
							'Pending approval: ' +
							([element.channel.owners, element.channel.managers, element.channel.publishers] | userList: ' or '),
					},
					Approved: {
						label: 'Approved',
						color: 'green',
						tooltip: 'Approved by: ' + element.approvedBy?.name,
					},
					Rejected: {
						label: 'Rejected',
						color: 'red',
						tooltip: 'Rejected by: ' + element.rejectedBy?.name,
					},
					Deleted: {
						label: 'Deleted',
						color: 'black',
					},
				} as statuses"
			>
				<ng-container
					*ngTemplateOutlet="
						badge;
						context: {
							color: statuses[element.approvalStatus].color,
							label: statuses[element.approvalStatus].label,
							tooltip: statuses[element.approvalStatus].tooltip,
						}
					"
				></ng-container>
			</ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="reject">
		<rds-header-cell *rdsHeaderCellDef>Action</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<button
				rds-text-button
				[disabled]="disableRowPredicate(element)"
				size="m"
				(click)="rejectClicked.emit({ id: element.id, title: element.title })"
			>
				Reject
			</button>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="accept">
		<rds-header-cell *rdsHeaderCellDef></rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<button
				rds-text-button
				[disabled]="disableRowPredicate(element)"
				size="m"
				(click)="acceptClicked.emit({ id: element.id })"
			>
				Accept
			</button>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="reject-disabled">
		<rds-header-cell *rdsHeaderCellDef>Action</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<div rdsTooltip="The action is available only to Channel Owners and Channel Managers">
				<button rds-text-button size="m" [disabled]="true">Reject</button>
			</div>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="accept-disabled">
		<rds-header-cell *rdsHeaderCellDef></rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<div rdsTooltip="The action is available only to Channel Owners and Channel Managers">
				<button rds-text-button size="m" [disabled]="true">Accept</button>
			</div>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="assign">
		<rds-header-cell *rdsHeaderCellDef>Channels assigned</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<button
				rds-text-button
				[disabled]="disableRowPredicate(element)"
				size="m"
				(click)="assignClicked.emit({ id: element.id, isDraft: element.status === 'Draft' })"
			>
				{{ element.assignedCount > 0 ? 'Assigned (' + element.assignedCount + ')' : 'Assign' }}
			</button>
		</rds-cell>
	</ng-container>

	<!-- API KEYS COLUMNS -->

	<ng-container rdsColumnDef="apiKey.identifier">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="user.Identifier" class="sortable">Login</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<rh-user-single [user]="element.user"></rh-user-single>
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						title: element.user.identifier,
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="apiKey.name">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="user.Name" class="sortable">Name</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						title: element.user.name,
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="apiKey.email">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="user.Email" class="sortable">Email</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					mail;
					context: {
						email: element.user.email,
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="apiKey.created">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="createdDate" class="sortable">Created</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						tooltip: (element.createdDate | date: '\'Created on \' M/d/yy \'at\' h:mm a'),
						title: (element.createdDate | date: 'shortDate'),
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<!-- ADMINS COLUMNS -->
	<ng-container rdsColumnDef="admin.identifier">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="identifier" class="sortable">Login</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<rh-user-single [user]="element"></rh-user-single>
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						title: element.identifier,
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="admin.name">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="name" class="sortable">Name</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						title: element.name,
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="admin.role">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="role" class="sortable">Role</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngIf="{
					Admin: {
						label: 'Administrator',
						color: 'c-600-roche-blue',
					},
					SuperAdmin: {
						label: 'Super Administrator',
						color: 'c-200-orange',
					},
				} as roles"
			>
				<ng-container
					*ngTemplateOutlet="
						icon;
						context: {
							icon: 'user',
							namespace: 'outlined',
							colorClass: roles[element.role].color,
							tooltip: roles[element.role].label,
						}
					"
				></ng-container>
			</ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="admin.email">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="email" class="sortable">Email</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					mail;
					context: {
						email: element.email,
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="admin.created">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="createdDate" class="sortable">Created</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						tooltip: (element.createdDate | date: '\'Created on \' M/d/yy \'at\' h:mm a'),
						tooltipAlwaysVisible: true,
						title: (element.createdDate | date: 'shortDate'),
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<!-- CATALOG TABLE COLUMN DEFINITION  -->
	<ng-container rdsColumnDef="catalog.name">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="name" class="sortable">Entry name</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					link;
					context: {
						disabled: disableRowPredicate(element),
						tooltip: element.name,
						highlight: highlight,
						title: element.name,
						link:
							element.status.statusId === 1
								? 'catalog/entry/' + element.catalogId + '/edit'
								: 'catalog/entry/' + element.catalogId + '/info',
						linkType: 'route',
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="catalog.approval-name">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="name" class="sortable">Entry name</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					link;
					context: {
						disabled: disableRowPredicate(element),
						tooltip: element.name,
						highlight: highlight,
						title: element.name,
						link: 'catalog/entry/' + element.catalogId + '/info-manager',
						linkType: 'route',
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="catalog.owner">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="contentOwner" class="sortable">Content Owner</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container *ngIf="!!element.contentOwner">
				<rh-user-single
					[user]="{
						login: element.contentOwner,
						name: element.contentOwnerFirstName + ' ' + element.contentOwnerLastName,
					}"
				></rh-user-single>
			</ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="catalog.manager">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="contentManager" class="sortable"
			>Content Manager
		</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container *ngIf="!!element.contentManager">
				<rh-user-single
					[user]="{
						login: element.contentManager,
						name: element.contentManagerFirstName + ' ' + element.contentManagerLastName,
					}"
				></rh-user-single>
			</ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="catalog.created">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="creationDate" class="sortable">Created</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						tooltip: (element.creationDate | date: '\'Created on \' M/d/yy \'at\' h:mm a'),
						tooltipAlwaysVisible: true,
						title: (element.creationDate | date: 'shortDate'),
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="catalog.modified">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="lastModifiedDate" class="sortable">Modified</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						tooltip: (element.lastModifiedDate | date: '\'Modified on \' M/d/yy \'at\' h:mm a'),
						tooltipAlwaysVisible: true,
						title: (element.lastModifiedDate | date: 'shortDate'),
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="catalog.approved">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="approvedDate" class="sortable">Approved</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						tooltip: (element.approvedDate | date: '\'Approved on \' M/d/yy \'at\' h:mm a'),
						tooltipAlwaysVisible: true,
						title: (element.approvedDate | date: 'shortDate'),
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="catalog.rejected">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="rejectedDate" class="sortable">Rejected</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						tooltip: (element.rejectedDate | date: '\'Rejected on \' M/d/yy \'at\' h:mm a'),
						tooltipAlwaysVisible: true,
						title: (element.rejectedDate | date: 'shortDate'),
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="catalog.comment">
		<rds-header-cell *rdsHeaderCellDef>Comment</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						title: element.comment,
						tooltip: element.comment,
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="catalog.status">
		<rds-header-cell *rdsHeaderCellDef>Status</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<rh-catalog-status [status]="element.status"></rh-catalog-status>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="catalog.attention">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="isAttentionRequired" class="sortable"
			>Attention
		</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable c-error">
			<rds-icon
				*ngIf="element.isAttentionRequired"
				[rdsTooltip]="attentionRequiredTooltip"
				[rdsTooltipShowDelay]="300"
				namespace="outlined"
				icon="warning_triangle"
			></rds-icon>
			<ng-template #attentionRequiredTooltip>
				<ul class="tooltip-list c-white ui-component-text-s">
					<li *ngFor="let attention of element | attentionRequired">
						{{ attention }}
					</li>
				</ul>
			</ng-template>
		</rds-cell>
	</ng-container>

	<!-- MANAGE SET COLUMN DEFINITION -->
	<ng-container rdsColumnDef="set.quicklinks-name">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="name" class="sortable">Set title</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					link;
					context: {
						disabled: disableRowPredicate(element),
						tooltip: element.name,
						highlight: highlight,
						title: element.name,
						link: 'manage-sets/quicklinks/' + element.id + '/info',
						linkType: 'route',
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="set.channels-name">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="name" class="sortable">Set title</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					link;
					context: {
						disabled: disableRowPredicate(element),
						tooltip: element.name,
						highlight: highlight,
						title: element.name,
						link: 'manage-sets/channels/' + element.id + '/info',
						linkType: 'route',
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="set.location">
		<rds-header-cell *rdsHeaderCellDef>Location</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<ng-container
				*ngTemplateOutlet="
					taxonomy;
					context: {
						dimension: 'location',
						taxonomies: element.locations,
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="set.department">
		<rds-header-cell *rdsHeaderCellDef>Department</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<ng-container
				*ngTemplateOutlet="
					taxonomy;
					context: {
						dimension: 'department',
						taxonomies: element.departments,
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="set.function">
		<rds-header-cell *rdsHeaderCellDef>Function</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<ng-container
				*ngTemplateOutlet="
					taxonomy;
					context: {
						dimension: 'function',
						taxonomies: element.functions,
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<!-- INTRANET COORDINATORS COLUMN DEFINITION -->

	<ng-container rdsColumnDef="intranet-coordinators.login">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="login" class="sortable">Login</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<rh-user-single
				[user]="{
					login: element.login,
					name: element.firstName + ' ' + element.lastName,
				}"
			></rh-user-single>
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						title: element.login,
						highlight: highlight,
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="intranet-coordinators.name">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="name" class="sortable">Name</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					link;
					context: {
						disabled: disableRowPredicate(element),
						tooltip: element.login + '\n' + element.email,
						highlight: highlight,
						title: element.firstName + ' ' + element.lastName,
						link: element,
						linkType: 'route',
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="intranet-coordinators.location">
		<rds-header-cell *rdsHeaderCellDef>Location</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<ng-container
				*ngTemplateOutlet="
					taxonomy;
					context: {
						dimension: 'location',
						taxonomies: element.locations,
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="intranet-coordinators.department">
		<rds-header-cell *rdsHeaderCellDef>Department</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<ng-container
				*ngTemplateOutlet="
					taxonomy;
					context: {
						dimension: 'department',
						taxonomies: element.departments,
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="intranet-coordinators.function">
		<rds-header-cell *rdsHeaderCellDef>Function</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<ng-container
				*ngTemplateOutlet="
					taxonomy;
					context: {
						dimension: 'function',
						taxonomies: element.functions,
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<!--ASSIGN ROLES COLUMN DEFINITION -->

	<ng-container rdsColumnDef="assign-role.login">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="login" class="sortable">Login</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<rh-user-single
				[user]="{
					login: element.login,
					name: element.firstName + ' ' + element.lastName,
				}"
			></rh-user-single>
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						title: element.login,
						highlight: highlight,
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="assign-role.name">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="name" class="sortable">Name</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					link;
					context: {
						disabled: disableRowPredicate(element),
						tooltip: element.login + '\n' + element.email,
						highlight: highlight,
						title: element.firstName + ' ' + element.lastName,
						link: 'role/' + element.login + '/info',
						linkType: 'route',
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="assign-role.role">
		<rds-header-cell *rdsHeaderCellDef>Role</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<ng-container *ngIf="element.isAdministrator">
				<ng-container
					*ngTemplateOutlet="
						icon;
						context: {
							icon: 'user',
							namespace: 'outlined',
							colorClass: 'c-600-roche-blue',
							tooltip: 'Admin',
						}
					"
				></ng-container>
			</ng-container>
			<ng-container *ngIf="element.isAudienceManager">
				<ng-container
					*ngTemplateOutlet="
						icon;
						context: {
							icon: 'user',
							namespace: 'outlined',
							colorClass: 'c-200-orange',
							tooltip: 'Intranet Coordinator',
						}
					"
				></ng-container>
			</ng-container>
			<ng-container *ngIf="element.isContentQualityManager">
				<ng-container
					*ngTemplateOutlet="
						icon;
						context: {
							icon: 'user',
							namespace: 'outlined',
							colorClass: 'c-500-extra-dark-green',
							tooltip: 'Content Quality Manager',
						}
					"
				></ng-container>
			</ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="assign-role.location">
		<rds-header-cell *rdsHeaderCellDef>Location</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<ng-container
				*ngTemplateOutlet="
					taxonomy;
					context: {
						dimension: 'location',
						taxonomies: element.locations,
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="assign-role.department">
		<rds-header-cell *rdsHeaderCellDef>Department</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<ng-container
				*ngTemplateOutlet="
					taxonomy;
					context: {
						dimension: 'department',
						taxonomies: element.departments,
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="assign-role.function">
		<rds-header-cell *rdsHeaderCellDef>Function</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<ng-container
				*ngTemplateOutlet="
					taxonomy;
					context: {
						dimension: 'function',
						taxonomies: element.functions,
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<!-- TICKER TABLE COLUMN DEFINITIONS -->
	<ng-container rdsColumnDef="ticker.type">
		<rds-header-cell *rdsHeaderCellDef>Type</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<ng-container
				*ngIf="{
					'1': {
						label: '',
						color: 'warning',
						type: 'dot',
						size: 's',
					},
					'2': {
						label: '',
						color: 'success',
						type: 'dot',
						size: 's',
					},
					'3': {
						label: '',
						color: 'error',
						type: 'dot',
						size: 's',
					},
					'4': {
						label: '',
						color: 'other',
						type: 'dot',
						size: 's',
					},
				} as statuses"
			>
				<ng-container
					*ngTemplateOutlet="
						badge;
						context: {
							color: statuses[element.type].color,
							label: statuses[element.type].label,
							type: statuses[element.type].type,
							size: statuses[element.type].size,
						}
					"
				></ng-container>
				<ng-container [ngSwitch]="element.type">
					<p *ngSwitchCase="1">&nbsp;News</p>
					<p *ngSwitchCase="2">&nbsp;Updates</p>
					<p *ngSwitchCase="3">&nbsp;Alerts</p>
					<p *ngSwitchCase="4">&nbsp;Events</p>
				</ng-container>
			</ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="ticker.message">
		<rds-header-cell *rdsHeaderCellDef>News message</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<ng-container
				*ngTemplateOutlet="
					link;
					context: {
						disabled: disableRowPredicate(element),
						tooltip: element.message,
						highlight: highlight,
						title: element.message,
						link: element.id,
						linkType: 'route',
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="ticker.published">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="published" class="sortable">Published</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						tooltip: (element.publishDate | date: '\'Created on \' M/d/yy \'at\' h:mm a'),
						tooltipAlwaysVisible: true,
						title: (element.publishDate | date: 'shortDate'),
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="ticker.expired">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="expire" class="sortable">Expired</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						tooltip: (element.expireDate | date: '\'Modified on \' M/d/yy \'at\' h:mm a'),
						tooltipAlwaysVisible: true,
						title: (element.expireDate | date: 'shortDate'),
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<!-- GENERIC ACCOUNTS COLUMN DEFINITIONS -->
	<ng-container rdsColumnDef="generic-accounts.login">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="username" class="sortable">Login</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<rh-user-single
				[user]="{
					login: element.username,
					name: element.firstName + ' ' + element.lastName,
				}"
			></rh-user-single>
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						title: element.username,
						highlight: highlight,
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="generic-accounts.name">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="name" class="sortable">Name</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						title: element.firstName + ' ' + element.lastName,
						highlight: highlight,
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="generic-accounts.email">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="email" class="sortable">Email</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					mail;
					context: {
						email: element.email,
						highlight: highlight,
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="generic-accounts.created">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="createdAt" class="sortable">Created</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						tooltip: (element.createdAt | date: '\'Created on \' M/d/yy \'at\' h:mm a'),
						tooltipAlwaysVisible: true,
						title: (element.createdAt | date: 'shortDate'),
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="generic-accounts.synchronized">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="isSynchronizedWithWorkday" class="sortable"
			>Synchronized
		</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container *ngIf="element.isSynchronizedWithWorkday">
				<ng-container
					*ngTemplateOutlet="
						badge;
						context: {
							color: 'green',
							label: 'Yes',
						}
					"
				></ng-container>
			</ng-container>
			<ng-container *ngIf="!element.isSynchronizedWithWorkday">
				<ng-container
					*ngTemplateOutlet="
						badge;
						context: {
							color: 'red',
							label: 'No',
						}
					"
				></ng-container>
			</ng-container>
		</rds-cell>
	</ng-container>

	<!-- EVENTS COLUMN DEFINITIONS -->
	<ng-container rdsColumnDef="event.title">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="Title" class="sortable">Title</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					link;
					context: {
						disabled: disableRowPredicate(element),
						tooltip: element.title,
						highlight: highlight,
						title: element.title,
						link: element.id,
						linkType: 'route',
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="event.eventOwners">
		<rds-header-cell *rdsHeaderCellDef>Owners</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<rh-user-group [users]="element.eventOwners"></rh-user-group>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="event.type">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="type" class="sortable">Type</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<div class="event-table__cell">
				<ng-container *ngIf="element.type > -1">
					<ng-container
						*ngIf="{
							'0': {
								type: 'On-Site',
							},
							'1': {
								type: 'Virtual',
							},
							'2': {
								type: 'Hybrid',
							},
							'3': {
								type: 'Off-Site',
							},
						} as types"
					>
						<ng-container
							*ngTemplateOutlet="
								text;
								context: {
									title: element.type !== null ? types[element.type].type : '',
								}
							"
						></ng-container>
					</ng-container>
				</ng-container>
			</div>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="event.location">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="location" class="sortable">Location</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						tooltip: element.location,
						highlight: highlight,
						title: element.location,
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="event.date">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="date" class="sortable">Date</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						tooltip: element.allDay
							? 'All day event'
							: (element.startTime | date: 'shortTime') +
								' - ' +
								(element.endTime | date: 'shortTime') +
								(element.timeZone !== defaultTimeZone ? ' (' + element.timeZone + ')' : ''),
						tooltipAlwaysVisible: true,
						title: (element.date | date: 'shortDate'),
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="event.status">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="status" class="sortable">Status</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngIf="{
					'0': {
						label: 'Draft',
						color: 'blue',
					},
					'1': {
						label: 'Published',
						color: 'green',
					},
				} as statuses"
			>
				<ng-container
					*ngTemplateOutlet="
						badge;
						context: {
							color: statuses[element.status].color,
							label: statuses[element.status].label,
						}
					"
				></ng-container>
			</ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="event.topEvent">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="topEvent" class="sortable"> Top Event</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<rds-icon class="c-success" *ngIf="element.topEvent" namespace="filled" icon="checkmark"></rds-icon>
			<rds-icon *ngIf="!element.topEvent" namespace="filled" icon="close"></rds-icon>
		</rds-cell>
	</ng-container>

	<!-- Newsletter URL Statistics -->
	<ng-container rdsColumnDef="newsletter-urls.linkname">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="linkname" class="sortable">URL Name</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<a rds-link standalone [href]="element.url" target="_blank">
				{{ element.linkName }}
			</a>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="newsletter-urls.numberofclicks">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="numberofclicks" class="sortable"
			>Number of clicks
		</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			{{ element.numberOfClicks }}
		</rds-cell>
	</ng-container>

	<!-- Newsletter URL Statistics -->
	<ng-container rdsColumnDef="newsletter-links.name">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="name" class="sortable">Link name</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<div style="overflow: hidden">
				<a rds-link standalone [href]="element.url" target="_blank">
					<ng-container
						*ngTemplateOutlet="
							text;
							context: {
								tooltip: element.name,
								highlight: highlight,
								title: element.name,
							}
						"
					></ng-container>
				</a>
				<p class="ui-component-text-s">
					<ng-container
						*ngTemplateOutlet="
							text;
							context: {
								tooltip: element.url,
								highlight: highlight,
								title: element.url,
							}
						"
					></ng-container>
				</p>
			</div>
		</rds-cell>
	</ng-container>
	<ng-container rdsColumnDef="newsletter-links.type">
		<rds-header-cell *rdsHeaderCellDef>Type</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<ng-container [ngSwitch]="element.type">
				<rds-badge *ngSwitchCase="0" size="m" type="minimal" color="orange" label="Button"></rds-badge>
				<rds-badge *ngSwitchCase="1" size="m" type="minimal" color="neutral" label="Channel"></rds-badge>
				<rds-badge *ngSwitchCase="2" size="m" type="minimal" color="blue" label="News"></rds-badge>
				<rds-badge *ngSwitchCase="3" size="m" type="minimal" color="purple" label="Event"></rds-badge>
				<rds-badge *ngSwitchCase="4" size="m" type="minimal" color="black" label="Text"></rds-badge>
				<rds-badge *ngSwitchCase="5" size="m" type="minimal" color="pink" label="Image"></rds-badge>
				<rds-badge *ngSwitchDefault size="m" type="minimal" color="black" label="Text"></rds-badge>
			</ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="newsletter-links.clicks">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="clicksCount" class="sortable">Clicks</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			{{ element.clicksCount }}
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="newsletter-links.ctr">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="clickThroughtRate" class="sortable">
			CTR<sup>
				<rds-icon style="width: 16px" icon="info" namespace="outlined" rdsTooltip="Click-through rate"></rds-icon>
			</sup>
		</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable"> {{ element.clickThroughtRate }}%</rds-cell>
	</ng-container>

	<!-- Newsletter table -->
	<ng-container rdsColumnDef="newsletter.title">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="Title" class="sortable">Newsletter title</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					link;
					context: {
						disabled: (element.templateId !== 'NewsletterTemplateNew') || disableRowPredicate(element),
						tooltip: element.displayName || element.title,
						highlight: highlight,
						title: element.displayName || element.title,
						link: element.id,
						linkType: 'route',
					}"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="newsletter.campaign">
		<rds-header-cell *rdsHeaderCellDef>Campaign</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<a rds-link standalone [href]="element.url" target="_blank">
				{{ element.campaignName }}
			</a>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="newsletter.editors">
		<rds-header-cell *rdsHeaderCellDef>Editors</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<rh-user-single
				*ngIf="element.isUnavailable"
				class="force-enable"
				[user]="{
					login: element.workingUserLogin,
					name: element.workingUserFullName,
				}"
				[customTooltip]="'Currently edited by ' + element.workingUserFullName"
			></rh-user-single>
			<rh-user-group
				[size]="element.isUnavailable ? 's' : 'm'"
				[users]="element.newsletterContributors.length > 0 ? element.newsletterContributors : [element.lastModifiedBy]"
			></rh-user-group>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="newsletter.edited">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="LastModifiedDate" class="sortable">Edited</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						tooltip: (element.edited | date: '\'Edited on \' M/d/yy \'at\' h:mm a'),
						tooltipAlwaysVisible: true,
						title: (element.edited | date: 'shortDate'),
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="newsletter.published">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="LastSentDate" class="sortable"
			>Publishing date
		</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngIf="{
					'0': {
						tooltip: null,
						title: null,
					},
					'1': {
						tooltip: (element.publishingDate | date: '\'Published on \' M/d/yy \'at\' h:mm a'),
						title: (element.publishingDate | date: 'shortDate'),
					},
					'2': {
						tooltip: (element.publishingDate | date: '\'Scheduled on \' M/d/yy \'at\' h:mm a'),
						title: (element.publishingDate | date: 'shortDate'),
					},
					'3': {
						tooltip: null,
						title: null,
					},
					'4': {
						tooltip: null,
						title: null,
					},
					'99': {
						tooltip: null,
						title: null,
					},
				} as statuses"
			>
				<ng-container
					*ngTemplateOutlet="
						text;
						context: {
							tooltip: statuses[element.statusId].tooltip,
							title: statuses[element.statusId].title,
							tooltipAlwaysVisible: element.statusId !== 0,
						}
					"
				></ng-container>
			</ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="newsletter.status">
		<rds-header-cell *rdsHeaderCellDef>Status</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<ng-container
				*ngIf="{
					'0': {
						label: 'Draft',
						color: 'blue',
					},
					'1': {
						label: 'Sent',
						color: 'green',
					},
					'2': {
						label: 'Scheduled',
						color: 'neutral',
					},
					'3': {
						label: 'Template',
						color: 'pink',
					},
					'4': {
						label: 'Shared template',
						color: 'purple',
					},
				} as statuses"
			>
				<ng-container
					*ngTemplateOutlet="
						badge;
						context: {
							color: statuses[element.statusId].color,
							label: statuses[element.statusId].label,
						}
					"
				></ng-container>
			</ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="newsletter.statistics">
		<rds-header-cell *rdsHeaderCellDef>Statistics</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<div
				*ngIf="element.statusId === 1"
				[rdsTooltipDisabled]="element.templateId === 'NewsletterTemplateNew'"
				rdsTooltip="Statistics for legacy Newsletters are not available"
			>
				<button
					rds-icon-button
					[routerLink]="'/newsletter/newsletter/' + element.id + '/stats'"
					[queryParams]="{ title: element.displayName || element.title }"
					[disabled]="disableRowPredicate(element) || element.templateId !== 'NewsletterTemplateNew'"
					size="m"
				>
					<rds-icon namespace="outlined" icon="view_line_chart"></rds-icon>
				</button>
			</div>
		</rds-cell>
	</ng-container>

	<!-- Recipient list table -->
	<ng-container rdsColumnDef="recipient.name">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="name" class="sortable">List Name</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					link;
					context: {
						disabled: disableRowPredicate(element),
						tooltip: element.displayName || element.name,
						highlight: highlight,
						title: element.displayName || element.name,
						link: element.id,
						linkType: 'route',
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="recipient.campaigns">
		<rds-header-cell *rdsHeaderCellDef>Campaigns</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<ng-container>
				<ng-container
					*ngTemplateOutlet="
						text;
						context: {
							tooltip: element.campaigns.join(', '),
							tooltipAlwaysVisible: true,
							title: element.campaigns.join(', '),
						}
					"
				></ng-container>
			</ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="recipient.user">
		<rds-header-cell *rdsHeaderCellDef>Recipients</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						highlight: highlight,
						title: element.recipientsCount || 0,
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="recipient.rada">
		<rds-header-cell *rdsHeaderCellDef>RADA groups</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						highlight: highlight,
						title: element.radaGroupsCount || 0,
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="recipient.lastModifiedDate">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="lastModifiedDate" class="sortable">Edited</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						tooltip: (element.lastModifiedDate | date: '\'Edited on \' M/d/yy \'at\' h:mm a'),
						tooltipAlwaysVisible: true,
						title: (element.lastModifiedDate | date: 'shortDate'),
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<!-- Campaign table -->

	<!-- Recipient list table -->
	<ng-container rdsColumnDef="campaign.name">
		<rds-header-cell *rdsHeaderCellDef rds-sort-header="name" class="sortable">Campaign Name</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					link;
					context: {
						disabled: disableRowPredicate(element),
						tooltip: element.displayName || element.name,
						highlight: highlight,
						title: element.displayName || element.name,
						link: element.id,
						linkType: 'route',
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="campaign.editors">
		<rds-header-cell *rdsHeaderCellDef>Editors</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<!--            <rh-user-single-->
			<!--                *ngIf="element.isUnavailable"-->
			<!--                class="force-enable"-->
			<!--                [user]="{-->
			<!--					login: element.workingUserLogin,-->
			<!--					name: element.workingUserFullName-->
			<!--				}"-->
			<!--                [customTooltip]="'Currently edited by ' + element.workingUserFullName"-->
			<!--            ></rh-user-single>-->
			<rh-user-group
				[size]="element.isUnavailable ? 's' : 'm'"
				[users]="element.campaignUserRoles.length > 0 ? element.campaignUserRoles : [element.lastModifiedBy]"
			></rh-user-group>
		</rds-cell>
	</ng-container>
	<ng-container rdsColumnDef="campaign.allowToSubscribe">
		<rds-header-cell *rdsHeaderCellDef>Subscription</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<ng-container
				*ngIf="{
					false: {
						label: 'Disabled',
						color: 'neutral',
					},
					true: {
						label: 'Enabled',
						color: 'green',
					},
				} as statuses"
			>
				<ng-container
					*ngTemplateOutlet="
						badge;
						context: {
							color: statuses[element.allowToSubscribe].color,
							label: statuses[element.allowToSubscribe].label,
						}
					"
				></ng-container>
			</ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="campaign.numberOfSubscribers">
		<rds-header-cell *rdsHeaderCellDef class="sortable">No.</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						highlight: highlight,
						title: element.allowToSubscribe || element.subscriptionCount > 0 ? element.subscriptionCount+'' : '-',
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="campaign.allowToUnsubscribe">
		<rds-header-cell *rdsHeaderCellDef>Unsubscription</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<ng-container
				*ngIf="{
					false: {
						label: 'Disabled',
						color: 'neutral',
					},
					true: {
						label: 'Enabled',
						color: 'green',
					},
				} as statuses"
			>
				<ng-container
					*ngTemplateOutlet="
						badge;
						context: {
							color: statuses[element.allowToUnsubscribe].color,
							label: statuses[element.allowToUnsubscribe].label,
						}
					"
				></ng-container>
			</ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="campaign.numberOfUnSubscribers">
		<rds-header-cell *rdsHeaderCellDef class="sortable">No.</rds-header-cell>
		<rds-cell *rdsCellDef="let element" class="sortable">
			<ng-container
				*ngTemplateOutlet="
					text;
					context: {
						highlight: highlight,
						title: element.allowToUnsubscribe || element.unsubscriptionCount > 0 ? element.unsubscriptionCount+'' : '-',
					}
				"
			></ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="campaign.status">
		<rds-header-cell *rdsHeaderCellDef>Status</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<ng-container
				*ngIf="{
					'0': {
						label: 'Active',
						color: 'green',
					},
					'1': {
						label: 'Archived',
						color: 'neutral',
					},
				} as statuses"
			>
				<ng-container
					*ngTemplateOutlet="
						badge;
						context: {
							color: statuses[element?.status].color,
							label: statuses[element?.status].label,
						}
					"
				></ng-container>
			</ng-container>
		</rds-cell>
	</ng-container>

	<ng-container rdsColumnDef="campaign.statistics">
		<rds-header-cell *rdsHeaderCellDef>Statistics</rds-header-cell>
		<rds-cell *rdsCellDef="let element">
			<button
				rds-icon-button
				[routerLink]="'/newsletter/campaign/' + element.id + '/stats'"
				[queryParams]="{ title: element.displayName || element.title }"
				[disabled]="disableRowPredicate(element)"
				size="m"
			>
				<rds-icon namespace="outlined" icon="view_line_chart"></rds-icon>
			</button>
		</rds-cell>
	</ng-container>

	<rds-header-row class="table__header" *rdsHeaderRowDef="allColumns"></rds-header-row>
	<rds-header-row
		class="table__bulk"
		[class.table__hide]="!selection.hasValue()"
		[class.table__show]="selection.hasValue()"
		*rdsHeaderRowDef="deleteColumns"
	></rds-header-row>
	<rds-header-row
		class="table__custom"
		[class.table__hide]="!customRowVisible"
		[class.table__show]="customRowVisible"
		*rdsHeaderRowDef="['custom']"
	></rds-header-row>
	<rds-row
		*rdsRowDef="let row; columns: isLoading ? loadingColumns : allColumns"
		[selected]="selection.isSelected(row)"
		[disabled]="disableRowPredicate(row)"
	></rds-row>
	<div *rdsNoDataRow class="table__empty">
		<rds-empty-state alignment="center" *ngIf="isLoading === false && !filtersApplied" size="m">
			<img rds-empty-state-image src="assets/images/empty-state.png" alt="No data" />
			<rds-empty-state-title>
				<ng-content select="[emptyTitle]"></ng-content>
			</rds-empty-state-title>
			<rds-empty-state-subtitle>
				<ng-content select="[emptySubtitle]"></ng-content>
			</rds-empty-state-subtitle>
			<ng-content select="[rds-empty-state-button]"></ng-content>
		</rds-empty-state>
		<rds-empty-state alignment="center" *ngIf="isLoading === false && filtersApplied" size="m">
			<img rds-empty-state-image src="/assets/images/microscope.png" alt="" />
			<rds-empty-state-title>No search results.</rds-empty-state-title>
			<rds-empty-state-subtitle>Please check your spelling or adjust filter settings.</rds-empty-state-subtitle>
		</rds-empty-state>
	</div>
</rds-table>
<rds-paginator
	*ngIf="pagination"
	#paginatorRef
	class="table__pagination mt-9"
	[length]="pagination.totalCount"
	[pageIndex]="pagination.pageIndex"
	[pageSize]="pagination.pageSize"
	[pageSizeOptions]="pageSizeOptions"
	[showItemsOfPageLabel]="true"
	[showFirstLastButtons]="true"
	[hidePageSize]="false"
	(page)="onPagination($event)"
></rds-paginator>

<!-- CELL TEMPLATES -->
<ng-template
	#link
	let-disabled="disabled"
	let-tooltip="tooltip"
	let-highlight="highlight"
	let-title="title"
	let-link="link"
	let-linkType="linkType"
>
	<div
		class="table__cell"
		[rdsTooltipDisabled]="!tooltip || !shouldTruncate.shouldTruncate"
		[rdsTooltip]="tooltip"
		[rdsTooltipShowDelay]="300"
	>
		<a
			rds-link
			standalone
			size="m"
			#shouldTruncate="rnbShouldTruncate"
			[disabled]="disabled"
			rnbShouldTruncate
			(click)="linkClicked.emit({ link, linkType })"
		>
			<span [innerHTML]="title | highlight: highlight"></span>
		</a>
	</div>
</ng-template>

<ng-template #mail let-disabled="disabled" let-tooltip="tooltip" let-highlight="highlight" let-email="email">
	<div class="table__cell" [rdsTooltipDisabled]="!tooltip" [rdsTooltip]="tooltip" [rdsTooltipShowDelay]="300">
		<a
			rds-link
			[disabled]="disabled"
			standalone
			size="m"
			[href]="'mailto:' + email"
			target="_blank"
			[innerHTML]="email | highlight: highlight"
		>
		</a>
	</div>
</ng-template>

<ng-template
	#text
	let-tooltip="tooltip"
	let-highlight="highlight"
	let-title="title"
	let-tooltipAlwaysVisible="tooltipAlwaysVisible"
>
	<div
		class="table__cell"
		#shouldTruncate="rnbShouldTruncate"
		rnbShouldTruncate
		[rdsTooltipDisabled]="(!tooltip || !shouldTruncate.shouldTruncate) && !tooltipAlwaysVisible"
		[rdsTooltip]="tooltip"
		[rdsTooltipShowDelay]="300"
	>
		<span [innerHTML]="title | highlight: highlight"> </span>
	</div>
</ng-template>
<ng-template #icon let-icon="icon" let-namespace="namespace" let-colorClass="colorClass" let-tooltip="tooltip">
	<div [rdsTooltip]="tooltip" [rdsTooltipShowDelay]="300" style="display: flex">
		<rds-icon [ngClass]="colorClass" [namespace]="namespace" [icon]="icon"></rds-icon>
	</div>
</ng-template>

<ng-template #date let-tooltip="tooltip" let-date="date">
	<div
		class="table__cell"
		[rdsTooltipDisabled]="!tooltip"
		[rdsTooltip]="tooltip | date: 'shortTime'"
		[rdsTooltipShowDelay]="300"
	>
		<span>
			{{ date | date: 'shortDate' }}
		</span>
	</div>
</ng-template>

<ng-template #badge let-tooltip="tooltip" let-color="color" let-label="label" let-type="type" let-size="size">
	<div class="table__cell" [rdsTooltipDisabled]="!tooltip" [rdsTooltip]="tooltip" [rdsTooltipShowDelay]="300">
		<rds-badge [size]="size ? size : 'm'" [label]="label" [type]="type ? type : 'minimal'" [color]="color"></rds-badge>
	</div>
</ng-template>

<ng-template #taxonomy let-dimension="dimension" let-taxonomies="taxonomies">
	<rds-chip-list *ngIf="taxonomies.length > 0" size="s" class="nowrap">
		<rds-chip
			rhIsOverflowing
			#overflow="rhIsOverflowing"
			[rdsTooltipDisabled]="!overflow.overflowingX"
			[rdsTooltip]="taxonomies[0] | dictionary: dimension | async"
			[rdsTooltipShowDelay]="300"
		>
			<p class="ellipsis-overflow">
				{{ taxonomies[0] | dictionary: dimension | async }}
			</p>
		</rds-chip>
		<rds-chip
			[class.d-none]="taxonomies.length < 2"
			class="notruncate"
			[rdsTooltip]="allTaxonomiesTemplate"
			[rdsTooltipShowDelay]="300"
		>
			<p class="ellipsis-overflow">+{{ taxonomies.length - 1 }}</p>
		</rds-chip>
		<ng-template #allTaxonomiesTemplate>
			<ng-container *ngFor="let t of taxonomies; let i = index">
				<p *ngIf="i > 0">
					{{ t | dictionary: dimension | async }}
				</p>
			</ng-container>
		</ng-template>
	</rds-chip-list>
</ng-template>

import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import { Pagination } from '@core/models';
import { SortDirection } from '@rds/angular-components';
import { NewsletterTableFilters } from '@app/shared/filters/models/newsletter-table';
import { BackButton } from '@app/root-store/ui/back-button';
import { NewsletterRenameResponse, NewsletterTableModel, UserSharedProfile } from '@app/core/models/newsletter.model';

export const table = createActionGroup({
	source: 'NEWSLETTER TABLE',
	events: {
		init: emptyProps(),
		clear: emptyProps(),
		request: props<{
			pageIndex: number;
			pageSize: number;
			sort: {
				active: string;
				direction: SortDirection;
			};
			filters: {
				[key: string]: any;
			};
		}>(),
		success: props<{
			data: Array<NewsletterTableModel>;
			pagination: Pagination;
      view?: 'newsletters' | 'campaignNewsletters';
		}>(),
		failure: props<{
			error: any;
		}>(),
		filter: props<{
			filters: NewsletterTableFilters;
		}>(),
		sort: props<{
			active: string;
			direction: SortDirection;
		}>(),
		paginate: props<{
			pageIndex: number;
			pageSize: number;
		}>(),
		campaignInit: props<{ id: number }>(),
		campaignRequest: props<{
			pageIndex: number;
			pageSize: number;
			sort: {
				active: string;
				direction: SortDirection;
			};
			filters: {
				[key: string]: any;
			};
		}>(),
	},
});

export const openNewsPreview = createAction(
	'[NEWSLETTER TABLE] Open news preview',
	props<{
		id: number;
		backButton: BackButton;
	}>()
);

export const deleteNewsletter = createActionGroup({
	source: 'DELETE NEWSLETTER',
	events: {
		dialog: props<{
			newsletter: NewsletterTableModel;
		}>(),
		request: props<{
			id: number;
			newsletter: NewsletterTableModel;
		}>(),
		success: emptyProps(),
		failure: props<{
			error: string;
		}>(),
	},
});

export const renameNewsletter = createActionGroup({
	source: 'RENAME NEWSLETTER',
	events: {
		dialog: props<{
			newsletter: NewsletterTableModel;
		}>(),
		request: props<{
			id: number;
			title: string;
		}>(),
		success: props<{
			newsletter: {newsletterId: number, displayName: string};
		}>(),
		failure: props<{
			error: string;
		}>(),
	},
});
export const shareNewsletter = createActionGroup({
	source: 'SHARE NEWSLETTER',
	events: {
		dialog: props<{
			newsletter: NewsletterTableModel;
		}>(),
		request: props<{
			newsletter: NewsletterTableModel;
			newsletterContributors: UserSharedProfile[];
		}>(),
		success: props<{
			newsletterContributors: UserSharedProfile[];
			id: number;
		}>(),
		failure: props<{
			error: string;
		}>(),
	},
});
export const moveToDraft = createActionGroup({
	source: 'MOVE TO DRAFT',
	events: {
		dialog: props<{
			newsletter: NewsletterTableModel;
		}>(),
		request: props<{
			newsletter: any;
		}>(),
		success: props<{
			newsletter: NewsletterRenameResponse;
		}>(),
		failure: props<{
			error: string;
		}>(),
	},
});

export const saveAsTemplate = createActionGroup({
	source: 'SAVE AS TEMPLATE',
	events: {
		request: props<{
			id: number;
		}>(),
		success: emptyProps(),
		failure: props<{
			error: string;
		}>(),
	},
});

export const createRecipientsList = createActionGroup({
	source: 'SAVE AS TEMPLATE',
	events: {
    dialog: props<{
      newsletter: NewsletterTableModel;
    }>(),
		request: props<{
			id: number;
		}>(),
		success: emptyProps(),
		failure: props<{
			error: string;
		}>(),
	},
});

export const updateFromWs = createActionGroup({
	source: 'UPDATE FROM WS',
	events: {
		update: props<{
			id: number;
			action: 'renamed' | 'deleted' | 'movedToDraft' | 'shared' | 'sent';
			data: any;
		}>(),
		delete: props<{
			id: number;
		}>(),
		share: props<{
			id: number;
			action: 'renamed' | 'deleted' | 'movedToDraft' | 'shared' | 'sent' | 'contributorAdded' | 'contributorRemoved';
			data: any;
		}>(),
	},
});

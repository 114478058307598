import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
	AdvancedFilterDefinition,
	DateFilterDefinition,
	DictionaryFilterDefinition,
	DictionaryTypeEnum,
	FilterTypeEnum,
	PredefinedFilterDefinition,
	SelectFilterDefinition,
	SuggestionFilterDefinition,
} from './filters-model';
import { Injectable } from '@angular/core';

import * as fromDepartmentsFlat from '@app/core/core-store/departments-flat';
import * as fromFunctionsFlat from '@app/core/core-store/functions-flat';
import * as fromLocationsFlat from '@app/core/core-store/locations-flat';
import * as fromContentTypes from '@core/core-store/content-types';
import * as fromCategories from '@core/core-store/categories-flat';
import * as fromSuggestions from '@app/root-store/suggestions';
import * as fromCampaginsTable from '@app/newsletter-new/store/campaign-table';
import { FormControl, FormGroup } from '@angular/forms';
import { categoriesOptionsModel, contentTypesOptionsModel, taxonomyOptionsModel } from '@app/core/models';

@Injectable({
	providedIn: 'root',
})
export class FiltersUtilsService {
	taxonomyOptModel = taxonomyOptionsModel;
	contentTypesOptModel = contentTypesOptionsModel;
	categoriesOptModel = categoriesOptionsModel;

	constructor(private store$: Store<any>) {}

	initializeDictionaries(filters: { [key: string]: any }) {
		let dictionaries: { [key: string]: { dict: Observable<any>; model: any } } = {};

		Object.keys(filters).forEach((prop) => {
			if (filters[prop].type === FilterTypeEnum.DICTIONARY && !dictionaries[prop]) {
				switch ((filters[prop] as DictionaryFilterDefinition).dictionary) {
					case DictionaryTypeEnum.LOCATIONS:
						this.store$.dispatch(fromLocationsFlat.loadLocationsFlat());
						dictionaries = {
							...dictionaries,
							[prop]: {
								dict: this.store$.pipe(select(fromLocationsFlat.selectAllActive)),
								model: this.taxonomyOptModel,
							},
						};
						break;
					case DictionaryTypeEnum.DEPARTMENTS:
						this.store$.dispatch(fromDepartmentsFlat.loadDepartmentsFlat());
						dictionaries = {
							...dictionaries,
							[prop]: {
								dict: this.store$.pipe(select(fromDepartmentsFlat.selectAllActive)),
								model: this.taxonomyOptModel,
							},
						};
						break;
					case DictionaryTypeEnum.FUNCTIONS:
						this.store$.dispatch(fromFunctionsFlat.loadFunctionsFlat());
						dictionaries = {
							...dictionaries,
							[prop]: {
								dict: this.store$.pipe(select(fromFunctionsFlat.selectAllActive)),
								model: this.taxonomyOptModel,
							},
						};
						break;
					case DictionaryTypeEnum.CONTENT_TYPES:
						this.store$.dispatch(fromContentTypes.loadContentTypes());
						dictionaries = {
							...dictionaries,
							[prop]: {
								dict: this.store$.pipe(select(fromContentTypes.selectAll)),
								model: this.contentTypesOptModel,
							},
						};
						break;
					case DictionaryTypeEnum.CATEGORIES:
						this.store$.dispatch(fromCategories.loadCategoriesFlat());
						const fromBranch = (filters[prop] as DictionaryFilterDefinition).fromBranch;
						dictionaries = {
							...dictionaries,
							[prop]: {
								dict: fromBranch
									? this.store$.pipe(select(fromCategories.selectBranchWithChildrens(fromBranch)))
									: this.store$.pipe(select(fromCategories.selectAll)),
								model: this.categoriesOptModel,
							},
						};
						break;
					case DictionaryTypeEnum.CAMPAIGNS:
						this.store$.dispatch(fromCampaginsTable.getAllCampaign.load());
						dictionaries = {
							...dictionaries,
							[prop]: {
								dict: this.store$.pipe(select(fromCampaginsTable.selectSelectionListData)),
								model: this.contentTypesOptModel,
							},
						};
						break;
				}
			}
		});
		return dictionaries;
	}

	initializeSuggestions(filters: { [key: string]: any }) {
		Object.keys(filters).forEach((prop) => {
			if (filters[prop].type === FilterTypeEnum.SUGGESTION) {
				this.store$.dispatch(
					fromSuggestions.initSuggestion({
						suggestionType: (filters[prop] as SuggestionFilterDefinition).suggestion,
						prop,
					})
				);
			}
		});
	}

	formifyFilters(form: FormGroup, filters: { [key: string]: any }) {
		Object.keys(filters).forEach((prop) => {
			if (!!form.get(prop)) {
				(form.get(prop) as FormGroup).controls.value.setValue(filters[prop].value, { emitEvent: true });
				form.updateValueAndValidity();
			} else {
				let typeSpecificControls = {};

				switch (filters[prop].type) {
					case FilterTypeEnum.SELECT:
						const selectFilter = filters[prop] as SelectFilterDefinition;
						typeSpecificControls = {
							label: new FormControl(selectFilter.label),
							multiple: new FormControl(selectFilter.multiple),
							options: new FormControl(selectFilter.options),
						};
						break;
					case FilterTypeEnum.DICTIONARY:
						const taxonomyFilter = filters[prop] as DictionaryFilterDefinition;
						typeSpecificControls = {
							dictionary: new FormControl(taxonomyFilter.dictionary),
							label: new FormControl(taxonomyFilter.label),
						};
						break;
					case FilterTypeEnum.ADVANCED:
						const advancedFilter = filters[prop] as AdvancedFilterDefinition<any>;
						typeSpecificControls = {
							activeCount: new FormControl(advancedFilter.activeCount),
							filters: new FormControl(advancedFilter.filters),
						};
						break;
					case FilterTypeEnum.DATE:
						const dateFilter = filters[prop] as DateFilterDefinition;
						const valueControl = dateFilter.range
							? new FormGroup({
									start: new FormControl(filters[prop].value?.start),
									end: new FormControl(filters[prop].value?.end),
								})
							: new FormControl(null);
						typeSpecificControls = {
							range: new FormControl(dateFilter.range),
							label: new FormControl(dateFilter.label),
							value: valueControl,
						};
						break;
					case FilterTypeEnum.SUGGESTION:
						const suggestionFilter = filters[prop] as SuggestionFilterDefinition;
						typeSpecificControls = {
							multiple: new FormControl(suggestionFilter.multiple),
							suggestion: new FormControl(suggestionFilter.suggestion),
							label: new FormControl(suggestionFilter.label),
						};
						break;
					case FilterTypeEnum.PREDEFINED_TOGGLE:
						const predefinedFilter = filters[prop] as PredefinedFilterDefinition;
						typeSpecificControls = {
							predefinedValue: new FormControl(predefinedFilter.predefinedValue),
							label: new FormControl(predefinedFilter.label),
							toggleLabel: new FormControl(predefinedFilter.toggleLabel),
						};
						break;
				}
				form.addControl(
					prop,
					new FormGroup({
						type: new FormControl(filters[prop].type),
						changable: new FormControl(filters[prop].changable),
						value: new FormControl(filters[prop].value),
						...typeSpecificControls,
					})
				);
				form.updateValueAndValidity();
			}
		});
	}
}

import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as fromReducer from './template-form.reducer';
import * as fromActions from './template-form.actions';
import * as fromSelectors from './template-form.selectors';
import * as fromRouter from '@app/root-store/router';
import * as fromNewsletterBlocks from '../newsletter-blocks';
import * as fromNewsletterForm from '../newsletter-form';
import {catchError, filter, map, mergeMap, of, switchMap, withLatestFrom} from 'rxjs';
import {RdsDialogService} from '@rds/angular-components';
import cloneDeep from '@lodash-es/cloneDeep';
import {SelectDialogData} from '@app/shared/dialogs/select-dialog/select-dialog.component';
import {ConfirmDialogComponent, ConfirmDialogData} from '@app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import * as fromUser from '@core/user/store';
import {NewsletterService} from '@core/services/newsletter.service';
import {
  SaveTemplatePreviewDialogComponent,
  SaveTemplatePreviewDialogData,
} from '@app/newsletter-new/dialogs/save-template-preview-dialog/save-template-preview-dialog.component';
import {RhTemplateFormRequest} from '@app/newsletter-new/models/template';
import {USER_ROLES_TEMPLATE} from '@app/newsletter-new/models/user-roles.enum';
import {EMPTY_TEMPLATE_FORM, RhTemplateForm} from '@app/newsletter-new/models/newsletter';

@Injectable()
export class TemplateFormEffects {
  public defaultTimeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

  public initAddForm$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.form.init),
        withLatestFrom(this.store$.pipe(select(fromUser.selectUserProfile))),
        map(([action, user]): Partial<RhTemplateForm> => {
          const template = cloneDeep(EMPTY_TEMPLATE_FORM);
          return {
            ...template,
            permissions: {
              ...template.permissions,
              owners: [
                {
                  identifier: user.login,
                  firstName: user.firstName,
                  lastName: user.lastName,
                  role: 'owners',
                  order: 0,
                },
              ],
            },
          };
        }),
        mergeMap((form) => [
          fromActions.form.setFormValue({form: {...form}}),
          fromActions.form.setInitialFormValue({form: {...form}}),
        ])
      ),
    {dispatch: true}
  );

  public saveTemplate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.save.request),
        withLatestFrom(
          this.store$.pipe(select(fromSelectors.selectCreateFormForRequest)),
          this.store$.pipe(select(fromNewsletterBlocks.selectSections))
        ),

        switchMap(([action, form, sections]) =>
          this.newsletterService.createTemplate(form, sections).pipe(
            map((template) => fromActions.save.success({template})),
            catchError(({message}) => of(fromActions.save.failure({message})))
          )
        )
      ),
    {dispatch: true}
  );

  public openSaveTemplateDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.save.dialog),
        withLatestFrom(
          this.store$.pipe(select(fromSelectors.selectCreateFormForRequest)),
          this.store$.pipe(select(fromNewsletterBlocks.selectSections))
        ),
        map(([action, form, sections]) => {
          const template: RhTemplateFormRequest = {
            ...form
          };
          const data: SaveTemplatePreviewDialogData = {
            form: this.store$.pipe(select(fromSelectors.selectCreateFormForRequest)),
            sections: this.store$.pipe(select(fromNewsletterBlocks.selectSections)),
          };

          const dialog = this.dialogService.open(SaveTemplatePreviewDialogComponent, {
            data,
            size: 'm',
            disableClose: true,
            closeOnNavigation: false,
          });
          return {dialog, template};
        }),
        switchMap(({dialog, template}) =>
          dialog.afterClosed().pipe(
            filter((data) => !!data),
            mergeMap(({url, error}) => {
              console.log(url, error)
              if (!!url) {
                const saveAction = !!template.id ? fromActions.update.request() : fromActions.save.request();
                return [fromActions.form.setFormValue({form: {imagePreviewUrl: url}}), saveAction];
              } else {
                return of(null);
              }
            })
          )
        )
      ),
    {dispatch: true}
  );

  public updateTemplate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.update.request),
        withLatestFrom(
          this.store$.pipe(select(fromSelectors.selectUpdateFormForRequest)),
          this.store$.pipe(select(fromNewsletterBlocks.selectSections))
        ),
        switchMap(([action, form, sections]) =>
          this.newsletterService.updateTemplate(form, sections).pipe(
            map((template) => fromActions.update.success({template})),
            catchError(({message}) => of(fromActions.update.failure({message})))
          )
        )
      ),
    {dispatch: true}
  );

  public saveSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.save.success, fromActions.update.success),
        mergeMap(() => [
          fromNewsletterBlocks.clearData(),
          fromNewsletterForm.form.clearForm(),
          fromActions.form.setFormTouched({touched: false}),
          fromRouter.go({queryParams: {}, path: 'newsletter/newsletter/create'}),
        ])
      ),
    {dispatch: true}
  );

  public openLeavePageDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.dialog.openLeave),
        withLatestFrom(this.store$.pipe(select(fromSelectors.selectChangesMade))),
        filter(([{url}, changesMade]) => !!changesMade),
        map(([{url}, changesMade]) => {
          const data: ConfirmDialogData = {
            ids: [],
            messages: [],
            title: 'Do you want to discard changes?',
            confirmButtonLabel: 'Yes, discard',
            confirmButtonType: 'primary',
          };
          const dialog = this.dialogService.open(ConfirmDialogComponent, {
            data,
            size: 's',
          });
          return {dialog, url};
        }),
        switchMap(({dialog, url}) =>
          dialog.afterClosed().pipe(
            filter((data) => !!data),
            map((data: SelectDialogData) => {
              return {url};
            })
          )
        ),
        map(({url}) => fromRouter.go({path: url, queryParams: {}}))
      ),
    {dispatch: true}
  );
  public getTemplateById$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.getTemplateById.request),
        switchMap(({id}) =>
          this.newsletterService.getTemplateById(id).pipe(
            switchMap((form) => {
              return [
                fromActions.form.setInitialFormValue({
                  form: {
                    ...form,
                    permissions: {
                      owners: form.newsletterTemplateUserRole
                        .filter((i) => i.role === USER_ROLES_TEMPLATE.OWNER)
                        .map((i) => ({...i, identifier: i.userLogin})),
                      editors: form.newsletterTemplateUserRole
                        .filter((i) => i.role === USER_ROLES_TEMPLATE.EDITOR)
                        .map((i) => ({...i, identifier: i.userLogin})),
                      viewers: form.newsletterTemplateUserRole
                        .filter((i) => i.role === USER_ROLES_TEMPLATE.VIEWER)
                        .map((i) => ({...i, identifier: i.userLogin})),
                    },
                  },
                }),
                fromActions.form.setFormValue({
                  form: {
                    ...form,
                    permissions: {
                      owners: form.newsletterTemplateUserRole
                        .filter((i) => i.role === USER_ROLES_TEMPLATE.OWNER)
                        .map((i) => ({...i, identifier: i.userLogin})),
                      editors: form.newsletterTemplateUserRole
                        .filter((i) => i.role === USER_ROLES_TEMPLATE.EDITOR)
                        .map((i) => ({...i, identifier: i.userLogin})),
                      viewers: form.newsletterTemplateUserRole
                        .filter((i) => i.role === USER_ROLES_TEMPLATE.VIEWER)
                        .map((i) => ({...i, identifier: i.userLogin})),
                    },
                  },
                }),
                fromNewsletterForm.preparing.prepareContentFromTemplate({template: form}),
              ]
            }),
            catchError(({message}) => of(fromActions.getTemplateById.failure({message})))
          )
        )
      ),
    {dispatch: true}
  );

  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private dialogService: RdsDialogService,
    private newsletterService: NewsletterService
  ) {
  }

  getTime(date: Date) {
    return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
  }
}

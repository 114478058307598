import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { FormStepStatus } from '@shared/form-status-badge/form-status-badge.component';
import { FormStatus } from '@app/newsletter-new/store/recipients-form';
import { RhTemplateForm, RhTemplateFormRequest } from '@app/newsletter-new/models/template';

export const form = createActionGroup({
	source: 'TEMPLATE FORM',
	events: {
		init: emptyProps(),
		clearForm: emptyProps(),
		getById: props<{
			id: number;
		}>(),
		setFormValue: props<{
			form: Partial<RhTemplateForm>;
		}>(),
		setInitialFormValue: props<{
			form: Partial<RhTemplateForm>;
		}>(),
		setFormChecked: emptyProps(),
		setFormTouched: props<{
			touched: boolean;
		}>(),
		setShareStepStatus: props<{
			status: FormStepStatus;
		}>(),
		setShareFormStatus: props<{
			status: FormStatus;
		}>(),
		clearAll: emptyProps(),
	},
});

export const getTemplateById = createActionGroup({
	source: 'TEMPLATE GET BY ID',
	events: {
		request: props<{
			id: number;
		}>(),
		success: props<{
			template: RhTemplateForm;
		}>(),
		failure: props<{
			message: string;
		}>(),
    noPermissions: props<{
      message: string;
    }>(),
	},
});

export const save = createActionGroup({
	source: 'TEMPLATE FORM SAVE',
	events: {
		dialog: emptyProps(),
		request: emptyProps(),
		requestFromNewsletter: props<{
			template: RhTemplateFormRequest;
		}>(),
		success: props<{
			template: RhTemplateFormRequest;
		}>(),
		failure: props<{
			message: string;
		}>(),
	},
});

export const update = createActionGroup({
	source: 'TEMPLATE FORM UPDATE',
	events: {
		request: emptyProps(),
		success: props<{
			template: RhTemplateForm;
		}>(),
		failure: props<{
			message: string;
		}>(),
	},
});

export const dialog = createActionGroup({
	source: 'TEMPLATE FORM DIALOG',
	events: {
		openLeave: props<{
			url: string;
		}>(),
		openSave: emptyProps(),
		openPreview: emptyProps,
	},
});

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {catchError, concatMap, filter, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';

import * as fromUser from '@app/core/user/store';
import * as fromActions from './campaign-subscriptions.actions';
import * as fromReducer from './campaign-subscriptions.reducer';
import {  Store } from '@ngrx/store';
import {NewsletterService} from '@core/services/newsletter.service';
import {of} from 'rxjs';
import { CopyLinkData, CopyLinkDialogComponent } from './copy-link-dialog/copy-link-dialog.component';
import { RdsDialogService, RdsToastService } from '@rds/angular-components';
import { ConfirmDialogComponent, ConfirmDialogData } from '@app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import { HiddenSubscriptionsDialogComponent } from '@app/user-settings/containers/subscriptions/hidden-subscriptions-dialog/hidden-subscriptions-dialog.component';

@Injectable()
export class CampaignSubscriptionsEffects {

  public initForm$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.list.request),
    switchMap(() => this.newsletterService.getCampaignSubscriptions().pipe(
      map(({data}) => fromActions.list.success({ data})),
      catchError(({ message }) => of(fromActions.list.failure({ error: message })))
    ))
  ), { dispatch: true }
);

  public subscribe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.subscribe.request),
      concatMap(({id, withRefresh}) => this.newsletterService.subscribe(id).pipe(
        mergeMap(({success}) => {
          const refreshAction = withRefresh ? [fromActions.list.request()] : [];
          console.log(success);
          if (success) {
            return [
              fromActions.subscribe.success({ id}),
              ...refreshAction
            ];
          } else {
            return [
              fromActions.subscribe.failure({ id, error: 'Subscribe not allowed' }),
              fromActions.list.request()
            ];
          }
        }),
        catchError(({ message }) => of(fromActions.subscribe.failure({ id, error: message })))
      ))
    ), { dispatch: true }
  );

  public unsubscribe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.unsubscribe.request),
      concatMap(({id}) => this.newsletterService.unsubscribe(id).pipe(
        mergeMap(({success}) => {
          if (success) {
            return [fromActions.unsubscribe.success({ id })];
          } else {
            return [
              fromActions.unsubscribe.failure({ id, error: 'Unsubscribe not allowed' }),
              fromActions.list.request()
            ];
          }
        }),
        catchError(({ message }) => of(fromActions.unsubscribe.failure({ id, error: message })))
      ))
    ), { dispatch: true }
  );

  public openCopyLinkDialog$ = createEffect(
      () =>
        this.actions$.pipe(
          ofType(fromActions.share.openCopyLinkDialog),
          withLatestFrom(this.store$.select(fromUser.selectFullName)),
          map(([{ id, name }, user]) => {
            const data: CopyLinkData = {
              id, 
              name,
              user,
              route: 'settings/subscriptions'
            }
            console.log(data);
            const dialog = this.dialogService.open(CopyLinkDialogComponent, {
              data
            });
            return { dialog, id };
          }),
          switchMap(({ dialog, id }) =>
            dialog.afterClosed().pipe(
              filter(confirmed => !!confirmed),
              tap((confirmed) => {
                this.toastService.success('Link copied to clipboard', 'Success', {position: 'bottom-left'})
              })
            )
          )
        ), 
      { dispatch: false }
    );

    public openAcceptShareDialog$ = createEffect(
      () =>
        this.actions$.pipe(
          ofType(fromActions.share.openAcceptShareDialog),
          map(({ id, name, user }) => {
            const data: ConfirmDialogData = {
              title: 'Subscribe',
              messages: 
                user 
                ? [ `<strong>${user}</strong> invites you to the <strong>${name}</strong> campaign. Would you like to subscribe? ` ]
                : [ `Would you like to subscribe to the <strong>${name}</strong> campaign?` ],
                cancelButtonLabel: 'Cancel',
                confirmButtonLabel: 'Yes, subscribe',
                confirmButtonType: 'primary'
            }
            console.log(data);
            const dialog = this.dialogService.open(ConfirmDialogComponent, {
              data
            });
            return { dialog, id };
          }),
          switchMap(({ dialog, id }) =>
            dialog.afterClosed().pipe(
              filter(confirmed => !!confirmed),
              map((confirmed) => {
                return fromActions.subscribe.request({ id, withRefresh: true});
              })
            )
          )
        ), 
      { dispatch: true }
    );

    public openHiddenSubscriptionModal$ = createEffect(
      () =>
        this.actions$.pipe(
          ofType(fromActions.visibility.openHiddenSubscriptionModal),
          map(() => {
            const dialog = this.dialogService.open(HiddenSubscriptionsDialogComponent, {});
            return { dialog };
          }),
        ), 
      { dispatch: false }
    );

  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private newsletterService: NewsletterService,
    private dialogService: RdsDialogService,
    private toastService: RdsToastService
  ) {}
}

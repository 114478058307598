import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isInArray'
})
export class IsInArrayPipe implements PipeTransform {

  transform(array: Array<any>, item: any, predicate?: (...args: any) => boolean): any {
    const isInArray = array.findIndex((i) => predicate ? predicate(item, i) : i === item) > -1;
    return isInArray;
  }

}

import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import forEach from '@lodash-es/forEach';
import pickBy from '@lodash-es/pickBy';

@Injectable()
export class EndpointsService {
	public get ENDPOINT() {
		return {
			ALERT: {
				SEND: this.getBaseApiUrl('CONTENT') + 'Alert/send',
				GET: {
					ALL_FOR_USER: this.getBaseApiUrl('CONTENT') + 'Alert/all',
				},
				PUT: {
					MARK_AS_READ: this.getBaseApiUrl('CONTENT') + 'Alert/read/{id}',
				},
			},
			AUTHENTICATION: {
				CREATE: this.getBaseApiUrl('AUTHENTICATION') + 'Auth/create',
				REFRESH: this.getBaseApiUrl('AUTHENTICATION') + 'Auth/refresh',
				GET_TOKEN: this.getBaseApiUrl('AUTHENTICATION') + 'token',
				REFRESH_TOKEN: this.getBaseApiUrl('AUTHENTICATION') + 'token',
				LOGIN: this.getBaseApiUrl('AUTHENTICATION') + 'token/create?redirectUrl={returnUrl}',
				USER_INFO: {
					GET: this.getBaseApiUrl('AUTHENTICATION') + 'userinfo',
				},
				TEST_401: this.getBaseApiUrl('AUTHENTICATION') + 'Test/GoogleToken',
			},
			CATALOG: {
				GET: this.getBaseApiUrl('CATALOG') + 'Catalog/{id}',
				SEARCH: this.getBaseApiUrl('CATALOG') + 'Catalog/search',
				KEWYWORD_SEARCH: this.getBaseApiUrl('CATALOG') + 'Catalog/keywordSearch',
				NAME_UNIQUE: this.getBaseApiUrl('CATALOG') + 'Catalog/isNameUnique',
				POST: {
					SAVE_AS_DRAFT: this.getBaseApiUrl('CATALOG') + 'Catalog/SaveAsDraft',
					SEND_FOR_APPROVAL: this.getBaseApiUrl('CATALOG') + 'Catalog/SendToApproval',
				},
				PUT: {
					SAVE_AS_DRAFT: this.getBaseApiUrl('CATALOG') + 'Catalog/SaveAsDraft/{catalogId}',
					SEND_FOR_APPROVAL: this.getBaseApiUrl('CATALOG') + 'Catalog/SendToApproval/{catalogId}',
					PUBLISH: this.getBaseApiUrl('CATALOG') + 'Catalog/Publish',
					NOT_PUBLISH: this.getBaseApiUrl('CATALOG') + 'Catalog/NotPublish',
					DELETE: this.getBaseApiUrl('CATALOG') + 'Catalog/Delete/{catalogId}',
					PERMISSIONS: this.getBaseApiUrl('CATALOG') + 'Catalog/{id}/permissions',
					FILTER: this.getBaseApiUrl('CATALOG') + 'Catalog/filter',
					SEARCH_FILTERED: this.getBaseApiUrl('CATALOG') + 'Catalog/searchFiltered',
					SEARCH_FILTERED_ASYNC: this.getBaseApiUrl('CATALOG') + 'Catalog/filterAsync',
					PROLONG: this.getBaseApiUrl('CATALOG') + 'Catalog/prolongPublication',
				},
				APPROVAL_DETAILS: this.getBaseApiUrl('CATALOG') + 'Catalog/{catalogId}/approvalDetails',
				DUBLICATE: {
					GET: this.getBaseApiUrl('CATALOG') + 'Catalog/duplicate/{catalogId}',
				},
			},
			CATALOG_PERSONALIZATION: {
				GET: this.getBaseApiUrl('CATALOG_PERSONALIZATION') + 'CatalogPersonalization',
				POST: this.getBaseApiUrl('CATALOG_PERSONALIZATION') + 'CatalogPersonalization',
				PUT: this.getBaseApiUrl('CATALOG_PERSONALIZATION') + 'CatalogPersonalization',
				DELETE: this.getBaseApiUrl('CATALOG_PERSONALIZATION') + 'CatalogPersonalization/{catalogPersonalizationId}',
				FULL: {
					GET: this.getBaseApiUrl('CATALOG_PERSONALIZATION') + 'CatalogPersonalization/{catalogPersonalizationId}',
				},
				UNIQUE: {
					GET: this.getBaseApiUrl('CATALOG_PERSONALIZATION') + 'CatalogPersonalization/isNameUnique',
				},
			},
			CATEGORY: {
				GET: this.getBaseApiUrl('CATEGORY') + 'Category',
				NESTED: {
					GET: this.getBaseApiUrl('CATEGORY') + 'Category/nested',
				},
			},
			CHANNEL: {
				GET: this.getBaseApiUrl('CHANNEL') + 'Channel',
			},
			CONTENT: {
				ALL: this.getBaseApiUrl('CONTENT') + 'Content/all',
				HERO_SECTION: this.getBaseApiUrl('CONTENT') + 'Content/heroSection',
				DYNAMIC_LINK: this.getBaseApiUrl('CONTENT') + 'Content/dynamicLink',
				CHANNEL: {
					GET_BY_IDS: {
						PUT: this.getBaseApiUrl('CONTENT') + 'Channel/getByIds',
					},
					DETAILS: {
						GET: this.getBaseApiUrl('CONTENT') + 'Channel/{id}',
					},
					FILTER: {
						PUT: this.getBaseApiUrl('CONTENT') + 'Channel/filter/v2',
					},
					SEARCH: {
						PUT: this.getBaseApiUrl('CONTENT') + 'Channel/search',
					},
					FILTER_ALL: {
						PUT: this.getBaseApiUrl('CONTENT') + 'Channel/filterAll',
					},
				},
				CHANNEL_SET: {
					GET: this.getBaseApiUrl('CONTENT') + 'ChannelSet/{id}',
					POST: this.getBaseApiUrl('CONTENT') + 'ChannelSet',
					PUT: this.getBaseApiUrl('CONTENT') + 'ChannelSet',
					DELETE: this.getBaseApiUrl('CONTENT') + 'ChannelSet/{id}',
					FILTER: {
						PUT: this.getBaseApiUrl('CONTENT') + 'ChannelSet/filter',
					},
					SEARCH: {
						PUT: this.getBaseApiUrl('CONTENT') + 'ChannelSet/search',
					},
					UNIQUE: {
						GET: this.getBaseApiUrl('CONTENT') + 'ChannelSet/isNameUnique',
					},
				},
				GET: this.getBaseApiUrl('CONTENT') + 'Content',
				RELATED: this.getBaseApiUrl('CONTENT') + 'Content/related',
				DETAILS: {
					GET: this.getBaseApiUrl('CONTENT') + 'Content/Details',
				},
				LIKE: {
					PUT: this.getBaseApiUrl('CONTENT') + 'Content/like',
					DELETE: this.getBaseApiUrl('CONTENT') + 'Content/like',
				},
				BOOKMARK: {
					PUT: this.getBaseApiUrl('CONTENT') + 'Content/bookmark',
					DELETE: this.getBaseApiUrl('CONTENT') + 'Content/bookmark',
					GET: this.getBaseApiUrl('CONTENT') + 'Content/bookmarks',
				},
				LATEST: {
					GET: this.getBaseApiUrl('CONTENT') + 'Content/latest',
				},
				LATEST_FOR_LANDING_PAGE: {
					GET: this.getBaseApiUrl('CONTENT') + 'LandingPage/latestContent',
				},
				TYPE: {
					GET: this.getBaseApiUrl('DIRECTORY') + 'ContentType',
				},
				MARK_READ: {
					PUT: this.getBaseApiUrl('CONTENT') + 'Content/markRead',
				},
				USER_CHANNEL: {
					GET: this.getBaseApiUrl('CONTENT') + 'UserChannel',
					PUT: this.getBaseApiUrl('CONTENT') + 'UserChannel',
					DELETE: this.getBaseApiUrl('CONTENT') + 'UserChannel',
					POST: this.getBaseApiUrl('CONTENT') + 'UserChannel',
					RESET: {
						PUT: this.getBaseApiUrl('CONTENT') + 'UserChannel/reset',
					},
					FAVOURITES: {
						GET: this.getBaseApiUrl('CONTENT') + 'UserChannel/favourites',
						FILTER: {
							PUT: this.getBaseApiUrl('CONTENT') + 'UserChannel/filter',
						},
						SEARCH: {
							PUT: this.getBaseApiUrl('CONTENT') + 'UserChannel/search',
						},
					},
				},
				USER_AUTHOR: {
					POST: this.getBaseApiUrl('CONTENT') + 'UserSubscription/author',
					DELETE: this.getBaseApiUrl('CONTENT') + 'UserSubscription/author',
				},
				USER_TOPIC: {
					POST: this.getBaseApiUrl('CONTENT') + 'UserSubscription/topic',
					DELETE: this.getBaseApiUrl('CONTENT') + 'UserSubscription/topic',
				},
				LANDING_PAGE: {
					GET: this.getBaseApiUrl('CONTENT') + 'LandingPage/url/{id}',
					ALL: {
						GET: this.getBaseApiUrl('CONTENT') + 'LandingPage',
					},
					DELETE: this.getBaseApiUrl('CONTENT') + 'LandingPage/{id}',
					DETAILS: {
						GET: this.getBaseApiUrl('CONTENT') + 'LandingPage/{id}',
					},
					FILTER: {
						PUT: this.getBaseApiUrl('CONTENT') + 'LandingPage/filter',
					},
					SEARCH: {
						PUT: this.getBaseApiUrl('CONTENT') + 'LandingPage/search',
					},
					IS_URL_USED: {
						GET: this.getBaseApiUrl('CONTENT') + 'LandingPage/isUrlUsed',
					},
					IS_NAME_USED: {
						GET: this.getBaseApiUrl('CONTENT') + 'LandingPage/isNameUsed',
					},
					SAVE_AS_DRAFT: {
						PUT: this.getBaseApiUrl('CONTENT') + 'LandingPage/SaveAsDraft/{id}',
						POST: this.getBaseApiUrl('CONTENT') + 'LandingPage/SaveAsDraft',
					},
					SAVE_AS_ACTIVE: {
						PUT: this.getBaseApiUrl('CONTENT') + 'LandingPage/SaveAsActive/{id}',
						POST: this.getBaseApiUrl('CONTENT') + 'LandingPage/SaveAsActive',
					},
				},
				MANDATORY_CHANNELS: {
					GET: this.getBaseApiUrl('CONTENT') + 'MandatoryChannel',
				},
				USER_SUBSCRIPTION: {
					GET: this.getBaseApiUrl('CONTENT') + 'UserSubscription',
					SEARCH: {
						TOPIC: {
							GET: this.getBaseApiUrl('CONTENT') + 'UserSubscription/search/topics',
						},
					},
				},
			},
			COMMENT: {
				GET: this.getBaseApiUrl('COMMENT') + 'Comment',
				PUT: this.getBaseApiUrl('COMMENT') + 'Comment',
				POST: this.getBaseApiUrl('COMMENT') + 'Comment',
				DELETE: this.getBaseApiUrl('COMMENT') + 'Comment',
			},
			DEPARTMENT: {
				NESTED: {
					GET: this.getBaseApiUrl('TAXONOMY') + 'Department/nested',
				},
				FLAT: {
					GET: this.getBaseApiUrl('TAXONOMY') + 'Department',
				},
				MANAGERS: {
					GET: this.getBaseApiUrl('TAXONOMY') + 'Department/{departmentId}/getManagers',
				},
			},
			FUNCTION: {
				NESTED: {
					GET: this.getBaseApiUrl('TAXONOMY') + 'Function/nested',
				},
				FLAT: {
					GET: this.getBaseApiUrl('TAXONOMY') + 'Function',
				},
				MANAGERS: {
					GET: this.getBaseApiUrl('TAXONOMY') + 'Function/{functionId}/getManagers',
				},
			},
			LOCATION: {
				NESTED: {
					GET: this.getBaseApiUrl('TAXONOMY') + 'Location/nested',
				},
				FLAT: {
					GET: this.getBaseApiUrl('TAXONOMY') + 'Location',
				},
				MANAGERS: {
					GET: this.getBaseApiUrl('TAXONOMY') + 'Location/{locationId}/getManagers',
				},
			},
			TAXONOMY_MANAGER: {
				BY_NAME: {
					PUT: this.getBaseApiUrl('TAXONOMY') + 'TaxonomyManager/getByName',
				},
			},
			SEARCH: {
				GET: this.getBaseApiUrl('SEARCH') + 'Results',
				PAGED: {
					GET: this.getBaseApiUrl('SEARCH') + 'Results/Paged',
					PUT: this.getBaseApiUrl('SEARCH') + 'Results/Paged',
				},
				AUTOCOMPLETE: this.getBaseApiUrl('SEARCH') + 'AutoComplete',
			},
			ODATA: this.getBaseApiUrl('ODATA'),
			QUICKLINKS: {
				GET_MY: this.getBaseApiUrl('QUICKLINKS') + 'QuickLink/my',
				GET_FAVORITE: this.getBaseApiUrl('QUICKLINKS') + 'QuickLink/myFavourite',
				SEARCH: this.getBaseApiUrl('QUICKLINKS') + 'QuickLink/Search',
				PUT: this.getBaseApiUrl('QUICKLINKS') + 'QuickLink',
				POST: this.getBaseApiUrl('QUICKLINKS') + 'QuickLink',
				DELETE: this.getBaseApiUrl('QUICKLINKS') + 'QuickLink',
				RESET: this.getBaseApiUrl('QUICKLINKS') + 'QuickLink/reset',
				FAVOURITE_REORDER: this.getBaseApiUrl('QUICKLINKS') + 'QuickLink/favouriteOrder',
				RENAME: this.getBaseApiUrl('QUICKLINKS') + 'QuickLink/rename',
			},
			QUICKLINKS_SET: {
				GET: this.getBaseApiUrl('DIRECTORY') + 'QuickLinkSet/{id}',
				POST: this.getBaseApiUrl('DIRECTORY') + 'QuickLinkSet',
				PUT: this.getBaseApiUrl('DIRECTORY') + 'QuickLinkSet',
				DELETE: this.getBaseApiUrl('DIRECTORY') + 'QuickLinkSet/{id}',
				FILTER: {
					PUT: this.getBaseApiUrl('DIRECTORY') + 'QuickLinkSet/filter',
				},
				SEARCH: {
					PUT: this.getBaseApiUrl('DIRECTORY') + 'QuickLinkSet/search',
				},
				UNIQUE: {
					GET: this.getBaseApiUrl('DIRECTORY') + 'QuickLinkSet/isNameUnique',
				},
			},
			CONTENT_APPROVALS: {
				WAITING_FOR__MY_APPROVAL: this.getBaseApiUrl('DIRECTORY') + 'Catalog/waitingForMyApproval',
				WAITING_FOR__MY_APPROVAL_DISTINCT: this.getBaseApiUrl('DIRECTORY') + 'Catalog/distinctWaitingForMyApproval',
				APPROVED_BY_ME: this.getBaseApiUrl('DIRECTORY') + 'Catalog/approvedByMe',
				APPROVED_BY_ME_DISTINCT: this.getBaseApiUrl('DIRECTORY') + 'Catalog/distinctApprovedByMe',
				REJECTED_BY_ME: this.getBaseApiUrl('DIRECTORY') + 'Catalog/rejectedByMe',
				REJECTED_BY_ME_DISTINCT: this.getBaseApiUrl('DIRECTORY') + 'Catalog/distinctRejectedByMe',
				CONTENT_APPROVALS_SUMMARY: this.getBaseApiUrl('DIRECTORY') + 'Catalog/contentApprovalsSummary',
			},
			SEO: {
				QUICKLINKS: {
					POST: this.getBaseApiUrl('QUICKLINKS') + 'QuickLink',
				},
			},
			TEMPLATE: {
				GET: this.getBaseApiUrl('NEWSLETTER') + 'Template',
			},
			NEWSLETTER_TEMPLATE: {
				SEND: this.getBaseApiUrl('NEWSLETTER') + 'NewsletterTemplate',
				UPDATE: this.getBaseApiUrl('NEWSLETTER') + 'NewsletterTemplate/{id}',
				GET: this.getBaseApiUrl('NEWSLETTER') + 'NewsletterTemplate/{id}',
				FILTER: this.getBaseApiUrl('NEWSLETTER') + 'NewsletterTemplate/filter',
				SEND_COPY: this.getBaseApiUrl('NEWSLETTER') + 'NewsletterTemplate/sendCopy/{id}',
				SHARE: this.getBaseApiUrl('NEWSLETTER') + 'NewsletterTemplate/share/{id}',
				DELETE: this.getBaseApiUrl('NEWSLETTER') + 'NewsletterTemplate/{id}',
				REJECT: this.getBaseApiUrl('NEWSLETTER') + 'NewsletterTemplate/reject/{id}',
				ACCEPT: this.getBaseApiUrl('NEWSLETTER') + 'NewsletterTemplate/accept/{id}',
				RENAME: this.getBaseApiUrl('NEWSLETTER') + 'NewsletterTemplate/rename/{id}',
			},
			NEWSLETTER: {
				POST_DRAFT: this.getBaseApiUrl('NEWSLETTER') + 'Newsletter/SaveAsDraft',
				PUT_DRAFT: this.getBaseApiUrl('NEWSLETTER') + 'Newsletter/SaveAsDraft/{id}',
				GET_DRAFT: this.getBaseApiUrl('NEWSLETTER') + 'Newsletter/Draft',
				POST_PHOTO: this.getBaseApiUrl('NEWSLETTER') + 'Photo/Upload',
				GET_NEWSLETTERS: this.getBaseApiUrl('NEWSLETTER') + 'Newsletter',
				GET_MY_ALL_DRAFT: this.getBaseApiUrl('NEWSLETTER') + 'Newsletter/getAllDraft',
				GET_MY_ALL_SENT: this.getBaseApiUrl('NEWSLETTER') + 'Newsletter/getAllSent',
				GET_MY_ALL_SCHEDULED: this.getBaseApiUrl('NEWSLETTER') + 'Newsletter/getAllScheduled',
				GET_ALL: this.getBaseApiUrl('NEWSLETTER') + 'Newsletter/getAll',
				GET_ALL_NEWSLETTER_FOR_CAMPAIGN: this.getBaseApiUrl('NEWSLETTER') + 'Newsletter/{id}',
				GET_NEWSLETTER_BY_ID: this.getBaseApiUrl('NEWSLETTER') + 'Newsletter/{id}',
				SAVE_AS_PUBLISH: this.getBaseApiUrl('NEWSLETTER') + 'Newsletter/SaveAsPublish',
				SAVE_AS_PUBLISH_FROM_DRAFT: this.getBaseApiUrl('NEWSLETTER') + 'Newsletter/SaveAsPublish/{id}',
				SEND_TEST: this.getBaseApiUrl('NEWSLETTER') + 'Newsletter/SendTestEmail',
				SEND_PDF: this.getBaseApiUrl('NEWSLETTER') + 'Newsletter/ExportToPdf',
				GET_CHANNELS: this.getBaseApiUrl('CONTENT') + 'channel',
				GET_NEWS_FOR_CHANNEL: this.getBaseApiUrl('CONTENT') + 'content',
				GET_EMAIL: this.getBaseApiUrl('NEWSLETTER') + 'Newsletter/Sender',
				AGREEMENT: this.getBaseApiUrl('NEWSLETTER') + 'UserAgreement',
				OPENED_NEWSLETTER: this.getBaseApiUrl('NEWSLETTER') + 'UserOpenedNewsletter',
				PUT_SHARE_WITH: this.getBaseApiUrl('NEWSLETTER') + 'Newsletter/ShareWith/{id}',
				DELETE: this.getBaseApiUrl('NEWSLETTER') + 'Newsletter/{id}',
				CANCEL: this.getBaseApiUrl('NEWSLETTER') + 'Newsletter/Cancel/{id}',
				GET_ALL_TEMPLATES: this.getBaseApiUrl('NEWSLETTER') + 'Newsletter/TemplateGetAll',
				SAVE_AS_TEMPLATE: this.getBaseApiUrl('NEWSLETTER') + 'Newsletter/TemplateAdd/{newsletterId}',
				RENAME: this.getBaseApiUrl('NEWSLETTER') + 'Newsletter/Rename/{newsletterId}',
				GET_ALL_SHARED_TEMPLATES: this.getBaseApiUrl('NEWSLETTER') + 'Newsletter/GetAllSharedTemplates',
				ACCEPT_SHARED_TEMPLATE: this.getBaseApiUrl('NEWSLETTER') + 'Newsletter/SharedTemplateAccept/{newsletterId}',
				SET_CONTRIBUTORS: this.getBaseApiUrl('NEWSLETTER') + 'Newsletter/SetContributors/{newsletterId}',
				RADA_GROUPS: {
					SEARCH: this.getBaseApiUrl('NEWSLETTER') + 'RecipientList/group/search',
					DETAILS: this.getBaseApiUrl('NEWSLETTER') + 'RecipientList/group/details/{key}',
				},
				CAMPAIGN: {
					GET: this.getBaseApiUrl('NEWSLETTER') + 'Campaign/{id}',
					GET_ALL: this.getBaseApiUrl('NEWSLETTER') + 'Campaign/all',
					POST: this.getBaseApiUrl('NEWSLETTER') + 'Campaign',
					PUT: this.getBaseApiUrl('NEWSLETTER') + 'Campaign/{id}',
					DELETE: this.getBaseApiUrl('NEWSLETTER') + 'Campaign/{id}',
					SEND: this.getBaseApiUrl('NEWSLETTER') + 'Campaign/share/{id}',
					ARCHIVE: this.getBaseApiUrl('NEWSLETTER') + 'Campaign/archive/{id}',
					UNARCHIVE: this.getBaseApiUrl('NEWSLETTER') + 'Campaign/unarchive/{id}',
					GET_NEWSLETTER_LIST: this.getBaseApiUrl('NEWSLETTER') + 'Campaign/{id}/newsletter',
					IS_SUBSCRIBED: this.getBaseApiUrl('NEWSLETTER') + 'Campaign/{id}/isSubscribed',
					SUBSCRIBE: this.getBaseApiUrl('NEWSLETTER') + 'Campaign/{id}/subscribe',
					UNSUBSCRIBE: this.getBaseApiUrl('NEWSLETTER') + 'Campaign/{id}/unsubscribe',
          			GET_SUBSCRIPTIONS: this.getBaseApiUrl('NEWSLETTER') + 'Campaign/subscriptions',
          CAN_EDIT: this.getBaseApiUrl('NEWSLETTER') + 'Campaign/{id}/canEdit',
				},
				RECIPIENT_LIST: {
					GET: this.getBaseApiUrl('NEWSLETTER') + 'RecipientList/{id}',
					TABLE: this.getBaseApiUrl('NEWSLETTER') + 'RecipientList/all',
					POST: this.getBaseApiUrl('NEWSLETTER') + 'RecipientList',
					PUT: this.getBaseApiUrl('NEWSLETTER') + 'RecipientList/{id}',
					PERSONALIZED: this.getBaseApiUrl('NEWSLETTER') + 'RecipientList',
					DELETE: this.getBaseApiUrl('NEWSLETTER') + 'RecipientList',
					SEND: this.getBaseApiUrl('NEWSLETTER') + 'RecipientList/sendCopy/{id}',
					ACCEPT: this.getBaseApiUrl('NEWSLETTER') + 'RecipientList/accept/{id}',
          CAN_EDIT: this.getBaseApiUrl('NEWSLETTER') + 'RecipientList/{id}/canEdit',
				},
				STATISTICS: {
					LINK_LEGACY: this.getBaseApiUrl('NEWSLETTER') + 'Newsletter/ClickStatistic/filter/{id}',
					GET_FOR_SINGLE: this.getBaseApiUrl('NEWSLETTER') + 'Statistics/{id}',
					GET_FEEDBACK: this.getBaseApiUrl('NEWSLETTER') + 'Feedback/statistic/{id}',
          GET_SURVEY: this.getBaseApiUrl('NEWSLETTER') + 'Statistics/survey/{id}',
					GET_FOR_CAMPAIGN: this.getBaseApiUrl('NEWSLETTER') + 'Statistics/campaign/{id}',
					POST_RANGE_FOR_CAMPAIGN: this.getBaseApiUrl('NEWSLETTER') + 'Statistics/campaign/{id}/timeline',
				},
			},
			TOPIC: {
				GET: this.getBaseApiUrl('DIRECTORY') + 'Topic',
			},
			EMAIL: {
				GET: this.getBaseApiUrl('WORKDAY') + 'User/{login}/email',
			},
			USER_PROFILE: {
				GET: this.getBaseApiUrl('USER_PROFILE') + 'User',
				POST: this.getBaseApiUrl('USER_PROFILE') + 'User/settings',
				WAS_LOGGED: this.getBaseApiUrl('USER_PROFILE') + 'User/displayed/1',
				SETTINGS_VISITED: this.getBaseApiUrl('USER_PROFILE') + 'User/displayed/2',
				QUICKLINKS_VISITED: this.getBaseApiUrl('USER_PROFILE') + 'User/displayed/3',
				CATALOG_VISITED: this.getBaseApiUrl('USER_PROFILE') + 'User/displayed/4',
				SEARCH_VISITED: this.getBaseApiUrl('USER_PROFILE') + 'User/displayed/5',
				MY_CONTENT_VISITED: this.getBaseApiUrl('USER_PROFILE') + 'User/displayed/6',
				LAYOUT_SETTINGS_VISITED: this.getBaseApiUrl('USER_PROFILE') + 'User/displayed/7',
				MENU_VISITED: this.getBaseApiUrl('USER_PROFILE') + 'User/displayed/8',
				CREATED: this.getBaseApiUrl('USER_PROFILE') + 'User/ensureCreated',
				LANGUAGE: {
					GET: this.getBaseApiUrl('USER_PROFILE') + 'Language',
				},
				ROLES: {
					GET: this.getBaseApiUrl('USER_PROFILE') + 'User/{login}/roles',
				},
				EVENT_FILTERS: {
					GET: this.getBaseApiUrl('USER_PROFILE') + 'EventFilter',
					POST: this.getBaseApiUrl('USER_PROFILE') + 'EventFilter',
				},
				FILTERS: {
					GET: this.getBaseApiUrl('USER_PROFILE') + 'SearchFilter',
					POST: this.getBaseApiUrl('USER_PROFILE') + 'SearchFilter',
				},
				SEARCHING_PREFERENCES: {
					DETAILS: {
						GET: this.getBaseApiUrl('USER_PROFILE') + 'SearchPreference/details',
					},
					RESET: {
						POST: this.getBaseApiUrl('USER_PROFILE') + 'SearchPreference/reset',
					},
					PUT: this.getBaseApiUrl('USER_PROFILE') + 'SearchPreference',
					GET: this.getBaseApiUrl('USER_PROFILE') + 'SearchPreference',
				},
				USER_ROLE: {
					PUT: this.getBaseApiUrl('USER_PROFILE') + 'UserRole',
				},
				DEFAULT_TAXONOMIES: {
					GET: this.getBaseApiUrl('USER_PROFILE') + 'User/{login}/taxonomies',
				},
				USER_COUNT_BY_TAXONOMIES: {
					PUT: this.getBaseApiUrl('USER_PROFILE') + 'User/getBySearchPreferences/count',
				},
			},
			WORKDAY: {
				USER: {
					FILTERED_SEARCH: this.getBaseApiUrl('WORKDAY') + 'User/filteredSearch',
					SEARCH: this.getBaseApiUrl('WORKDAY') + 'User/search',
					MY_SEARCH: this.getBaseApiUrl('WORKDAY') + 'User/mysearch',
					BY_EMAIL: {
						GET: this.getBaseApiUrl('WORKDAY') + 'User',
					},
					BY_GOOGLE_ID: {
						GET: this.getBaseApiUrl('WORKDAY') + 'User',
					},
					BY_LOGIN: {
						GET: this.getBaseApiUrl('WORKDAY') + 'User/{login}',
					},
					ORGANISATION_BY_LOGIN: {
						GET: this.getBaseApiUrl('WORKDAY') + 'User/{login}/organization',
					},
					PHOTO: {
						GET: this.getBaseApiUrl('WORKDAY') + 'User/{login}/photoUrl',
					},
					GET_BY_EMAILS: this.getBaseApiUrl('WORKDAY') + 'User/getByEmails',
				},
				ACCOUNT: {
					POST: this.getBaseApiUrl('WORKDAY') + 'Account',
					DELETE: this.getBaseApiUrl('WORKDAY') + 'Account/{userId}',
					PUT: this.getBaseApiUrl('WORKDAY') + 'Account/getAccounts',
					UPDATE: this.getBaseApiUrl('WORKDAY') + 'Account/updateAccount',
					GET: this.getBaseApiUrl('WORKDAY') + 'Account/getByUsername',
					IS_USERNAME_UNIQUE: this.getBaseApiUrl('WORKDAY') + 'Account/isUsernameUnique',
					IS_EMAIL_UNIQUE: this.getBaseApiUrl('WORKDAY') + 'Account/isEmailUnique',
				},
			},
			USER_NOTIFICATION: {
				PUT: this.getBaseApiUrl('CONTENT') + 'UserNotification/token',
				SETTINGS: {
					GET: this.getBaseApiUrl('CONTENT') + 'UserNotification/settings',
					PUT: this.getBaseApiUrl('CONTENT') + 'UserNotification/settings',
					RESET: this.getBaseApiUrl('CONTENT') + 'UserNotification/settings/reset',
					CHANNELS: this.getBaseApiUrl('CONTENT') + 'UserNotification/settings/channels',
				},
			},
			TICKER: {
				GET_FEED: this.getBaseApiUrl('CONTENT') + 'TickerNews/feed',
				GET_BY_ID: this.getBaseApiUrl('CONTENT') + 'TickerNews/{id}',
				POST: this.getBaseApiUrl('CONTENT') + 'TickerNews',
				PUT_FILTER: this.getBaseApiUrl('CONTENT') + 'TickerNews/filter',
				PUT_SEARCH: this.getBaseApiUrl('CONTENT') + 'TickerNews/search',
				MARK_AS_READ: this.getBaseApiUrl('CONTENT') + 'TickerNews/{id}',
			},
			TIMEZONE: {
				GET: this.getBaseApiUrl('CONTENT') + 'TimeZone',
			},
			HEADER: {
				GET_LINKS: this.getBaseApiUrl('DIRECTORY') + 'configurationparameter/all',
			},
			IMAGE: {
				DIRECTORY_UPLOAD: this.getBaseApiUrl('DIRECTORY') + 'Image/upload',
			},
			EVENT: {
				GET: this.getBaseApiUrl('DIRECTORY') + 'Event/{id}',
				SAVE_AS_DRAFT: this.getBaseApiUrl('DIRECTORY') + 'Event/SaveAsDraft',
				SAVE_AS_PUBLISH: this.getBaseApiUrl('DIRECTORY') + 'Event/SaveAsPublish',
				UPDATE_AS_DRAFT: this.getBaseApiUrl('DIRECTORY') + 'Event/SaveAsDraft/{id}',
				UPDATE_AS_PUBLISH: this.getBaseApiUrl('DIRECTORY') + 'Event/SaveAsPublish/{id}',
				MY_EVENTS: this.getBaseApiUrl('DIRECTORY') + 'Event/myEvents',
				DELETE: this.getBaseApiUrl('DIRECTORY') + 'Event/Delete',
				TOPIC_SEARCH: this.getBaseApiUrl('DIRECTORY') + 'Event/topicSearch',
				FEED: this.getBaseApiUrl('DIRECTORY') + 'Event/feed',
				CALENDAR: this.getBaseApiUrl('DIRECTORY') + 'Event/calendar',
				GRID: this.getBaseApiUrl('DIRECTORY') + 'Event/grid',
				TOP: this.getBaseApiUrl('DIRECTORY') + 'Event/top',
			},
			DASHBOARD: {
				RECENT: this.getBaseApiUrl('DIRECTORY') + 'Dashboard/recentItems',
			},
			RADA: {
				GET: this.getBaseApiUrl('DIRECTORY') + 'Event/top',
			},
		};
	}

	public getBaseApiUrl(key: string): string {
		return `${environment.gateway[key]}`;
	}

	/**
	 * Method adding query params to url
	 */
	public addParams(url: string, params: any): string {
		let i = 0;
		let urlWithQueryParams = url;

		const queryParams = pickBy(params, (p: string) => !!p && p !== '');

		forEach(queryParams, (v, k) => {
			const startQuery = i === 0 || urlWithQueryParams.indexOf('?') === -1;
			urlWithQueryParams += startQuery ? '?' : '&';
			urlWithQueryParams += `${k}=${v}`;
			i++;
		});
		return urlWithQueryParams;
	}

	/**
	 * Method replacing tokens in urls
	 */
	public replaceUrlTokens(url: string, values: any): string {
		const pattern = /\{([^\{\}]*)\}/g;

		return url.replace(pattern, (a, b) => {
			const p = b.split('.');
			let vals = values;

			try {
				// tslint:disable-next-line:prefer-for-of
				for (let s = 0; s < p.length; s++) {
					vals = String(vals[p[s]]);
				}
			} catch (e) {
				vals = a;
			}

			if (typeof vals === 'undefined') {
				vals = 'undefined';
			} else if (vals === null) {
				vals = 'null';
			}

			return String(typeof vals === 'string' || typeof vals === 'number' ? vals : typeof vals);
		});
	}

	constructor() {}
}

import {Component, OnInit, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromScreen from '@app/root-store/ui/screen';
import * as fromRouter from '@app/root-store/router';
import {RdsTabsComponent} from '@rds/angular-components';
import {ActivatedRoute} from '@angular/router';
import { environment } from '@env/environment';

@Component({
  selector: 'rh-settings-page',
  templateUrl: './settings-page.container.html',
  styleUrls: ['./settings-page.container.scss']
})

export class SettingsPageContainer implements OnInit {
  // remove after release
  isDev = environment.dev;
  
  @ViewChild('tabs') tabs: RdsTabsComponent;

  activeTab: 'global' | 'notifications'| 'subscriptions';
  tabIndex = 0;
  
  ngOnInit() {
    this.route?.firstChild?.url.subscribe((data) => {
      if (data[0]) {
        this.activeTab = data[0].path as 'global' | 'notifications' | 'subscriptions';
        switch (this.activeTab) {
          case 'global': {
            this.tabIndex = 0;
            break;
          }
          case 'notifications': {
            this.tabIndex = 1;
            break;
          }
          case 'subscriptions': {
            this.tabIndex = 2;
            break;
          }
        }
      }
    });
  }

  tabsClicked(tabs: RdsTabsComponent) {
    if (this.tabIndex !== tabs.selectedIndex) {
      switch (tabs.selectedIndex) {
        case 0: {
          this.activeTab = 'global';
          this.store$.dispatch(fromRouter.go({path: 'settings/global', queryParams: {}}));
          break;
        }
        case 1: {
          this.activeTab = 'notifications';
          this.store$.dispatch(fromRouter.go({path: 'settings/notifications', queryParams: {}}));
          break;
        }
        case 2: {
          this.activeTab = 'subscriptions';
          this.store$.dispatch(fromRouter.go({path: 'settings/subscriptions', queryParams: {}}));
          break;
        }
      }
      this.tabIndex = tabs.selectedIndex;
    }
    
  }

  constructor(
    private store$: Store<fromScreen.State>,
    private route: ActivatedRoute,
  ) {
  }
}

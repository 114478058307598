import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { environment } from '@env/environment';
import { RDS_DIALOG_DATA, RdsDialogRef } from '@rds/angular-components';
import { Base64 } from 'js-base64';
import { Clipboard } from '@angular/cdk/clipboard';

export interface CopyLinkData {
 id: number,
 name: string,
 user?: string,
 route: string
}
@Component({
  selector: 'rh-copy-link-dialog',
  templateUrl: './copy-link-dialog.component.html',
  styleUrls: ['./copy-link-dialog.component.scss']
})
export class CopyLinkDialogComponent implements OnInit {
  
  form: FormGroup = new FormGroup({
    link: new FormControl(''),
    sendAsMe: new FormControl(false)
  });

  constructor(
    private clipboard: Clipboard,
    public dialogRef: RdsDialogRef<CopyLinkDialogComponent>,
    @Inject(RDS_DIALOG_DATA) public data: CopyLinkData
  ) {

  }

  ngOnInit() {
    this.form.get('link').setValue(this.buildLink());
    this.form.get('link').disable();
    this.form.get('sendAsMe').valueChanges.subscribe(value => {
      this.form.get('link').enable();
      this.form.get('link').setValue(this.buildLink(value));
      this.form.get('link').disable();
      console.log(Base64.decode(this.form.get('link').value.split('share=')[1]));
    });
  }

  copyLink() {
    this.clipboard.copy(this.form.get('link').getRawValue());
  }

  buildLink(includeName: boolean = false) {
    if (includeName) {
      return `${environment.url}/${this.data.route}?share=${Base64.encodeURI(JSON.stringify(this.data))}`;
    } else {
      return `${environment.url}/${this.data.route}?share=${Base64.encodeURI(JSON.stringify({id: this.data.id, name: this.data.name}))}`;
    }
  }
}
